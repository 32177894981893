import React, {useState, useContext, useEffect, useCallback} from 'react';
import {Button, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/queueIn.css';
import dayjs from "dayjs";
import {
    faArrowUpShortWide,
    faAt,
    faMobileScreenButton,
    faArrowDownWideShort,
    faCirclePhone,
    faBrainCircuit,
    faClipboardList,
    faUserPlus,
    faPipe
} from "@fortawesome/pro-duotone-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Empty from "../utils/Empty";
import User from "../utils/User";
import Loading from "../utils/Loading";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function QueueIn({locale, Dialog, setVisit, location, setPatient, width}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [queueIn, setQueueIn] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sort, setSort] = useState(true);

    const Queue = data => {
        const message = {
            type: 'wss',
            path: 'queue',
            action: 'get',
            data: {
                status: 'in',
                location: location
            }
        };
        wsCall(message);
    }

    const ButtonSort = () => {
        if (!sort) {
            const array = queueIn;
            array.reverse();
            setQueueIn(array);
            setSort(true);
        } else {
            const array = queueIn;
            array.reverse();
            setQueueIn(array);
            setSort(false);
        }
    }

    const ClickOpen = data => {
        setVisit(data);
        Dialog({view: 'queueVisit', tab: 'visit'});
    }

    const ButtonPatient = data => {
        setVisit(data);
        setPatient({});

        const message = {
            type: 'wss',
            path: 'patient',
            action: 'get',
            data: {
                patient: data.user,
                source: 'queueIn'
            }
        };
        wsCall(message);
    }

    const ListItem = data => {
        const itemDate = dayjs(parseInt(data.item.timestampIn)).format("MMMM D, YYYY @ h:mm A");

        return <li key={data.item.timestampIn}>
            <div className="mbsc-row">
                <div className="mbsc-col">
                    <div className="mbsc-row cs-queue-in-list" onClick={(ev) => ClickOpen(data.item)}>
                        {data.item.type === 'mobile' && width > 576 &&
                            <FontAwesomeIcon className="cs-queue-in-list-icon-mobile" icon={faMobileScreenButton} />
                        }
                        {data.item.type === 'whatsapp' && width > 576 &&
                            <FontAwesomeIcon className="cs-queue-in-list-icon-whatsapp" icon={faCirclePhone} />
                        }
                        {data.item.type === 'email' && width > 576 &&
                            <FontAwesomeIcon className="cs-queue-in-list-icon-email" icon={faAt} />
                        }
                        <div className="cs-queue-in-list-section">
                            <div className="mbsc-bold mbsc-txt-muted">
                                {data.item.firstName && data.item.firstName} {data.item.middleName && data.item.middleName} {data.item.lastName && data.item.lastName} {data.item.paternalName && data.item.paternalName} {data.item.maternalName && data.item.maternalName} <FontAwesomeIcon className="cs-queue-in-icon-pipe-xs" icon={faPipe} /> {User(data.item)}
                            </div>
                            <div className="mbsc-row mbsc-txt-muted mbsc-txt-s cs-queue-in-item-txt">
                                {locale.queueIn.f} {itemDate}
                            </div>
                        </div>
                        {(data.item.triage.length !== 0 || data.item.firstVisit) &&
                            <FontAwesomeIcon className="cs-queue-in-icon-info mbsc-txt-muted" icon={faPipe} />
                        }
                        {data.item.triage.length !== 0 &&
                            <FontAwesomeIcon className="cs-queue-in-icon-info" icon={faBrainCircuit} />
                        }
                        {data.item.firstVisit &&
                            <FontAwesomeIcon className="cs-queue-in-icon-info" icon={faUserPlus} />
                        }
                    </div>
                </div>
                <div className="mbsc-col-auto cs-queue-in-list-button">
                    <Button color="primary" variant="flat" className="mbsc-bold" onClick={() => ButtonPatient(data.item)} >
                        <FontAwesomeIcon className="cs-queue-in-icon-right" icon={faClipboardList} />
                    </Button>
                </div>
            </div>
        </li>;
    }

    const GetQueue = useCallback(data => {
        if (data.queue) {
            setQueueIn(data.queue);
        }
        if (loading) {
            setLoading(false);
        }
    }, [loading]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'queue') {
            GetQueue(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetQueue, setWsResponse]);

    useEffect(() => {
        Queue();
    }, []);

    return (
        <>
            <div className="mbsc-row cs-queue-in-menu">
                <div className="mbsc-col" />
                <div className="mbsc-col-auto">
                    <div className="mbsc-row">
                        <Button disabled={queueIn.length === 0} color="primary" variant="flat" className="mbsc-bold cs-queue-in-button-text" onClick={() => ButtonSort()} >
                            <FontAwesomeIcon className="cs-queue-in-icon" icon={sort ? faArrowUpShortWide : faArrowDownWideShort} /> {width > 768 && locale.queueIn.b}
                        </Button>
                    </div>
                </div>
            </div>
            {loading &&
                <Loading />
            }
            {queueIn.length === 0 && !loading &&
                <div className="mbsc-row mbsc-justify-content-center cs-queue-in-section">
                    <div className="mbsc-padding">
                        <p className="mbsc-bold mbsc-txt-muted">{locale.queueIn.a}</p>
                    </div>
                </div>
            }
            {queueIn.length !== 0 && !loading &&
                <Listview
                    theme="ios"
                    themeVariant="light"
                    select="single"
                    itemType={ListItem}
                    data={queueIn}
                />
            }
        </>
    );
}

export default QueueIn;
