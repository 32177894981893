import React, {useState} from 'react';
import {Button, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from "@mobiscroll/react4";
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import dayjs from "dayjs";
import '../styles/patientMedications.css';
import {faPrescriptionBottlePill, faPrescriptionBottle} from "@fortawesome/pro-duotone-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PatientMedication from "../dialogs/PatientMedication";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function PatientMedications({locale, setPatient, patient, dialog, Dialog, width}) {
    const [medications, setMedications] = useState(patient.medications);
    const [medication, setMedication] = useState({});

    const ButtonCreate = () => {
        setMedication({});
        Dialog({view: 'patientMedication'});
    }

    const ClickOpen = data => {
        setMedication(data);
        Dialog({view: 'patientMedication'});
    }

    const ListItem = data => {
        const itemDate = dayjs(parseInt(data.item.id)).format("MMMM D, YYYY");

        return <li key={data.item.id}>
            <div className="mbsc-row">
                <div className="mbsc-col">
                    <div className="mbsc-row cs-patient-medications-list" onClick={(ev) => ClickOpen(data.item)}>
                        {data.item.type === 'active' && width > 576 &&
                            <FontAwesomeIcon className="cs-patient-medications-list-icon-active" icon={faPrescriptionBottle} />
                        }
                        {data.item.type === 'past' && width > 576 &&
                            <FontAwesomeIcon className="cs-patient-medications-list-icon-past" icon={faPrescriptionBottlePill} />
                        }
                        <div className="cs-patient-medications-list-section">
                            <div className="mbsc-bold mbsc-txt-muted">{data.item.text}</div>
                            <div className="mbsc-row mbsc-txt-muted mbsc-txt-s cs-patient-medications-item-txt">
                                {itemDate}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mbsc-col-auto cs-patient-medications-list-button" />
            </div>
        </li>;
    }

    return (
        <div className="cs-patient-medications-page">
            <div className="mbsc-row cs-patient-medications-section cs-patient-medications-title">
                <div className="mbsc-col" >
                    <div className="cs-patient-medications-title-text">
                        <p className="mbsc-txt-muted mbsc-bold">{locale.patientMedications.a}</p>
                    </div>
                </div>
                <div className="mbsc-col-auto" >
                    <Button color="primary" variant="flat" className="cs-patient-medications-button mbsc-bold" onClick={() => ButtonCreate()} >
                        {locale.patientMedications.b}
                    </Button>
                </div>
            </div>
            {medications.length !== 0 ?
                <Listview
                    theme="ios"
                    themeVariant="light"
                    select="single"
                    itemType={ListItem}
                    data={medications}
                />
                :
                <div className="mbsc-row cs-patient-medications-section cs-patient-medications-body" />
            }
            <div className={medications.length === 0 ? "mbsc-row cs-patient-medications-footer-empty" : "mbsc-row cs-patient-medications-footer"} />
            {dialog.view === 'patientMedication' &&
                <PatientMedication
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    setPatient={setPatient}
                    setMedications={setMedications}
                    medications={medications}
                    patient={patient}
                    medication={medication}
                    width={width}
                />
            }
        </div>
    );
}

export default PatientMedications;
