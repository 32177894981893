import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Popup, Select, setOptions, toast, Button} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/queueVisitTransfer.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/pro-duotone-svg-icons";
import {NetworkContext} from "../utils/NetworkContext";
import Empty from "../utils/Empty";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function QueueVisitTransfer({locale, dialog, Dialog, setRefresh, visit, configuration, width}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [location, setLocation] = useState(() => {
        const array = configuration.locations.filter(item => item.value !== visit.location);
        return array[0].value;
    });
    const [locations, setLocations] = useState(() => {
        return configuration.locations.filter(item => item.value !== visit.location);
    });

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const ButtonTransfer = () => {
        setRefresh(true);

        const message = {
            type: 'wss',
            path: 'visit-status',
            action: 'update',
            data: {
                user: visit.user,
                status: 'transfer',
                log: visit.log,
                location: location
            }
        };
        wsCall(message);
    }

    const UpdateVisitStatus = useCallback(data => {
        if (data.toast) {
            toast({message: locale.queueVisitTransfer.e, color: 'info', duration: 1000, display: 'bottom'});
        }
        DialogClose();
        setRefresh(false);
    }, [locale.queueVisitTransfer.e, DialogClose, dialog.view]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'visit-status') {
            UpdateVisitStatus(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateVisitStatus, setWsResponse]);

    return (
        <Popup className="cs-queue-visit-transfer-popup" width={600} display="center" closeOnOverlayClick={false} closeOnEsc={false} scrollLock={false} buttons={[]} isOpen={dialog.view === 'queueVisitTransfer'} onClose={DialogClose}>
            <div className="mbsc-grid cs-queue-visit-transfer-header-col">
                <div className="mbsc-row cs-queue-visit-transfer-header-row">
                    {width > 576 &&
                        <div className="mbsc-col cs-queue-visit-transfer-header-col" />
                    }
                    <div className={width > 576 ? "mbsc-col cs-queue-visit-transfer-header-col mbsc-align-center" : "mbsc-col cs-queue-visit-transfer-header-col"} >
                        <Button className="cs-queue-visit-transfer-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                            {locale.queueVisitTransfer.c}
                        </Button>
                    </div>
                    <div className="mbsc-col cs-queue-visit-transfer-header-col mbsc-align-right">
                        <Button color="primary" variant="flat" className="mbsc-bold cs-queue-visit-transfer-close-button" onClick={() => DialogClose()} >
                            &nbsp;<FontAwesomeIcon className="cs-queue-visit-transfer-close-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="cs-queue-visit-transfer-helper">
                <p>{locale.queueVisitTransfer.a}</p>
            </div>
            <Select
                animation="slide-down"
                dropdown={false}
                inputStyle="underline"
                rows={locations.length > 10 ? 10 : locations.length}
                display="anchored"
                touchUi={false}
                value={location}
                data={locations}
                onChange={args => setLocation(args.value)}
            />
            <div className="mbsc-button-group-justified cs-queue-visit-transfer-nav-buttons">
                <Button color="primary" variant="flat" className="mbsc-bold cs-queue-visit-transfer-nav-button" onClick={() => ButtonTransfer()} >
                    {locale.queueVisitTransfer.d}
                </Button>
            </div>
        </Popup>
    );
}

export default QueueVisitTransfer;
