import React, {useCallback, useContext, useEffect, useRef} from 'react';
import {Popup, Button, setOptions, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/calendarDisplay.css';
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
  theme: 'ios',
  themeVariant: 'light',
});

function CalendarDisplay({locale, dialog, Dialog, setDisplay, display}) {
  const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
  const menu = useRef(locale.calendarDisplay.a);

  const DialogClose = useCallback(() => {
    Dialog({view: 'close'});
  }, [Dialog]);

  const ButtonSelect = data => {
    setDisplay(data);

    const message = {
      type: 'wss',
      path: 'user-ui-calendar-display',
      action: 'update',
      data: {
        display: data
      }
    };
    wsCall(message);
  }

  const ListItem = data => {
    let item = '';

    if (data.item.agenda) {
      item = <li key={data.item.timestamp} className="cs-calendar-display-li">
        <Button disabled={display === 'agenda'} color="primary" variant="flat" className="cs-calendar-display-button mbsc-bold" onClick={() => ButtonSelect('agenda')} >
          {locale.calendarDisplay.b}
        </Button>
      </li>;
    } else if (data.item.scheduler) {
      item = <li key={data.item.timestamp} className="cs-calendar-display-li">
        <Button disabled={display === 'scheduler'} color="primary" variant="flat" className="cs-calendar-display-button mbsc-bold" onClick={() => ButtonSelect('scheduler')} >
          {locale.calendarDisplay.c}
        </Button>
      </li>;
    }

    return item;
  }

  const UpdateUserUiCalendarDisplay = useCallback(data => {
    if (data.toast){
      toast({message: locale.calendarDisplay.d, color: 'info', display: 'bottom', duration: 1000});
    }
    DialogClose();
  }, [locale.calendarDisplay.d, DialogClose]);

  useEffect(() => {
    if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'user-ui-calendar-display') {
      UpdateUserUiCalendarDisplay(wsResponse.data);
      setWsResponse({});
    }
  }, [wsResponse, UpdateUserUiCalendarDisplay, setWsResponse]);

  return (
      <Popup className="cs-calendar-display-popup" showArrow={false} anchor={document.getElementById('calendarDisplay')} display="anchored" buttons={[]} isOpen={dialog.view === 'calendarDisplay'} onClose={DialogClose}>
        <Listview
            theme="ios"
            themeVariant="light"
            select="single"
            itemType={ListItem}
            data={menu.current}
        />
      </Popup>
  );
}

export default CalendarDisplay;
