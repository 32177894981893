import React, {useCallback, useState, useContext} from 'react';
import {Popup, Input, toast, setOptions, Button} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/headerAccount.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/pro-duotone-svg-icons";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function HeaderAccount({locale, dialog, Dialog, select, width}) {
    const {restCall} = useContext(NetworkContext);
    const [account, setAccount] = useState('');

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const ButtonSelect = () => {
        if (!account) {
            toast({message: locale.headerAccount.j, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (account) {
            const message = {
                type: 'rest',
                path: 'account-configuration',
                action: 'get',
                data: {
                    account: account
                }
            };
            restCall(message);
        }
    }

    const ButtonSmartDocFitness = () => {
        window.location.href = 'https://smartdocfitness.com';
    }

    return (
        <Popup className="cs-header-account-popup" width={600} display="center" closeOnOverlayClick={false} closeOnEsc={false} buttons={[]} isOpen={dialog.view === 'headerAccount'} onClose={DialogClose}>
            <div className="mbsc-grid cs-header-account-header-col">
                <div className="mbsc-row cs-header-account-header-row">
                    {width > 576 &&
                        <div className="mbsc-col cs-header-account-header-col" />
                    }
                    <div className={width > 576 ? "mbsc-col cs-header-account-header-col mbsc-align-center" : "mbsc-col cs-header-account-header-col"} >
                        <Button className="cs-header-account-header-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                            {select ? locale.headerAccount.n : locale.headerAccount.a}
                        </Button>
                    </div>
                    <div className="mbsc-col cs-header-account-header-col mbsc-align-right">
                        <Button color="primary" variant="flat" className="mbsc-bold cs-header-account-header-close-button" onClick={() => DialogClose()} >
                            &nbsp;<FontAwesomeIcon className="cs-header-account-close-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="cs-header-account-helper">
                <p>{locale.headerAccount.b}</p>
            </div>
            <div className="cs-header-account-helper">
                <p>{locale.headerAccount.c}</p>
            </div>
            <div className="cs-header-account-helper">
                <p>{locale.headerAccount.d}</p>
            </div>
            <Input inputStyle="underline" label={locale.headerAccount.e} labelStyle="floating" type="text" name="account" value={account} onChange={ev => setAccount(ev.target.value)} />
            {select &&
                <div className="cs-header-account-helper">
                    <p>{locale.headerAccount.l} <span className="mbsc-bold">{locale.headerAccount.h}</span> {locale.headerAccount.m}</p><br />
                </div>
            }
            {select &&
                <div className="cs-header-account-helper">
                    <p>{locale.headerAccount.i} <span className="mbsc-bold">{locale.headerAccount.g}</span> {locale.headerAccount.k}</p>
                </div>
            }
            <div className="mbsc-button-group-justified cs-header-account-nav-buttons">
                {select &&
                    <Button color="primary" variant="flat" className="mbsc-bold cs-header-account-nav-button" onClick={() => ButtonSmartDocFitness()} >
                        {locale.headerAccount.g}
                    </Button>
                }
                <Button color="primary" variant="flat" className="mbsc-bold cs-header-account-nav-button" onClick={() => ButtonSelect()} >
                    {select ? locale.headerAccount.h : locale.headerAccount.f}
                </Button>
            </div>
        </Popup>
    );
}

export default HeaderAccount;
