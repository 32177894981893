import React, {useState} from 'react';
import {Button, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from "@mobiscroll/react4";
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import dayjs from "dayjs";
import '../styles/patientImmunizations.css';
import {faShieldVirus} from "@fortawesome/pro-duotone-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PatientImmunization from "../dialogs/PatientImmunization";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function PatientImmunizations({locale, setPatient, patient, width, dialog, Dialog}) {
    const [immunizations, setImmunizations] = useState(patient.immunizations);
    const [immunization, setImmunization] = useState({});

    const ButtonCreate = () => {
        setImmunization({});
        Dialog({view: 'patientImmunization'});
    }

    const ClickOpen = data => {
        setImmunization(data);
        Dialog({view: 'patientImmunization'});
    }

    const ListItem = data => {
        const itemDate = dayjs(data.item.date).format("MMMM D, YYYY");

        return <li key={data.item.id}>
            <div className="mbsc-row">
                <div className="mbsc-col">
                    <div className="mbsc-row cs-patient-immunizations-list" onClick={(ev) => ClickOpen(data.item)}>
                        {width > 576 &&
                            <FontAwesomeIcon className="cs-patient-immunizations-list-icon" icon={faShieldVirus} />
                        }
                        <div className="cs-patient-immunizations-list-section">
                            <div className="mbsc-bold mbsc-txt-muted">{data.item.text}</div>
                            <div className="mbsc-row mbsc-txt-muted mbsc-txt-s cs-patient-immunizations-item-txt">
                                {locale.patientImmunizations.d}: {itemDate}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mbsc-col-auto cs-patient-immunizations-list-button" />
            </div>
        </li>;
    }

    return (
        <div className="cs-patient-immunizations-page">
            <div className="mbsc-row cs-patient-immunizations-section cs-patient-immunizations-title">
                <div className="mbsc-col" >
                    <div className="cs-patient-immunizations-title-text">
                        <p className="mbsc-txt-muted mbsc-bold">{locale.patientImmunizations.a}</p>
                    </div>
                </div>
                <div className="mbsc-col-auto" >
                    <Button color="primary" variant="flat" className="cs-patient-immunizations-button mbsc-bold" onClick={() => ButtonCreate()} >
                        {locale.patientImmunizations.b}
                    </Button>
                </div>
            </div>
            {immunizations.length !== 0 ?
                <Listview
                    theme="ios"
                    themeVariant="light"
                    select="single"
                    itemType={ListItem}
                    data={immunizations}
                />
                :
                <div className="mbsc-row cs-patient-immunizations-section cs-patient-immunizations-body" />
            }
            <div className={immunizations.length === 0 ? "mbsc-row cs-patient-immunizations-footer-empty" : "mbsc-row cs-patient-immunizations-footer"} />
            {dialog.view === 'patientImmunization' &&
                <PatientImmunization
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    setPatient={setPatient}
                    setImmunizations={setImmunizations}
                    immunizations={immunizations}
                    patient={patient}
                    width={width}
                    immunization={immunization}
                />
            }
        </div>
    );
}

export default PatientImmunizations;
