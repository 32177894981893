import React, {useCallback, useState, useEffect, useContext} from 'react';
import {Popup, toast, Input, Textarea, Datepicker, Select, setOptions, Button} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/queueNewVisit.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/pro-duotone-svg-icons";
import Empty from "../utils/Empty";
import User from "../utils/User";
import dayjs from 'dayjs';
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
  theme: 'ios',
  themeVariant: 'light',
});

function QueueNewVisit({locale, configuration, dialog, Dialog, type, width, visit, location, setRefresh}) {
  const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
  const [firstName, setFirstName] = useState(visit.firstName ? visit.firstName : null);
  const [middleName, setMiddleName] = useState(visit.middleName ? visit.middleName : null);
  const [lastName, setLastName] = useState(visit.lastName ? visit.lastName : null);
  const [paternalName, setPaternalName] = useState(visit.paternalName ? visit.paternalName : null);
  const [maternalName, setMaternalName] = useState(visit.maternalName ? visit.maternalName : null);
  const [dob, setDob] = useState(visit.dob ? visit.dob : null);
  const [gender, setGender] = useState(visit.gender ? visit.gender : 'male');
  const [reason, setReason] = useState(null);

  const DialogClose = useCallback(() => {
    Dialog({view: 'close'});
  }, [Dialog]);

  const ButtonCheckin = data => {
    if (configuration.patients.longName && !firstName && !paternalName) {
      toast({message: locale.queueNewVisit.a, color: 'danger', duration: 3000, display: 'bottom'});
    } else if (!configuration.patients.longName && !firstName && !lastName) {
      toast({message: locale.queueNewVisit.b, color: 'danger', duration: 3000, display: 'bottom'});
    } else {
      setRefresh(true);
      const update = {};

      if (!visit.new && firstName !== visit.firstName) {
        update.firstName = true;
      } else if (!visit.new && middleName !== visit.middleName) {
        update.middleName = true;
      } else if (!visit.new && lastName !== visit.lastName) {
        update.lastName = true;
      } else if (!visit.new && paternalName !== visit.paternalName) {
        update.paternalName = true;
      } else if (!visit.new && maternalName !== visit.maternalName) {
        update.maternalName = true;
      } else if (!visit.new && dob !== visit.dob) {
        update.dob = true;
      } else if (!visit.new && gender !== visit.gender) {
        update.gender = true;
      }

      const message = {
        type: 'wss',
        path: 'visit',
        action: 'put',
        data: {
          firstName: firstName ? firstName : null,
          middleName: middleName ? middleName : null,
          lastName: lastName ? lastName : null,
          paternalName: paternalName ? paternalName : null,
          maternalName: maternalName ? maternalName : null,
          dob: dob ? dob : null,
          gender: gender ? gender : null,
          user: visit.user,
          reason: reason ? reason : null,
          type: type ? type : null,
          location: location,
          new: visit.new ? visit.new : null,
          status: data,
          firstVisit: visit.firstVisit ? visit.firstVisit : null,
          update: update
        }
      };
      wsCall(message);
    }
  }

  const PutVisit = useCallback(data => {
    if (data.toast) {
      toast({message: locale.queueNewVisit.s, color: 'info', duration: 1000, display: 'bottom'});
      DialogClose();
      setRefresh(false);
    }
  }, [locale.queueNewVisit.s, DialogClose]);

  useEffect(() => {
    if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'visit') {
      PutVisit(wsResponse.data);
      setWsResponse({});
    }
  }, [wsResponse, PutVisit, setWsResponse]);

  return (
      <Popup className="cs-queue-new-visit-popup" width={600} display="center" closeOnOverlayClick={false} closeOnEsc={false} scrollLock={false} buttons={[]} isOpen={dialog.view === 'queueNewVisit'} onClose={DialogClose}>
        <div className="mbsc-grid cs-queue-new-visit-header-col">
          <div className="mbsc-row cs-queue-new-visit-header-row">
            {width > 576 &&
                <div className="mbsc-col cs-queue-new-visit-header-col" />
            }
            <div className={width > 576 ? "mbsc-col cs-queue-new-visit-header-col mbsc-align-center" : "mbsc-col cs-queue-new-visit-header-col"} >
              <Button className="cs-queue-new-visit-event-button mbsc-bold mbsc-txt-muted" variant="flat" >
                {User(visit)}
              </Button>
            </div>
            <div className="mbsc-col cs-queue-new-visit-header-col mbsc-align-right">
              <Button color="primary" variant="flat" className="mbsc-bold cs-queue-new-visit-close-button" onClick={() => DialogClose()} >
                &nbsp;<FontAwesomeIcon className="cs-queue-new-visit-close-icon" icon={faXmark} />
              </Button>
            </div>
          </div>
        </div>
        {visit.new ?
            <div className="cs-queue-new-visit-helper">
              <p>{locale.queueNewVisit.m}</p>
            </div>
            :
            <div className="cs-queue-new-visit-helper">
              <p>{locale.queueNewVisit.n}</p>
            </div>
        }
        {configuration.patients.longName ?
            <>
              <Input inputStyle="underline" label={locale.queueNewVisit.j} labelStyle="floating" type="text" name="firstName" value={firstName} onChange={ev => setFirstName(ev.target.value)} />
              <Input inputStyle="underline" label={locale.queueNewVisit.l} labelStyle="floating" type="text" name="middleName" value={middleName} onChange={ev => setMiddleName(ev.target.value)} />
              <Input inputStyle="underline" label={locale.queueNewVisit.i} labelStyle="floating" type="text" name="paternalName" value={paternalName} onChange={ev => setPaternalName(ev.target.value)} />
              <Input inputStyle="underline" label={locale.queueNewVisit.h} labelStyle="floating" type="text" name="maternalName" value={maternalName} onChange={ev => setMaternalName(ev.target.value)} />
            </>
            :
            <>
              <Input inputStyle="underline" label={locale.queueNewVisit.j} labelStyle="floating" type="text" name="firstName" value={firstName} onChange={ev => setFirstName(ev.target.value)} />
              <Input inputStyle="underline" label={locale.queueNewVisit.k} labelStyle="floating" type="text" name="lastName" value={lastName} onChange={ev => setLastName(ev.target.value)} />
            </>
        }
        <Datepicker
            controls={['date']}
            touchUi={width > 576 ? false : true}
            inputStyle="underline"
            label={locale.queueNewVisit.f}
            labelStyle="floating"
            value={dob}
            selectMultiple={false}
            max={dayjs().format("YYYY-M-D")}
            onChange={args => setDob(args.value)}
            buttons={['set', 'cancel']}
            setText={locale.queueNewVisit.t}
            cancelText={locale.queueNewVisit.u}
            dateFormat="YYYY-M-D"
            dateWheels="|YYYY | MMM | D|"
        />
        <Select
            animation="slide-down"
            inputStyle="underline"
            label={locale.queueNewVisit.p}
            labelStyle="floating"
            touchUi={false}
            rows={locale.queueNewVisit.q.length}
            display="anchored"
            data={locale.queueNewVisit.q}
            value={gender}
            onChange={args => setGender(args.value)}
        />
        <div className="cs-queue-new-visit-textarea">
          <Textarea inputStyle="underline" labelStyle="floating" label={locale.queueNewVisit.e} value={reason} onChange={(ev) => setReason(ev.target.value)} />
        </div>
        <div className="mbsc-button-group-justified cs-queue-new-visit-nav-buttons">
          <Button color="primary" variant="flat" className="mbsc-bold cs-queue-new-visit-nav-button" onClick={() => ButtonCheckin('in')} >
            {locale.queueNewVisit.r}
          </Button>
          <Button color="primary" variant="flat" className="mbsc-bold cs-queue-new-visit-nav-button" onClick={() => ButtonCheckin('wait')} >
            {locale.queueNewVisit.c}
          </Button>
        </div>
      </Popup>
  );
}

export default QueueNewVisit;
