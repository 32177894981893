import React, {useCallback, useContext, useEffect} from 'react';
import {Button, setOptions, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardHeader.css';
import {faHospitalUser, faBars, faPipe, faArrowLeft, faCalendars} from "@fortawesome/pro-duotone-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Empty from "../utils/Empty";
import DashboardHeaderHelp from "../dialogs/DashboardHeaderHelp";
import DashboardHeaderProfile from "../dialogs/DashboardHeaderProfile";
import DashboardHeaderMenu from "../dialogs/DashboardHeaderMenu";
import DashboardHeaderReset from "../dialogs/DashboardHeaderReset";
import DashboardHeaderPatients from "../dialogs/DashboardHeaderPatients";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardHeader({Navigation, locale, navigation, user, setUser, dialog, Dialog, width, configuration, token, wss, setLocale, setVisit, setPatient}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);

    const ButtonUpdate = data => {
        Navigation(data);

        const message = {
            type: 'wss',
            path: 'user-ui-navigation',
            action: 'update',
            data: {
                navigation: data
            }
        };
        wsCall(message);
    }

    const UpdateUserUiNavigation = useCallback(data => {
        if (data.toast){
            toast({message: locale.dashboardHeader.l, color: 'info', display: 'bottom', duration: 1000});
        }
    }, [locale.dashboardHeader.l]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'user-ui-navigation') {
            UpdateUserUiNavigation(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateUserUiNavigation, setWsResponse]);

    useEffect(() => {
        if (user.ui.navigation) {

            Navigation(user.ui.navigation)
        } else if (configuration.options.queue) {

            ButtonUpdate({page:'queue', tab: 'wait'})
        } else if (configuration.options.calendar) {

            ButtonUpdate({page: 'calendar', tab: 'day'})
        }
    }, []);

    return (
        <div className="mbsc-grid cs-dashboard-header-toolbar">
            <div className="mbsc-row">
                <div className={width > 768 ? 'mbsc-col' : 'mbsc-col cs-dashboard-header-col-xs'} >
                    <div className="mbsc-row">
                        {width > 576 &&
                            <div className="cs-dashboard-header-logo" style={{backgroundImage: `url(${configuration.logo})`}} />
                        }
                        {width > 576 &&
                            <Button className="cs-dashboard-header-button-text-logo mbsc-bold" variant="flat" >
                                {configuration.displayName}
                            </Button>
                        }
                    </div>
                </div>
                <div className={width > 768 ? 'mbsc-col-auto' : 'mbsc-col-auto cs-dashboard-header-col-xs'} >
                    <div className="mbsc-row mbsc-justify-content-end">
                        {navigation.page === 'patient' && !Empty(navigation.back) &&
                            <Button color="primary" variant="flat" disabled={navigation.page === 'queue'} title={locale.dashboardHeader.j} className="mbsc-bold cs-dashboard-header-button-text" onClick={() => Navigation({back: true})} >
                                <FontAwesomeIcon className="cs-dashboard-header-button-icon" icon={faArrowLeft} /> {width > 992 && <div className="cs-dashboard-header-menu-text">{locale.dashboardHeader.j}</div>}
                            </Button>
                        }
                        {navigation.page === 'patient' && !Empty(navigation.back) &&
                            <Button color="secondary" variant="flat" >
                                <FontAwesomeIcon className="cs-dashboard-header-button-icon-pipe" icon={faPipe} />
                            </Button>
                        }
                        {configuration.options.queue && (navigation.page !== 'patient' || (navigation.page === 'patient' && Empty(navigation.back))) &&
                            <Button color="primary" variant="flat" disabled={navigation.page === 'queue'} title={locale.dashboardHeader.h} className="mbsc-bold cs-dashboard-header-button-text" onClick={() => ButtonUpdate({page: 'queue', tab: 'wait'})} >
                                <FontAwesomeIcon className="cs-dashboard-header-button-icon" icon={faHospitalUser} /> {width > 992 && <div className="cs-dashboard-header-menu-text">{locale.dashboardHeader.h}</div>}
                            </Button>
                        }
                        {configuration.options.queue && (navigation.page !== 'patient' || (navigation.page === 'patient' && Empty(navigation.back))) &&
                            <Button color="secondary" variant="flat" >
                                <FontAwesomeIcon className="cs-dashboard-header-button-icon-pipe" icon={faPipe} />
                            </Button>
                        }
                        {configuration.options.calendar && (navigation.page !== 'patient' || (navigation.page === 'patient' && Empty(navigation.back))) &&
                            <Button color="primary" variant="flat" disabled={navigation.page === 'calendar'} title={locale.dashboardHeader.h} className="mbsc-bold cs-dashboard-header-button-text" onClick={() => ButtonUpdate({page: 'calendar', tab: 'day'})} >
                                <FontAwesomeIcon className="cs-dashboard-header-button-icon" icon={faCalendars} /> {width > 992 && <div className="cs-dashboard-header-menu-text">{locale.dashboardHeader.k}</div>}
                            </Button>
                        }
                        {configuration.options.calendar && (navigation.page !== 'patient' || (navigation.page === 'patient' && Empty(navigation.back))) &&
                            <Button color="secondary" variant="flat" >
                                <FontAwesomeIcon className="cs-dashboard-header-button-icon-pipe" icon={faPipe} />
                            </Button>
                        }
                        <Button color="primary" variant="flat" className="cs-dashboard-header-button-menu"  id="dashboardHeaderMenu" title={locale.dashboardHeader.e} onClick={() => Dialog({view: 'dashboardHeaderMenu'})} >
                            <FontAwesomeIcon className="cs-dashboard-header-button-icon" icon={faBars} />
                        </Button>
                    </div>
                </div>
            </div>
            {dialog.view === 'dashboardHeaderMenu' &&
                <DashboardHeaderMenu
                    dialog={dialog}
                    Dialog={Dialog}
                    locale={locale}
                    user={user}
                    token={token}
                    wss={wss}
                    setUser={setUser}
                    setLocale={setLocale}
                    configuration={configuration}
                />
            }
            {dialog.view === 'dashboardHeaderHelp' &&
                <DashboardHeaderHelp
                    locale={locale}
                    dialog={dialog}
                    Dialog={Dialog}
                    width={width}
                />
            }
            {dialog.view === 'dashboardHeaderProfile' &&
                <DashboardHeaderProfile
                    locale={locale}
                    dialog={dialog}
                    Dialog={Dialog}
                    user={user}
                    width={width}
                />
            }
            {dialog.view === 'dashboardHeaderReset' &&
                <DashboardHeaderReset
                    locale={locale}
                    dialog={dialog}
                    Dialog={Dialog}
                    setUser={setUser}
                    width={width}
                />
            }
            {dialog.view === 'dashboardHeaderPatients' &&
                <DashboardHeaderPatients
                    locale={locale}
                    dialog={dialog}
                    Dialog={Dialog}
                    setVisit={setVisit}
                    setPatient={setPatient}
                    width={width}
                />
            }
        </div>
    );
}

export default DashboardHeader;
