import React, {useEffect, useState, useCallback, useContext} from 'react';
import {Select, setOptions, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview, NavItem, TabNav} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import 'dayjs/locale/es'
import '../styles/queue.css';
import Empty from '../utils/Empty';
import Menu from "../navigation/Menu";
import Item from "../navigation/Item";
import QueueWait from "../blocks/QueueWait";
import QueueIn from "../blocks/QueueIn";
import QueueTransfer from "../blocks/QueueTransfer";
import QueueNewVisit from "../dialogs/QueueNewVisit";
import QueueVisitTransfer from "../dialogs/QueueVisitTransfer";
import QueueVisit from "../dialogs/QueueVisit";
import NewMenu from "../shared/NewMenu";
import NewCheck from "../shared/NewCheck";
import QR from "../shared/QR";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
  theme: 'ios',
  themeVariant: 'light',
});

function Queue({locale, width, navigation, Navigation, configuration, dialog, Dialog, user, setPatient, refresh, setRefresh, setVisit, visit, location, setLocation}) {
  const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
  const [type, setType] = useState('');
  const [qr, setQr] = useState({});

  const SelectLocation = data => {
    setRefresh(true);
    setLocation(data);

    const message = {
      type: 'wss',
      path: 'user',
      action: 'update',
      data: {
        location: data
      }
    };
    wsCall(message);
  }

  const PutShortener = useCallback(data => {
    if (data.qr) {
      setQr(data.qr);
    }
    if (data.qr && !dialog.view) {
      Dialog({view: 'sharedQr'});
    }
  }, [Dialog]);

  const UpdatePatient = useCallback(data => {
    if (data.toast) {
      toast({message: locale.queue.e, color: 'info', duration: 1000, display: 'bottom'});
    }
  }, [locale.queue.e]);

  useEffect(() => {
    if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'patient') {
      UpdatePatient(wsResponse.data);
      setWsResponse({});
    }
  }, [wsResponse, UpdatePatient, setWsResponse]);

  useEffect(() => {
    if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'shortener') {
      PutShortener(wsResponse.data);
      setWsResponse({});
    }
  }, [wsResponse, PutShortener, setWsResponse]);

  useEffect(() => {
    if (configuration.locations.length === 1) {
      Navigation({menu: locale.queue.c});
    } else if (configuration.locations.length > 1 && configuration.queue.transfer) {
      Navigation({menu: locale.queue.d});
    }
  }, []);

  return (
      <div className={width > 768 ? "mbsc-grid cs-queue-main" : "mbsc-grid cs-queue-main-xs"} >
        <div className={width > 768 ? "mbsc-row cs-queue-container" : "mbsc-row cs-queue-container-xs"}>
          <div className="mbsc-col cs-queue-title cs-queue-title-input">
            {configuration.locations.length <= 1 &&
                <p className={width > 768 ? "mbsc-txt-l" : "mbsc-txt-m"}>
                  {locale.queue.b}
                </p>
            }
            {configuration.locations.length > 1 &&
                <Select
                    animation="slide-down"
                    dropdown={false}
                    inputStyle="box"
                    rows={configuration.locations.length > 10 ? 10 : configuration.locations.length}
                    display="anchored"
                    touchUi={false}
                    value={location}
                    data={configuration.locations}
                    onChange={args => SelectLocation(args.value)}
                />
            }
          </div>
        </div>
        <div className="mbsc-row">
          {width > 768 &&
              <div className="mbsc-col-12 mbsc-col-md-3 mbsc-col-sm-12 cs-queue-left">
                {navigation.menu && navigation.menu.length !== 0 &&
                    <Listview
                        theme="ios"
                        themeVariant="light"
                        select="single"
                        itemType={event => Item(event, navigation)}
                        data={navigation.menu}
                        onItemTap={event => Menu(event.index, Navigation, navigation)}
                    />
                }
              </div>
          }
          <div className={width > 576 ? "mbsc-col-12 mbsc-col-md-9 mbsc-col-sm-12 cs-queue-right" : "mbsc-col-12 mbsc-col-md-9 mbsc-col-sm-12 cs-queue-right-xs"} >
            {navigation.menu && navigation.menu.length !== 0 && width < 768 &&
                <TabNav theme="ios" themeVariant="light" display='inline' >
                  {navigation.menu.map((item) => {
                    return (
                        <NavItem selected={navigation.tab === item.name} onClick={(ev) => Menu(item.name, Navigation, navigation)} >
                          <span className={navigation.tab === item.name ? 'mbsc-bold cs-queue-nav' : 'mbsc-bold'} >{item.label}</span>
                        </NavItem>
                    );
                  })}
                </TabNav>
            }
            {navigation.tab === 'wait' && !refresh &&
                <QueueWait
                    locale={locale}
                    Dialog={Dialog}
                    setVisit={setVisit}
                    location={location}
                    setQr={setQr}
                    setPatient={setPatient}
                    width={width}
                />
            }
            {navigation.tab === 'in' && !refresh &&
                <QueueIn
                    locale={locale}
                    Dialog={Dialog}
                    setVisit={setVisit}
                    location={location}
                    setPatient={setPatient}
                    width={width}
                />
            }
            {navigation.tab === 'transfer' && !refresh &&
                <QueueTransfer
                    locale={locale}
                    Dialog={Dialog}
                    setVisit={setVisit}
                    location={location}
                    width={width}
                />
            }
          </div>
        </div>
        {dialog.view === 'sharedNewMenu' &&
            <NewMenu
                locale={locale}
                dialog={dialog}
                Dialog={Dialog}
                setType={setType}
                configuration={configuration}
                anchor={null}
                instance={{}}
                type={type}
                visit={visit}
                navigation={navigation}
                Busy={null}
            />
        }
        {dialog.view === 'sharedNewCheck' &&
            <NewCheck
                locale={locale}
                dialog={dialog}
                Dialog={Dialog}
                type={type}
                user={user}
                setVisit={setVisit}
                navigation={navigation}
                visit={visit}
                instance={{}}
                anchor={null}
                width={width}
            />
        }
        {dialog.view === 'queueNewVisit' &&
            <QueueNewVisit
                Dialog={Dialog}
                dialog={dialog}
                locale={locale}
                type={type}
                configuration={configuration}
                width={width}
                visit={visit}
                location={location}
                setRefresh={setRefresh}
            />
        }
        {dialog.view === 'queueVisitTransfer' &&
            <QueueVisitTransfer
                locale={locale}
                Dialog={Dialog}
                dialog={dialog}
                setRefresh={setRefresh}
                visit={visit}
                configuration={configuration}
                width={width}
            />
        }
        {dialog.view === 'queueVisit' &&
            <QueueVisit
                Dialog={Dialog}
                dialog={dialog}
                locale={locale}
                configuration={configuration}
                width={width}
                visit={visit}
                setRefresh={setRefresh}
                navigation={navigation}
                user={user}
                setQr={setQr}
                qr={qr}
            />
        }
        {dialog.view === 'sharedQr' && !Empty(qr) &&
            <QR
                locale={locale}
                dialog={dialog}
                Dialog={Dialog}
                qr={qr}
                width={width}
            />
        }
      </div>
  );
}

export default Queue;
