import React, {useEffect, useState, useCallback, useContext} from 'react';
import {Select, setOptions, toast, Button, Eventcalendar, Datepicker, confirm} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/es'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faCalendarCirclePlus,
  faQrcode,
  faUserPlus,
  faCalendarLinesPen,
  faCalendarRange,
  faEllipsisVertical,
  faChevronRight,
  faChevronLeft,
  faCircleNotch,
  faPipe
} from "@fortawesome/pro-duotone-svg-icons";
import '../styles/calendar.css';
import Empty from '../utils/Empty';
import QR from "../shared/QR";
import NewMenu from "../shared/NewMenu";
import NewCheck from "../shared/NewCheck";
import NewEvent from "../shared/NewEvent";
import CalendarRange from "../dialogs/CalendarRange";
import CalendarDisplay from "../dialogs/CalendarDisplay";
import CalendarSelect from "../dialogs/CalendarSelect";
import Event from "../shared/Event";
import Loading from "../utils/Loading";
import Truncate from "../utils/Truncate";
import {NetworkContext} from "../utils/NetworkContext";

dayjs.extend(utc);
setOptions({
  theme: 'ios',
  themeVariant: 'light',
});

function Calendar({locale, width, navigation, configuration, dialog, Dialog, user, setRefresh, refresh, location, setLocation}) {
  const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
  const [range, setRange] = useState(user.ui.calendarRange ? user.ui.calendarRange : 'day');
  const [qr, setQr] = useState({});
  const [hours, setHours] = useState({});
  const [doctors, setDoctors] = useState([]);
  const [events, setEvents] = useState([]);
  const [resources, setResources] = useState([]);
  const [selection, setSelection] = useState(user.ui.calendars ? user.ui.calendars : []);
  const [date, setDate] = useState(user.ui.calendarDate ? user.ui.calendarDate : dayjs().format());
  const [type, setType] = useState('');
  const [anchor, setAnchor] = useState('');
  const [instance, setInstance] = useState({});
  const [visit, setVisit] = useState({});
  const [loading, setLoading] = useState(true);
  const [display, setDisplay] = useState(user.ui.calendarDisplay ? user.ui.calendarDisplay : 'scheduler');

  const Busy = data => {
    setRefresh(true);

    const message = {
      type: 'wss',
      path: 'event',
      action: 'put',
      data: {
        start: dayjs(visit.start).format(),
        end: dayjs(visit.end).format(),
        resource: visit.resource,
        type: data,
        location: location
      }
    };
    wsCall(message);
  }

  const Settings = () => {

    const message = {
      type: 'wss',
      path: 'calendar-settings',
      action: 'get',
      data: {
        location: location,
        date: date,
        range: range
      }
    };
    wsCall(message);
  }

  const SelectLocation = data => {
    setRefresh(true);
    setLocation(data);

    const message = {
      type: 'wss',
      path: 'user',
      action: 'update',
      data: {
        location: data
      }
    };
    wsCall(message);
  }

  const ButtonRange = () => {
    if (range === 'week') {
      return locale.calendar.d;
    } else if (range === 'month') {
      return locale.calendar.e;
    } else if (range === 'day') {
      return locale.calendar.c;
    }
  }

  const ButtonDisplay = () => {
    if (display === 'agenda') {
      return locale.calendar.p;
    } else if (display === 'scheduler') {
      return locale.calendar.t;
    }
  }

  const ButtonCreate = () => {
    setAnchor(null);
    setType('');
    setVisit(selection.length === 1 ? {resource: selection[0]} : {});
    setInstance({});
    Dialog({view: 'sharedNewMenu'});
  }

  const ButtonShare = () => {

  }

  const ButtonDatePlus = () => {
    const itemDate = date;
    setDate(dayjs(itemDate).add(1, 'day').format("YYYY-M-D"));
  }

  const ButtonDateMinus = () => {
    const itemDate = date;
    setDate(dayjs(itemDate).subtract(1, 'day').format("YYYY-M-D"));
  }

  const ButtonDateToday = () => {
    setDate(dayjs().format("YYYY-M-D"));
  }

  const PickerFormat = () => {
    if (range === 'day') {
      return "YYYY-M-D";
    } else if (range === 'week') {
      return "YYYY-M-D";
    } else if (range === 'month') {
      return "YYYY-M";
    }
  }

  const PickerWheels = () => {
    if (range === 'day') {
      return "|YYYY | MMM | D|"
    } else if (range === 'week') {
      return "|YYYY | MMM | D|"
    } else if (range === 'month') {
      return "|YYYY | MMM|"
    }
  }

  const PickerUpdate = data => {
    const itemDate = dayjs(data).format("YYYY-M-D");
    setDate(itemDate);
  }

  const CalendarView = () => {
    if (display === 'agenda') {
      return {
        agenda: {
          type: range,
          startTime: hours.startTime,
          endTime: hours.endTime,
          allDay: false,
          showEmptyDays: true
        }
      }
    } else if (range === 'day' && selection.length > 2) {
      return {
        timeline: {
          type: 'day',
          startTime: hours.startTime,
          endTime: hours.endTime,
          allDay: false
        }
      }
    } else if (range === 'day' && selection.length <= 2) {
      return {
        schedule: {
          type: 'day',
          startTime: hours.startTime,
          endTime: hours.endTime,
          allDay: false
        }
      }
    } else if (range === 'week') {
      return {
        schedule: {
          type: 'week',
          startTime: hours.startTime,
          endTime: hours.endTime,
          allDay: false
        }
      }
    } else if (range === 'month') {
      return {
        calendar: {
          allDay: false,
          type: 'month',
          labels: 'all',
        }
      }
    }
  }

  const CalendarGroupBy = () => {
    if (range === 'week' && display === 'scheduler') {
      return "date"
    } else {
      return "";
    }
  }

  const CalendarResource = data => {
    return <div className={(range === 'day' && selection.length > 2) ? "mbsc-row" : "mbsc-row mbsc-justify-content-center"} >
      <div style={{backgroundImage: "url(https://www.gravatar.com/avatar/" + data.avatar + "?d=robohash)"}} className="cs-calendar-resource-avatar" />
      <div className="cs-calendar-resource-text" >{data.name}</div>
    </div>;
  }

  const CalendarCreateInline = (data, inst) => {
    setAnchor(data.target);
    setType('');
    setVisit({start: data.event.start, end: data.event.end, id: data.event.id, resource: data.event.resource});
    setInstance(inst);
    Dialog({view: 'sharedNewMenu'});
  }

  const CalendarUpdate = data => {

    const message = {
      type: 'wss',
      path: 'event',
      action: 'update',
      data: {
        start: dayjs(data.event.start).format(),
        end: dayjs(data.event.end).format(),
        resource: data.event.resource,
        user: data.event.user,
        event: data.event.id
      }
    };
    wsCall(message);
  }

  const CalendarDelete = data => {

    confirm({
      title: locale.calendar.i,
      message: locale.calendar.j,
      okText: locale.calendar.k,
      cancelText: locale.calendar.l,
      callback: (res) => {
        if (res) {
          setRefresh(true);

          const message = {
            type: 'wss',
            path: 'event',
            action: 'delete',
            data: {
              event: data.event
            }
          };
          wsCall(message);
        }
      }
    })

    return false;
  }

  const CalendarEvents = data => {

    const message = {
      type: 'wss',
      path: 'events',
      action: 'get',
      data: {
        location: location,
        start: dayjs(data.firstDay).format(),
        end: dayjs(data.lastDay).format(),
        selection: selection
      }
    };
    wsCall(message);
  }

  const CalendarOpen = data => {
    if (display === 'scheduler') {
      setAnchor(data.domEvent.target);
    } else {
      setAnchor(null);
    }
    setVisit(data.event);
    Dialog({view: 'sharedEvent'});
  }

  const EventAvatar = data => {
    const user = resources.find(user => user.id === data);
    return user ? user.avatar : null;
  }

  const EventInitials = data => {
    if (!data) return '';

    return data
        .trim()
        .split(/\s+/) // Splits by any number of spaces
        .filter(word => word.length > 0)
        .map(word => word[0])
        .join('')
        .toUpperCase();
  }

  const EventContent = data => {
    let itemTitle = data.original.title;
    let itemAge = '';
    let itemGender = '';
    let itemReturn = '';

    if (data.original.dob) {
      const today = dayjs();
      const birthDate = dayjs(data.original.dob);
      const age = today.diff(birthDate, 'year');

      itemAge = age + ' ' + locale.calendar.q;
    }
    if (data.original.gender) {
      itemGender = data.original.gender === 'male' ? locale.calendar.r : locale.calendar.s;
    }

    if ((display === 'agenda' && selection.length === 1) ||
        (display === 'scheduler' && range === 'day' && selection.length === 1) ||
        (display === 'scheduler' && range === 'day' && selection.length === 2 && width > 768)) {
      itemReturn = <>
        <div>
          {itemTitle}
          {(itemAge || itemGender) && <FontAwesomeIcon className="cs-calendar-event-icon-pipe-xs" icon={faPipe} />}
          {itemAge && itemAge}
          {(itemAge && itemGender) && ', '}
          {itemGender && itemGender}
          {data.original.firstVisit && <FontAwesomeIcon className="cs-calendar-event-icon-pipe-xs" icon={faPipe} />}
          {data.original.firstVisit && <><FontAwesomeIcon className="cs-calendar-event-icon-info-xs" icon={faUserPlus} /></>}
        </div>
        {data.original.reason &&
            <div className="cs-calendar-event-secondary">{Truncate(data.original.reason, 30)}</div>
        }
      </>
    } else if (display === 'agenda' && selection.length > 1) {
      itemReturn = <>
        <div className="mbsc-row">
          <div style={{backgroundImage: "url(https://www.gravatar.com/avatar/" + EventAvatar(data.resource) + "?d=robohash)"}} className="cs-calendar-resource-avatar" />
          <div className="cs-calendar-event-agenda-resource">
            <div>
              {itemTitle}
              {(itemAge || itemGender) && <FontAwesomeIcon className="cs-calendar-event-icon-pipe-xs" icon={faPipe} />}
              {itemAge && itemAge}
              {(itemAge && itemGender) && ', '}
              {itemGender && itemGender}
              {data.original.firstVisit && <FontAwesomeIcon className="cs-calendar-event-icon-pipe-xs" icon={faPipe} />}
              {data.original.firstVisit && <><FontAwesomeIcon className="cs-calendar-event-icon-info-xs" icon={faUserPlus} /></>}
            </div>
            <div className="cs-calendar-event-secondary">{data.original.reason && data.original.reason}</div>
          </div>
        </div>
      </>
    } else if (display === 'scheduler' &&
        ((range === 'week' && selection.length === 1) ||
        (range === 'day' && selection.length === 3) ||
        (range === 'day' && selection.length === 2 && width < 768))) {
      itemReturn = <>
        <div>
          {itemTitle}
          {data.original.firstVisit && <FontAwesomeIcon className="cs-calendar-event-icon-pipe-xs" icon={faPipe} />}
          {data.original.firstVisit && <><FontAwesomeIcon className="cs-calendar-event-icon-info-xs" icon={faUserPlus} /></>}
        </div>
        {data.original.reason &&
            <div className="cs-calendar-event-secondary">{Truncate(data.original.reason, 15)}</div>
        }
      </>
    } else if (display === 'scheduler' && range === 'week' && selection.length > 1) {
      itemReturn = <>
        <div>
          {EventInitials(itemTitle)}
          {data.original.firstVisit && <FontAwesomeIcon className="cs-calendar-event-icon-pipe-xs" icon={faPipe} />}
          {data.original.firstVisit && <><FontAwesomeIcon className="cs-calendar-event-icon-info-xs" icon={faUserPlus} /></>}
        </div>
      </>
    } else if (display === 'scheduler' && range === 'month') {
    itemReturn = <>
      <div style={{background: data.original.color + '80', color: '#000'}} className="cs-calendar-event-month mbsc-bold">
        {width < 768 ? EventInitials(itemTitle) : itemTitle}
        {width > 992 && data.original.firstVisit && <FontAwesomeIcon className="cs-calendar-event-icon-pipe-xs" icon={faPipe} />}
        {width > 992 && data.original.firstVisit && <><FontAwesomeIcon className="cs-calendar-event-icon-info-xs" icon={faUserPlus} /></>}
      </div>
    </>
  }

    return itemReturn;
  }

  const SelectDoctors = data => {
    setSelection(data);
    const array = doctors.filter(user => data.includes(user.id));
    setResources(array);

    const message = {
      type: 'wss',
      path: 'user-ui-calendars',
      action: 'update',
      data: {
        calendars: data,
        range: range,
        location: location,
        date: date
      }
    };
    wsCall(message);
  }

  const StyleSectionMenu = () => {
    if (width > 576 && display === 'agenda') {
      return "mbsc-row cs-calendar-menu-agenda";
    } else if (width < 576 && display === 'agenda') {
      return "mbsc-row cs-calendar-menu-agenda-xs";
    } else if (width > 576 && display !== 'agenda') {
      return "mbsc-row cs-calendar-menu";
    } else if (width < 576 && display !== 'agenda') {
      return "mbsc-row cs-calendar-menu-xs";
    }
  }

  const PutShortener = useCallback(data => {
    if (data.qr) {
      setQr(data.qr);
    }
    if (data.qr && !dialog.view) {
      Dialog({view: 'sharedQr'});
    }
  }, [Dialog, dialog.view]);

  const GetCalendarSettings = useCallback(data => {
    if (data.hours) {
      setHours(data.hours);
    }
    if (data.doctors) {
      setDoctors(data.doctors);
    }
    if (data.selection) {
      setSelection(data.selection);
    }
    if (data.resources) {
      setResources(data.resources);
    }
    setLoading(false);
  }, []);

  const GetEvents = useCallback(data => {
    if (data.events) {
      setEvents(data.events);
    }
  }, []);

  const UpdateUserUiCalendars = useCallback(data => {
    if (data.events) {
      setEvents(data.events);
    }
    if (data.toast){
      toast({message: locale.calendar.a, color: 'info', display: 'bottom', duration: 1000});
    }
  }, [locale.calendar.a]);

  const UpdateEvent = useCallback(data => {
    if (data.toast){
      toast({message: locale.calendar.h, color: 'info', display: 'bottom', duration: 1000});
    }
    if (dialog.view) {
      Dialog({view: 'close'});
    }
    if (refresh) {
      setRefresh(false);
    }
  }, [locale.calendar.h, dialog.view, Dialog, refresh]);

  const DeleteEvent = useCallback(data => {
    if (data.toast){
      toast({message: locale.calendar.m, color: 'info', display: 'bottom', duration: 1000});
    }
    if (dialog.view) {
      Dialog({view: 'close'});
    }
    if (refresh) {
      setRefresh(false);
    }
    setEvents([]);
  }, [locale.calendar.m, Dialog, dialog.view, refresh]);

  const PutEvent = useCallback(data => {
    if (data.toast) {
      toast({message: locale.calendar.n, color: 'info', duration: 1000, display: 'bottom'});
      setEvents([]);
    }
    if (dialog.view) {
      Dialog({view: 'close'});
    }
    if (refresh) {
      setRefresh(false);
    }
  }, [locale.calendar.n, dialog.view, Dialog, refresh]);

  useEffect(() => {
    if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'event') {
      PutEvent(wsResponse.data);
      setWsResponse({});
    }
  }, [wsResponse, PutEvent, setWsResponse]);

  useEffect(() => {
    if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'delete' && wsResponse.path === 'event') {
      DeleteEvent(wsResponse.data);
      setWsResponse({});
    }
  }, [wsResponse, DeleteEvent, setWsResponse]);

  useEffect(() => {
    if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'event') {
      UpdateEvent(wsResponse.data);
      setWsResponse({});
    }
  }, [wsResponse, UpdateEvent, setWsResponse]);

  useEffect(() => {
    if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'user-ui-calendars') {
      UpdateUserUiCalendars(wsResponse.data);
      setWsResponse({});
    }
  }, [wsResponse, UpdateUserUiCalendars, setWsResponse]);

  useEffect(() => {
    if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'shortener') {
      PutShortener(wsResponse.data);
      setWsResponse({});
    }
  }, [wsResponse, PutShortener, setWsResponse]);

  useEffect(() => {
    if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'calendar-settings') {
      GetCalendarSettings(wsResponse.data);
      setWsResponse({});
    }
  }, [wsResponse, GetCalendarSettings, setWsResponse]);

  useEffect(() => {
    if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'events') {
      GetEvents(wsResponse.data);
      setWsResponse({});
    }
  }, [wsResponse, GetEvents, setWsResponse]);

  useEffect(() => {
    Settings();
  }, []);

  return (
      <div className={width > 768 ? "mbsc-grid cs-calendar-main" : "mbsc-grid cs-calendar-main-xs"}>
        <div className={width > 768 ? "mbsc-row cs-calendar-container" : "mbsc-row cs-calendar-container-xs"}>
          <div className="mbsc-col cs-calendar-title cs-calendar-title-input">
            {configuration.locations.length <= 1 &&
                <p className={width > 768 ? "mbsc-txt-l" : "mbsc-txt-m"}>
                  {locale.calendar.b}
                </p>
            }
            {configuration.locations.length > 1 &&
                <Select
                    animation="slide-down"
                    dropdown={false}
                    inputStyle="box"
                    rows={configuration.locations.length > 10 ? 10 : configuration.locations.length}
                    display="anchored"
                    touchUi={false}
                    value={location}
                    data={configuration.locations}
                    onChange={args => SelectLocation(args.value)}
                />
            }
          </div>
        </div>
        {!loading &&
            <div className={width > 576 ? "mbsc-row cs-calendar-menu" : "mbsc-row cs-calendar-menu-xs"} >
              <div className="mbsc-col cs-calendar-select" >
                {width < 768 &&
                    <Button id="calendarSelect" color="primary" variant="flat" className="mbsc-bold mbsc-txt-muted cs-calendar-button-text" onClick={() => Dialog({view: 'calendarSelect'})} >
                      <FontAwesomeIcon className="cs-calendar-icon" icon={faEllipsisVertical} />
                    </Button>
                }
                {width > 768 &&
                    <Select
                        animation="slide-down"
                        inputStyle="underline"
                        dropdown={false}
                        label={locale.calendar.f}
                        labelStyle="floating"
                        touchUi={false}
                        rows={doctors.length}
                        display="anchored"
                        selectMultiple={true}
                        value={selection}
                        data={doctors}
                        onChange={(args, inst) => {SelectDoctors(args.value); inst.close()}}
                    />
                }
              </div>
              <div className={width > 768 ? "mbsc-col-auto cs-calendar-buttons" : "mbsc-col-auto"} >
                <div className="mbsc-row">
                  <Button color="primary" variant="flat" className="mbsc-bold cs-calendar-button-text" onClick={() => ButtonShare()} >
                    <FontAwesomeIcon className={width > 768 ? "cs-calendar-icon" : "cs-calendar-icon-xs"} icon={faQrcode} /> {width > 768 && locale.calendar.o}
                  </Button>
                  <Button id="sharedNewMenu" color="primary" variant="flat" className="mbsc-bold cs-calendar-button-text" onClick={() => ButtonCreate()} >
                    <FontAwesomeIcon className={width > 768 ? "cs-calendar-icon" : "cs-calendar-icon-xs"} icon={faCalendarCirclePlus} /> {width > 768 && locale.calendar.g}
                  </Button>
                </div>
              </div>
            </div>
        }
        {!loading &&
            <div className={StyleSectionMenu()} >
              <div className="mbsc-col" >
                <div className="mbsc-row">
                  {width > 375 &&
                      <Button color="primary" variant="flat" className="mbsc-bold mbsc-txt-muted cs-calendar-button-text" onClick={() => ButtonDateMinus()} >
                        <FontAwesomeIcon className="cs-calendar-icon-xs" icon={faChevronLeft} />
                      </Button>
                  }
                  {width > 576 &&
                      <Button color="primary" variant="flat" className="mbsc-bold mbsc-txt-muted cs-calendar-button-text" onClick={() => ButtonDateToday()} >
                        {width > 768 ? locale.calendar.v : <FontAwesomeIcon className="cs-calendar-icon-xs" icon={faCircleNotch} />}
                      </Button>
                  }
                  {width > 375 &&
                      <Button color="primary" variant="flat" className="mbsc-bold mbsc-txt-muted cs-calendar-button-text" onClick={() => ButtonDatePlus()} >
                        <FontAwesomeIcon className="cs-calendar-icon-xs" icon={faChevronRight} />
                      </Button>
                  }
                </div>
              </div>
              <div className={width > 768 ? "mbsc-col-auto cs-calendar-buttons" : "mbsc-col-auto"} >
                <div className="mbsc-row">
                  <Button disabled={selection.length === 0} color="primary" variant="flat" className="cs-calendar-picker cs-calendar-button-text mbsc-bold mbsc-txt-muted" >
                    <Datepicker
                        disabled={selection.length === 0}
                        controls={['date']}
                        touchUi={false}
                        value={date}
                        inputComponent="input"
                        dateFormat={PickerFormat()}
                        dateWheels={PickerWheels()}
                        buttons={['set', 'cancel']}
                        onChange={ev => PickerUpdate(ev.value)}
                        setText={locale.calendar.u}
                        cancelText={locale.calendar.l}
                    />
                  </Button>
                  <Button id="calendarRange" color="primary" variant="flat" className="mbsc-bold mbsc-txt-muted cs-calendar-button-text" onClick={() => Dialog({view: 'calendarRange'})} >
                    {width > 768 ? ButtonRange() : <FontAwesomeIcon className="cs-calendar-icon-xs" icon={faCalendarRange} />}
                  </Button>
                  <Button id="calendarDisplay" color="primary" variant="flat" className="mbsc-bold mbsc-txt-muted cs-calendar-button-text" onClick={() => Dialog({view: 'calendarDisplay'})} >
                    {width > 768 ? ButtonDisplay() : <FontAwesomeIcon className="cs-calendar-icon-xs" icon={faCalendarLinesPen} />}
                  </Button>
                </div>
              </div>
            </div>
        }
        <div className={width > 576 ? "mbsc-row cs-calendar-section" : "mbsc-row cs-calendar-section-xs"} >
          <div className="mbsc-col cs-calendar-col">
            {!loading && !refresh &&
                <Eventcalendar
                    view={CalendarView()}
                    grouBy={CalendarGroupBy()}
                    eventOverlap={false}
                    dragToCreate={range !== 'month'}
                    dragToMove={range !== 'month'}
                    dragToResize={range !== 'month'}
                    clickToCreate={range === 'month' ? false : 'double'}
                    showControls={false}
                    renderResource={resource => CalendarResource(resource)}
                    renderScheduleEventContent={event => EventContent(event)}
                    renderLabel={event => EventContent(event)}
                    renderEventContent={event => EventContent(event)}
                    selectedDate={date}
                    data={events}
                    resources={resources}
                    onEventCreated={(args, inst) => CalendarCreateInline(args, inst)}
                    onEventDoubleClick={args => display === 'scheduler' ? CalendarOpen(args) : false}
                    onEventClick={args => display === 'agenda' ? CalendarOpen(args) : false}
                    onSelectedDateChange={args => setDate(args.date)}
                    onEventDelete={args => display === 'scheduler' ? CalendarDelete(args) : false}
                    onEventUpdate={args => CalendarUpdate(args)}
                    onPageLoading={args => CalendarEvents(args)}
                />
            }
            {loading &&
                <Loading />
            }
          </div>
        </div>
        {dialog.view === 'sharedNewMenu' &&
            <NewMenu
                locale={locale}
                dialog={dialog}
                Dialog={Dialog}
                setType={setType}
                configuration={configuration}
                anchor={anchor}
                instance={instance}
                type={type}
                visit={visit}
                navigation={navigation}
                Busy={Busy}
            />
        }
        {dialog.view === 'sharedNewCheck' &&
            <NewCheck
                locale={locale}
                dialog={dialog}
                Dialog={Dialog}
                type={type}
                user={user}
                setVisit={setVisit}
                navigation={navigation}
                visit={visit}
                instance={instance}
                anchor={anchor}
                width={width}
            />
        }
        {dialog.view === 'sharedNewEvent' &&
            <NewEvent
                Dialog={Dialog}
                dialog={dialog}
                locale={locale}
                type={type}
                configuration={configuration}
                width={width}
                visit={visit}
                location={location}
                setRefresh={setRefresh}
                instance={instance}
                hours={hours}
                date={date}
                resources={resources}
                range={range}
                anchor={anchor}
                selection={selection}
                navigation={navigation}
            />
        }
        {dialog.view === 'sharedEvent' &&
            <Event
                Dialog={Dialog}
                dialog={dialog}
                locale={locale}
                width={width}
                visit={visit}
                location={location}
                setRefresh={setRefresh}
                anchor={anchor}
                hours={hours}
                date={date}
                resources={resources}
                configuration={configuration}
                navigation={navigation}
            />
        }
        {dialog.view === 'sharedQr' && !Empty(qr) &&
            <QR
                locale={locale}
                dialog={dialog}
                Dialog={Dialog}
                qr={qr}
                width={width}
            />
        }
        {dialog.view === 'calendarRange' &&
            <CalendarRange
                Dialog={Dialog}
                dialog={dialog}
                locale={locale}
                setRange={setRange}
                range={range}
            />
        }
        {dialog.view === 'calendarDisplay' &&
            <CalendarDisplay
                Dialog={Dialog}
                dialog={dialog}
                locale={locale}
                setDisplay={setDisplay}
                display={display}
            />
        }
        {dialog.view === 'calendarSelect' &&
            <CalendarSelect
                Dialog={Dialog}
                dialog={dialog}
                locale={locale}
                setSelection={setSelection}
                range={range}
                location={location}
                date={date}
                setResources={setResources}
                doctors={doctors}
                selection={selection}
            />
        }
      </div>
  );
}

export default Calendar;
