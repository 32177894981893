import React, {useCallback, useState, useContext, useEffect} from 'react';
import {Popup, Input, setOptions, Button} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from "@mobiscroll/react4";
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/dashboardHeaderPatients.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/pro-duotone-svg-icons";
import Empty from "../utils/Empty";
import Loading from "../utils/Loading";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardHeaderPatients({locale, dialog, Dialog, setVisit, setPatient, width}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [search, setSearch] = useState('');
    const [patients, setPatients] = useState([]);
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(true);

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const Autocomplete = () => {
        const message = {
            type: 'wss',
            path: 'patients-autocomplete',
            action: 'get',
            data: {}
        };
        wsCall(message);
    }

    const InputSuggest = data => {
        setSearch(data);

        const item = data;
        let array = patients;

        if (item && array.length !== 0) {
            array = array.filter(itemPatients =>
                itemPatients.text.includes(item.toLowerCase())
            );
        }

        if (!item){
            setResults([]);
        } else {
            setResults(array);
        }
    };

    const ClickPatient = data => {
        setVisit({});
        setPatient({});

        const message = {
            type: 'wss',
            path: 'patient',
            action: 'get',
            data: {
                patient: data.id,
                source: 'dashboardHeaderPatients'
            }
        };
        wsCall(message);
    };

    const ListItem = data => {
        return <li key={data.item.id}>
            <div className="mbsc-row cs-dashboard-header-patients-list" onClick={(ev) => ClickPatient(data.item)}>
                <div>
                    <div>
                        <h4 className="cs-dashboard-header-patients-results-text">
                            {data.item.firstName ? (data.item.firstName + ' ') : null}{data.item.middleName ? (data.item.middleName + ' ') : null}{data.item.lastName ? (data.item.lastName + ' ') : null}{data.item.paternalName ? (data.item.paternalName + ' ') : null}{data.item.maternalName ? (data.item.maternalName + ' ') : null}
                        </h4>
                    </div>
                    <div className="mbsc-txt-muted mbsc-bold">
                        {data.item.id}{data.item.dob ? (' | ' + data.item.dob) : null}{(data.item.email && data.item.type !== 'email') ? (' | ' + data.item.email) : null}{(data.item.mobile && data.item.type !== 'mobile') ? (' | ' + data.item.mobile) : null}
                    </div>
                </div>
            </div>
        </li>;
    }

    const GetPatientsAutocomplete = useCallback(data => {
        if (data.patients) {
            setPatients(data.patients);
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'patients-autocomplete') {
            GetPatientsAutocomplete(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetPatientsAutocomplete, setWsResponse]);

    useEffect(() => {
        Autocomplete();
    }, []);

    return (
        <Popup className="cs-dashboard-header-patients-popup" width={600} maxHeight={900} display="center" scrollLock={false} closeOnOverlayClick={false} closeOnEsc={false} buttons={[]} isOpen={dialog.view === 'dashboardHeaderPatients'} onClose={DialogClose}>
            <div className="mbsc-grid cs-dashboard-header-patients-header-col">
                <div className="mbsc-row cs-dashboard-header-patients-header-row">
                    {width > 576 &&
                        <div className="mbsc-col cs-dashboard-header-patients-header-col" />
                    }
                    <div className={width > 576 ? "mbsc-col cs-dashboard-header-patients-header-col mbsc-align-center" : "mbsc-col cs-dashboard-header-patients-header-col"} >
                        <Button className="cs-dashboard-header-patients-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                            {locale.dashboardHeaderPatients.c}
                        </Button>
                    </div>
                    <div className="mbsc-col cs-dashboard-header-patients-header-col mbsc-align-right">
                        <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-header-patients-close-button" onClick={() => DialogClose()} >
                            &nbsp;<FontAwesomeIcon className="cs-dashboard-header-patients-close-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="cs-dashboard-header-patients-helper">
                <p>{locale.dashboardHeaderPatients.b}</p>
            </div>
            {loading &&
                <div className="cs-dashboard-header-patients-loading" >
                    <Loading />
                </div>
            }
            {!loading &&
                <Input
                    inputStyle="underline"
                    label={locale.dashboardHeaderPatients.a}
                    labelStyle="floating"
                    type="text"
                    name="search"
                    value={search}
                    onChange={ev => InputSuggest(ev.target.value)}
                />
            }
            {search.length !== 0 && results.length === 0 && !loading &&
                <div className="cs-dashboard-header-patients-helper">
                    <p className="mbsc-bold mbsc-txt-muted">{locale.dashboardHeaderPatients.e}</p>
                </div>
            }
            <Listview
                theme="ios"
                themeVariant="light"
                itemType={ListItem}
                data={results}
            />
        </Popup>
    );
}

export default DashboardHeaderPatients;
