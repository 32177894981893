import React, {useCallback, useState, useContext, useEffect} from 'react';
import {Popup, Input, toast, Select, setOptions, Button} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/pro-duotone-svg-icons";
import '../styles/sharedNewCheck.css';
import Empty from "../utils/Empty";
import Mobile from "../utils/Mobile";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function NewCheck({locale, dialog, Dialog, type, user, setVisit, navigation, visit, instance, anchor, width}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [country, setCountry] = useState(() => {
        if (user.locale === 'en') {
            return 'us';
        } else if (user.locale === 'es') {
            return 'mx';
        } else {
            return 'us';
        }
    });

    const DialogClose = useCallback(() => {
        if (navigation.page === 'calendar' && !Empty(instance)) {
            instance.removeEvent(visit.id);
        }
        Dialog({view: 'close'});
    }, [Dialog, instance, navigation.page, visit.id]);

    const ButtonNext = () => {
        if (type === 'email' && !email) {
            toast({message: locale.sharedNewCheck.a, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (type === 'mobile' && !mobile) {
            toast({message: locale.sharedNewCheck.b, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (type === 'whatsapp' && !mobile) {
            toast({message: locale.sharedNewCheck.p, color: 'danger', duration: 3000, display: 'bottom'});
        } else {
            let number = '';
            if (type === 'mobile' || type === 'whatsapp') {
                number = mobile.replace(/[()\s-]/g, '');
            }

            const message = {
                type: 'wss',
                path: 'patient-check',
                action: 'get',
                data: {
                    email: type === 'email' ? email : null,
                    mobile: (type === 'mobile' || type === 'whatsapp') ? number : null,
                    country: (type === 'mobile' || type === 'whatsapp') ? country : null,
                    type: type,
                    page: navigation.page
                }
            };
            wsCall(message);
        }
    }

    const GetPatientCheck = useCallback(data => {
        if (data.patient) {
            const item = {...data.patient, ...visit}
            setVisit(item);
        }
        if (data.patient && navigation.page === 'queue') {
            Dialog({view: 'queueNewVisit'});
        }
        if (data.patient && navigation.page === 'calendar') {
            Dialog({view: 'sharedNewEvent'});
        }
        if (data.error && data.queue) {
            toast({message: locale.sharedNewCheck.m, color: 'danger', display: 'bottom', duration: 3000});
        }
    }, [Dialog, locale.sharedNewCheck.m, navigation.page]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'patient-check') {
            GetPatientCheck(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetPatientCheck, setWsResponse]);

    return (
        <Popup className="cs-shared-new-check-popup" width={600} anchor={anchor ? anchor : null} display={anchor ? "anchored" : "center"} showArrow={false} closeOnOverlayClick={false} closeOnEsc={false} buttons={[]} isOpen={dialog.view === 'sharedNewCheck'} onClose={DialogClose}>
            <div className="mbsc-grid cs-shared-new-check-header-col">
                <div className="mbsc-row cs-shared-new-check-header-row">
                    {width > 576 &&
                        <div className="mbsc-col cs-shared-new-check-header-col" />
                    }
                    <div className={width > 576 ? "mbsc-col cs-shared-new-check-header-col mbsc-align-center" : "mbsc-col cs-shared-new-check-header-col"}>
                        <Button className="cs-shared-new-check-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                            {navigation.page === 'queue' ? locale.sharedNewCheck.c : locale.sharedNewCheck.q}
                        </Button>
                    </div>
                    <div className="mbsc-col cs-shared-new-check-header-col mbsc-align-right">
                        <Button className="cs-shared-new-check-close-button mbsc-bold" onClick={() => DialogClose()} variant="flat" >
                            &nbsp;<FontAwesomeIcon className="cs-shared-new-check-close-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="cs-shared-new-check-helper">
                {type === 'email' &&
                    <p>{locale.sharedNewCheck.f}</p>
                }
                {type === 'mobile' &&
                    <p>{locale.sharedNewCheck.g}</p>
                }
                {type === 'whatsapp' &&
                    <p>{locale.sharedNewCheck.o}</p>
                }
            </div>
            {type === 'email' &&
                <Input inputStyle="underline" label={locale.sharedNewCheck.h} labelStyle="floating" type="email" name="email" value={email} onChange={ev => setEmail(ev.target.value)} />
            }
            {(type === 'mobile' || type === 'whatsapp') &&
                <div className="mbsc-grid cs-shared-new-check-grid">
                    <div className="mbsc-row cs-shared-new-check-row">
                        <div className="mbsc-col-4 cs-shared-new-check-grid">
                            <Select
                                animation="slide-down"
                                dropdown={false}
                                inputStyle="underline"
                                label={locale.sharedNewCheck.j}
                                labelStyle="floating"
                                rows={locale.sharedNewCheck.k.length}
                                display="anchored"
                                touchUi={false}
                                value={country}
                                data={locale.sharedNewCheck.k}
                                disabled={mobile.length !== 0}
                                onChange={args => setCountry(args.value)}
                            />
                        </div>
                        <div className="mbsc-col-8 cs-shared-new-check-grid">
                            <Input className="cs-shared-new-check-inputs-right-input" label={type === 'mobile' ? locale.sharedNewCheck.i : locale.sharedNewCheck.n} inputStyle="underline" labelStyle="floating" type="text" name="mobile" value={mobile} onChange={ev => Mobile({data: ev.target.value, setMobile: setMobile, country: country})} />
                        </div>
                    </div>
                </div>
            }
            <div className="mbsc-button-group-justified cs-shared-new-check-nav-buttons">
                <Button color="primary" variant="flat" className="mbsc-bold cs-shared-new-check-nav-button" onClick={() => ButtonNext()} >
                    {locale.sharedNewCheck.e}
                </Button>
            </div>
        </Popup>
    );
}

export default NewCheck;
