import React, {useCallback, useState, useContext, useEffect} from 'react';
import {Popup, Input, toast, Select, setOptions, Button, confirm} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/patientMedication.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashCan, faXmark} from "@fortawesome/pro-duotone-svg-icons";
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function PatientMedication({locale, dialog, Dialog, setPatient, setMedications, medications, patient, medication, width}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [type, setType] = useState(medication.type ? medication.type : 'active');
    const [text, setText] = useState(medication.text ? medication.text : '');

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const ButtonAdd = () => {
        if (!text) {
            toast({message: locale.patientMedication.f, color: 'danger', display: 'bottom', duration: 3000});
        } else {

            const message = {
                type: 'wss',
                path: 'patient-medication',
                action: 'put',
                data: {
                    patient: patient.id,
                    medications: medications,
                    type: type,
                    text: text
                }
            };
            wsCall(message);
        }
    }

    const ButtonDelete = () => {
        confirm({
            title: locale.patientMedication.j,
            message: locale.patientMedication.k,
            okText: locale.patientMedication.l,
            cancelText: locale.patientMedication.m,
            callback: (res) => {
                if (res) {
                    const message = {
                        type: 'wss',
                        path: 'patient-medication',
                        action: 'delete',
                        data: {
                            patient: patient.id,
                            medications: medications,
                            id: medication.id
                        }
                    };
                    wsCall(message);
                }
            }
        });
    }

    const ButtonHeader = () => {
        if (!Empty(medication) && medication.id) {
            return locale.patientMedication.h;
        } else {
            return locale.patientMedication.b;
        }
    }

    const ButtonUpdate = () => {
        if (!text) {
            toast({message: locale.patientMedication.f, color: 'danger', display: 'bottom', duration: 3000});
        } else {

            const message = {
                type: 'wss',
                path: 'patient-medication',
                action: 'update',
                data: {
                    patient: patient.id,
                    medications: medications,
                    type: type,
                    text: text,
                    id: medication.id
                }
            };
            wsCall(message);
        }
    }

    const PutPatientMedication = useCallback(data => {
        if (data.medications) {
            const item = {...patient};
            item.medications = data.medications;
            setPatient(item);
            setMedications(data.medications);
            setText('');
        }
        DialogClose();
    }, [patient, DialogClose]);

    const UpdatePatientMedication = useCallback(data => {
        if (data.medications) {
            const item = {...patient};
            item.medications = data.medications;
            setPatient(item);
            setMedications(data.medications);
            setText('');
        }
        DialogClose();
    }, [patient, DialogClose]);

    const DeletePatientMedication = useCallback(data => {
        if (data.medications) {
            const item = {...patient};
            item.medications = data.medications;
            setPatient(item);
            setMedications(data.medications);
        }
        DialogClose();
    }, [patient, DialogClose]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'delete' && wsResponse.path === 'patient-medication') {
            DeletePatientMedication(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, DeletePatientMedication, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'patient-medication') {
            UpdatePatientMedication(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdatePatientMedication, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'patient-medication') {
            PutPatientMedication(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PutPatientMedication, setWsResponse]);

    return (
        <Popup className="cs-patient-medication-popup" display="center" width={600} closeOnOverlayClick={false} closeOnEsc={false} buttons={[]} isOpen={dialog.view === 'patientMedication'} onClose={DialogClose}>
            <div className="mbsc-grid cs-patient-medication-header-col">
                <div className="mbsc-row cs-patient-medication-header-row">
                    {width > 576 &&
                        <div className="mbsc-col cs-patient-medication-header-col" />
                    }
                    <div className={width > 576 ? "mbsc-col cs-patient-medication-header-col mbsc-align-center" : "mbsc-col cs-patient-medication-header-col"} >
                        <Button className="cs-patient-medication-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                            {ButtonHeader()}
                        </Button>
                    </div>
                    <div className="mbsc-col cs-patient-medication-header-col mbsc-align-right">
                        {!Empty(medication) && medication.id &&
                            <Button color="primary" variant="flat" className="mbsc-bold cs-patient-medication-delete-button" onClick={() => ButtonDelete()} >
                                &nbsp;<FontAwesomeIcon className="cs-patient-medication-delete-icon" icon={faTrashCan} />
                            </Button>
                        }
                        <Button color="primary" variant="flat" className="mbsc-bold cs-patient-medication-close-button" onClick={() => DialogClose()} >
                            &nbsp;<FontAwesomeIcon className="cs-patient-medication-close-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="mbsc-grid cs-patient-medication-grid">
                <div className="mbsc-row">
                    <div className="mbsc-col-4 cs-patient-medication-inputs-left">
                        <Select
                            animation="slide-down"
                            dropdown={false}
                            inputStyle="underline"
                            label={locale.patientMedication.d}
                            labelStyle="floating"
                            rows={locale.patientMedication.c.length}
                            display="anchored"
                            touchUi={false}
                            value={type}
                            data={locale.patientMedication.c}
                            onChange={args => setType(args.value)}
                        />
                    </div>
                    <div className="mbsc-col-8 cs-patient-medication-inputs-right">
                        <Input className="cs-patient-medication-inputs-right-input" label={locale.patientMedication.e} inputStyle="underline" labelStyle="floating" type="text" name="allergy" value={text} onChange={ev => setText(ev.target.value)} />
                    </div>
                </div>
            </div>
            <div className="mbsc-button-group-justified cs-patient-medication-nav-buttons">
                {!Empty(medication) && medication.id &&
                    <Button color="primary" variant="flat" className="mbsc-bold cs-patient-medication-nav-button" onClick={() => ButtonUpdate()} >
                        {locale.patientMedication.i}
                    </Button>
                }
                {!(!Empty(medication) && medication.id) &&
                    <Button color="primary" variant="flat" className="mbsc-bold cs-patient-medication-nav-button" onClick={() => ButtonAdd()} >
                        {locale.patientMedication.a}
                    </Button>
                }
            </div>
        </Popup>
    );
}

export default PatientMedication;
