import React, {useContext, useEffect, useCallback, useState} from 'react';
import {Button, setOptions, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from "@mobiscroll/react4";
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import {CKEditor} from "@ckeditor/ckeditor5-react";
import BalloonBlock from "@ckeditor/ckeditor5-build-balloon-block";
import '../styles/patientVisits.css';
import {faClipboardList, faCalendarCircleUser} from "@fortawesome/pro-duotone-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Empty from "../utils/Empty";
import Loading from "../utils/Loading";
import PatientPastVisit from "../dialogs/PatientPastVisit";
import Event from "../shared/Event";
import NewEvent from "../shared/NewEvent";
import {NetworkContext} from "../utils/NetworkContext";

dayjs.extend(utc);
setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function PatientVisits({locale, patient, visits, setVisits, summary, setSummary, dialog, Dialog, width, setFile, location, setRefresh, configuration, navigation, refresh}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [visit, setVisit] = useState({});
    const [hours, setHours] = useState({});
    const [resources, setResources] = useState([]);
    const [date, setDate] = useState(dayjs().format());
    const [selection, setSelection] = useState([]);
    const [loading, setLoading] = useState(false);

    const ButtonSummary = () => {
        setLoading(true);

        const message = {
            type: 'wss',
            path: 'visits-summary',
            action: 'get',
            data: {
                user: patient.id
            }
        };
        wsCall(message);
    }

    const ButtonSchedule = () => {
        setVisit({
            user: patient.id,
            type: patient.type,
            firstName: patient.firstName,
            lastName: patient.lastName,
            middleName: patient.middleName,
            paternalName: patient.paternalName,
            maternalName: patient.maternalName,
            dob: patient.dob,
            gender: patient.gender,
            new: false,
            firstVisit: visits.past.length === 0
        });
        Dialog({view: 'sharedNewEvent'});
    }

    const ClickAppointment = data => {
        setVisit(data);
        Dialog({view: 'sharedEvent'});
    }

    const ClickVisit = data => {
        const message = {
            type: 'wss',
            path: 'queue-visit',
            action: 'get',
            data: {
                user: patient.id,
                id: data.id
            }
        };
        wsCall(message);
    }

    const ListItemUpcoming = data => {
        const itemDate = dayjs(data.item.start).format("MMMM D, YYYY @ h:mm A");

        return <li key={data.item.id}>
            <div className="mbsc-row">
                <div className="mbsc-col">
                    <div className="mbsc-row cs-patient-visits-list" onClick={(ev) => ClickAppointment(data.item)}>
                        <FontAwesomeIcon className="cs-patient-visits-list-icon-upcoming" icon={faCalendarCircleUser} />
                        <div className="cs-patient-visits-list-section">
                            <div className="mbsc-bold mbsc-txt-muted">{data.item.reason? data.item.reason : data.item.title}</div>
                            <div className="mbsc-row mbsc-txt-muted mbsc-txt-s cs-patient-visits-item-txt">
                                {locale.patientVisits.c} {itemDate}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mbsc-col-auto cs-patient-visits-list-button" />
            </div>
        </li>;
    }

    const ListItemPast = data => {
        const itemDate = dayjs(parseInt(data.item.id)).format("MMMM D, YYYY @ h:mm A");

        return <li key={data.item.id}>
            <div className="mbsc-row">
                <div className="mbsc-col">
                    <div className="mbsc-row cs-patient-visits-list" onClick={(ev) => ClickVisit(data.item)}>
                        <FontAwesomeIcon className="cs-patient-visits-list-icon-past" icon={faClipboardList} />
                        <div className="cs-patient-visits-list-section">
                            <div className="mbsc-bold mbsc-txt-muted">{data.item.reason}</div>
                            <div className="mbsc-row mbsc-txt-muted mbsc-txt-s cs-patient-visits-item-txt">
                                {locale.patientVisits.c} {itemDate}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mbsc-col-auto cs-patient-visits-list-button" />
            </div>
        </li>;
    }

    const Visits = useCallback(() => {
        setVisits({});

        const message = {
            type: 'wss',
            path: 'visits',
            action: 'get',
            data: {
                user: patient.id,
                date: dayjs().format(),
                location: location
            }
        };
        wsCall(message);
    }, [location, patient.id, wsCall]);

    const GetVisitsSummary = useCallback(data => {
        if (data.summary) {
            setSummary(data.summary);
        }
        if (data.error) {
            toast({message: locale.patientVisits.d, color: 'danger', display: 'bottom', duration: 3000});
        }
        setLoading(false);
    }, [locale.patientVisits.d]);

    const GetVisits = useCallback(data => {
        if (data.visits) {
            setVisits(data.visits);
        }
        if (data.resources) {
            setResources(data.resources);
        }
        if (data.selection) {
            setSelection(data.selection);
        }
        if (data.hours) {
            setHours(data.hours);
        }
    }, []);

    const GetQueueVisit = useCallback(data => {
        if (data.visit) {
            setVisit(data.visit);
            let tab = '';
            if (data.visit.soap.assessment) {
                tab = 'assessment';
            } else if (data.visit.soap.plan) {
                tab = 'plan';
            } else if (data.visit.soap.objective) {
                tab = 'objective';
            } else if (data.visit.soap.subjective) {
                tab = 'subjective';
            } else if (data.visit.soap.instructions) {
                tab = 'instructions';
            } else if (data.visit.triage && data.visit.triage.length !== 0) {
                tab = 'triage';
            } else if (data.visit.files && data.visit.files.length !== 0) {
                tab = 'files';
            }
            if (data.visit.files.length === 0 && data.visit.triage.length === 0 && !data.visit.soap.assessment && !data.visit.soap.plan && !data.visit.soap.objective && !data.visit.soap.subjective && !data.visit.soap.instructions) {
                toast({message: locale.patientVisits.e, color: 'danger', display: 'bottom', duration: 3000});
            } else {
                Dialog({view: 'patientPastVisit', tab: tab});
            }
        }
    }, []);

    const DeleteEvent = useCallback(data => {
        if (data.toast){
            toast({message: locale.patientVisits.h, color: 'info', display: 'bottom', duration: 1000});
        }
        if (dialog.view) {
            Dialog({view: 'close'});
        }
        if (refresh) {
            Visits();
            setRefresh(false);
        }
    }, [locale.patientVisits.h, Dialog, dialog.view, refresh, Visits]);

    const UpdateEvent = useCallback(data => {
        if (data.toast){
            toast({message: locale.patientVisits.i, color: 'info', display: 'bottom', duration: 1000});
        }
        if (dialog.view) {
            Dialog({view: 'close'});
        }
        if (refresh) {
            Visits();
            setRefresh(false);
        }
    }, [locale.patientVisits.i, dialog.view, Dialog, refresh, Visits]);

    const PutEvent = useCallback(data => {
        if (data.toast) {
            toast({message: locale.patientVisits.i, color: 'info', duration: 1000, display: 'bottom'});
        }
        if (dialog.view) {
            Dialog({view: 'close'});
        }
        if (refresh) {
            Visits();
            setRefresh(false);
        }
    }, [locale.patientVisits.i, dialog.view, Dialog, refresh, Visits]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'event') {
            PutEvent(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PutEvent, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'event') {
            UpdateEvent(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateEvent, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'delete' && wsResponse.path === 'event') {
            DeleteEvent(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, DeleteEvent, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'queue-visit') {
            GetQueueVisit(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetQueueVisit, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'visits-summary') {
            GetVisitsSummary(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetVisitsSummary, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'visits') {
            GetVisits(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetVisits, setWsResponse]);

    useEffect(() => {
        Visits();
    }, []);

    return (
        <div className="cs-patient-visits-page">
            <div className="mbsc-row cs-patient-visits-section cs-patient-visits-title">
                <div className="mbsc-col" >
                    <div className="cs-patient-visits-title-text">
                        <p className="mbsc-txt-muted mbsc-bold">{locale.patientVisits.f}</p>
                    </div>
                </div>
                <div className="mbsc-col-auto" >
                    <div className="mbsc-row" >
                        <Button id="sharedNewMenu" color="primary" variant="flat" className="cs-patient-visits-button mbsc-bold" onClick={() => ButtonSchedule()} >
                            {locale.patientVisits.g}
                        </Button>
                    </div>
                </div>
            </div>
            {loading &&
                <div className="cs-patient-visits-loading" >
                    <Loading />
                </div>
            }
            {!Empty(visits) && visits.upcoming.length !== 0 &&
                <Listview
                    theme="ios"
                    themeVariant="light"
                    select="single"
                    itemType={ListItemUpcoming}
                    data={visits.upcoming}
                />
            }
            <div className="mbsc-row cs-patient-visits-section cs-patient-visits-title">
                <div className="mbsc-col" >
                    <div className="cs-patient-visits-title-text">
                        <p className="mbsc-txt-muted mbsc-bold">{locale.patientVisits.a}</p>
                    </div>
                </div>
                <div className="mbsc-col-auto" >
                    <div className="mbsc-row" >
                        <Button disabled={!Empty(visits) && visits.past.length === 0} color="primary" variant="flat" className="cs-patient-visits-button mbsc-bold" onClick={() => ButtonSummary()} >
                            {locale.patientVisits.b}
                        </Button>
                    </div>
                </div>
            </div>
            {summary &&
                <CKEditor
                    id="editor-summary"
                    onReady={editor => {editor.enableReadOnlyMode('editor-summary')}}
                    editor={BalloonBlock}
                    data={summary}
                    disableWatchdog={true}
                />
            }
            {loading &&
                <div className="cs-patient-visits-loading" >
                    <Loading />
                </div>
            }
            {!Empty(visits) && visits.past.length !== 0 &&
                <Listview
                    theme="ios"
                    themeVariant="light"
                    select="single"
                    itemType={ListItemPast}
                    data={visits.past}
                />
            }
            <div className={!Empty(visits) && visits.past.length === 0 ? "mbsc-row cs-patient-visits-footer-empty" : "mbsc-row cs-patient-visits-footer"} />
            {dialog.view === 'patientPastVisit' && !Empty(visit) &&
                <PatientPastVisit
                    locale={locale}
                    dialog={dialog}
                    Dialog={Dialog}
                    visit={visit}
                    setVisit={setVisit}
                    width={width}
                    setFile={setFile}
                />
            }
            {dialog.view === 'sharedEvent' &&
                <Event
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    width={width}
                    visit={visit}
                    location={location}
                    setRefresh={setRefresh}
                    anchor={null}
                    hours={hours}
                    date={date}
                    resources={resources}
                    configuration={configuration}
                    navigation={navigation}
                />
            }
            {dialog.view === 'sharedNewEvent' &&
                <NewEvent
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    type={visit.type}
                    configuration={configuration}
                    width={width}
                    visit={visit}
                    location={location}
                    setRefresh={setRefresh}
                    instance={{}}
                    hours={hours}
                    date={date}
                    resources={resources}
                    range={'day'}
                    anchor={null}
                    selection={selection}
                    navigation={navigation}
                />
            }
        </div>
    );
}

export default PatientVisits;
