import React, {useCallback, useContext, useEffect} from 'react';
import {Popup, setOptions, toast, Select} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/calendarSelect.css';
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
  theme: 'ios',
  themeVariant: 'light',
});

function CalendarSelect({locale, dialog, Dialog, setSelection, range, location, date, setResources, doctors, selection}) {
  const {wsCall} = useContext(NetworkContext);

  const DialogClose = useCallback(() => {
    Dialog({view: 'close'});
  }, [Dialog]);

  const SelectUpdate = data => {
    setSelection(data);
    const array = doctors.filter(user => data.includes(user.id));
    setResources(array);

    const message = {
      type: 'wss',
      path: 'user-ui-calendars',
      action: 'update',
      data: {
        calendars: data,
        range: range,
        location: location,
        date: date
      }
    };
    wsCall(message);
    DialogClose();
  }

  return (
      <Popup className="cs-calendar-select-popup" showArrow={false} scrollLock={false} anchor={document.getElementById('calendarSelect')} display="anchored" buttons={[]} isOpen={dialog.view === 'calendarSelect'} onClose={DialogClose}>
        <Select
            animation="slide-down"
            inputStyle="underline"
            dropdown={false}
            label={locale.calendarSelect.a}
            labelStyle="floating"
            touchUi={false}
            rows={doctors.length}
            display="inline"
            selectMultiple={true}
            value={selection}
            data={doctors}
            onChange={args => SelectUpdate(args.value)}
        />
      </Popup>
  );
}

export default CalendarSelect;
