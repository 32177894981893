import React, {useCallback} from 'react';
import {Button, Popup, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {TabNav, NavItem, Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import Truncate from '../utils/Truncate';
import '../styles/patientPastVisit.css';
import {CKEditor} from "@ckeditor/ckeditor5-react";
import BalloonBlock from "@ckeditor/ckeditor5-build-balloon-block";
import dayjs from "dayjs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileImage, faXmark} from "@fortawesome/pro-duotone-svg-icons";

setOptions({
  theme: 'ios',
  themeVariant: 'light',
});

function PatientPastVisit({locale, dialog, Dialog, visit, setVisit, width, setFile}) {

    const DialogClose = useCallback(() => {
        setVisit({});
        Dialog({view: 'close'});
    }, [Dialog]);

    const ClickFileOpen = data => {
        setFile(data);
        Dialog({view: 'patientVisitFile'});
    }

    const ListItemTriage = data => {

        return <li key={data.item.sequence}>
            <div className="mbsc-row cs-patient-past-visit-list-triage">
                <div className="cs-patient-past-visit-list-section">
                    <div className="mbsc-bold mbsc-txt-muted">{data.item.question}</div>
                    <div className="mbsc-row mbsc-txt-muted cs-patient-past-visit-item-txt-triage">
                        {data.item.type === 'boolean' && data.item.answer === 'true' && locale.patientPastVisit.j}
                        {data.item.type === 'boolean' && data.item.answer === 'false' && locale.patientPastVisit.k}
                        {(data.item.type === 'number' || data.item.type === 'text') && data.item.answer}
                    </div>
                </div>
            </div>
        </li>;
    }

    const ListItemFile = data => {
        const itemDate = dayjs(data.item.timestamp).format("MMMM D, YYYY");

        return <li key={data.item.timestamp}>
            <div className="mbsc-row cs-patient-past-visit-list-file" onClick={(ev) => ClickFileOpen(data.item)}>
                <FontAwesomeIcon className="cs-patient-past-visit-list-icon" icon={faFileImage} />
                <div className="cs-patient-visit-list-section">
                    <div className="mbsc-bold mbsc-txt-muted">{data.item.name}</div>
                    <div className="mbsc-row mbsc-txt-muted mbsc-txt-s cs-patient-past-visit-item-txt-file">
                        {data.item.notes ? locale.patientVisit.z + ': ' + data.item.notes : locale.patientVisit.aa + ': ' + itemDate}
                    </div>
                </div>
            </div>
        </li>;
    }

    return (
        <Popup className="cs-patient-past-visit-popup" width={600} maxHeight={900} display="center" closeOnOverlayClick={false} closeOnEsc={false} scrollLock={false} buttons={[]} isOpen={dialog.view === 'patientPastVisit'} onClose={DialogClose}>
            <div className="mbsc-grid cs-patient-past-visit-header-col">
                <div className="mbsc-row cs-patient-past-visit-header-row">
                    {width > 576 &&
                        <div className="mbsc-col cs-patient-past-visit-header-col" />
                    }
                    <div className={width > 576 ? "mbsc-col cs-patient-past-visit-header-col mbsc-align-center" : "mbsc-col cs-patient-past-visit-header-col"} >
                        <Button className="cs-patient-past-visit-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                            {width < 992 ? Truncate(visit.reason, 15) : Truncate(visit.reason, 30)}
                        </Button>
                    </div>
                    <div className="mbsc-col cs-patient-past-visit-header-col mbsc-align-right">
                        <Button color="primary" variant="flat" className="mbsc-bold cs-patient-past-visit-close-button" onClick={() => DialogClose()} >
                            &nbsp;<FontAwesomeIcon className="cs-patient-past-visit-close-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            <TabNav theme="ios" themeVariant="light" display='inline' >
                {visit.soap.assessment &&
                    <NavItem selected={dialog.tab === 'assessment'} onClick={(ev) => Dialog({tab: 'assessment'})} >
                        <span className={dialog.tab === 'assessment' ? 'mbsc-bold cs-patient-past-visit-nav' : 'mbsc-bold'} >{locale.patientPastVisit.c}</span>
                    </NavItem>
                }
                {visit.soap.plan &&
                    <NavItem selected={dialog.tab === 'plan'} onClick={(ev) => Dialog({tab: 'plan'})} >
                        <span className={dialog.tab === 'plan' ? 'mbsc-bold cs-patient-past-visit-nav' : 'mbsc-bold'} >{locale.patientPastVisit.d}</span>
                    </NavItem>
                }
                {visit.soap.objective &&
                    <NavItem selected={dialog.tab === 'objective'} onClick={(ev) => Dialog({tab: 'objective'})} >
                        <span className={dialog.tab === 'objective' ? 'mbsc-bold cs-patient-past-visit-nav' : 'mbsc-bold'} >{locale.patientPastVisit.e}</span>
                    </NavItem>
                }
                {visit.soap.subjective &&
                    <NavItem selected={dialog.tab === 'subjective'} onClick={(ev) => Dialog({tab: 'subjective'})} >
                        <span className={dialog.tab === 'subjective' ? 'mbsc-bold cs-patient-past-visit-nav' : 'mbsc-bold'} >{locale.patientPastVisit.f}</span>
                    </NavItem>
                }
                {visit.soap.instructions &&
                    <NavItem selected={dialog.tab === 'instructions'} onClick={(ev) => Dialog({tab: 'instructions'})} >
                        <span className={dialog.tab === 'instructions' ? 'mbsc-bold cs-patient-past-visit-nav' : 'mbsc-bold'} >{locale.patientPastVisit.g}</span>
                    </NavItem>
                }
                {visit.triage && visit.triage.length !== 0 &&
                    <NavItem selected={dialog.tab === 'triage'} onClick={(ev) => Dialog({tab: 'triage'})} >
                        <span className={dialog.tab === 'triage' ? 'mbsc-bold cs-patient-past-visit-nav' : 'mbsc-bold'} >{locale.patientPastVisit.h}</span>
                    </NavItem>
                }
                {visit.files && visit.files.length !== 0 &&
                    <NavItem selected={dialog.tab === 'files'} onClick={(ev) => Dialog({tab: 'files'})} >
                        <span className={dialog.tab === 'files' ? 'mbsc-bold cs-patient-past-visit-nav' : 'mbsc-bold'} >{locale.patientPastVisit.i}</span>
                    </NavItem>
                }
            </TabNav>
            {dialog.tab === 'assessment' &&
                <div className="cs-patient-past-visit-section" >
                    <CKEditor
                        id="editor-assessment"
                        onReady={editor => {editor.enableReadOnlyMode('editor-assessment')}}
                        editor={BalloonBlock}
                        data={visit.soap.assessment}
                        disableWatchdog={true}
                    />
                </div>
            }
            {dialog.tab === 'plan' &&
                <div className="cs-patient-past-visit-section" >
                    <CKEditor
                        id="editor-plan"
                        onReady={editor => {editor.enableReadOnlyMode('editor-plan')}}
                        editor={BalloonBlock}
                        data={visit.soap.plan}
                        disableWatchdog={true}
                    />
                </div>
            }
            {dialog.tab === 'objective' &&
                <div className="cs-patient-past-visit-section" >
                    <CKEditor
                        id="editor-objective"
                        onReady={editor => {editor.enableReadOnlyMode('editor-objective')}}
                        editor={BalloonBlock}
                        data={visit.soap.objective}
                        disableWatchdog={true}
                    />
                </div>
            }
            {dialog.tab === 'subjective' &&
                <div className="cs-patient-past-visit-section" >
                    <CKEditor
                        id="editor-subjective"
                        onReady={editor => {editor.enableReadOnlyMode('editor-subjective')}}
                        editor={BalloonBlock}
                        data={visit.soap.subjective}
                        disableWatchdog={true}
                    />
                </div>
            }
            {dialog.tab === 'instructions' &&
                <div className="cs-patient-past-visit-section" >
                    <CKEditor
                        id="editor-instructions"
                        onReady={editor => {editor.enableReadOnlyMode('editor-instructions')}}
                        editor={BalloonBlock}
                        data={visit.soap.instructions}
                        disableWatchdog={true}
                    />
                </div>
            }
            {dialog.tab === 'triage' &&
                <Listview
                    theme="ios"
                    themeVariant="light"
                    select="single"
                    itemType={ListItemTriage}
                    data={visit.triage}
                />
            }
            {dialog.tab === 'files' &&
                <Listview
                    theme="ios"
                    themeVariant="light"
                    select="single"
                    itemType={ListItemFile}
                    data={visit.files}
                />
            }
        </Popup>
    );
}

export default PatientPastVisit;
