import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Button, Input, Popup, setOptions, Textarea, confirm} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/patientVisitFile.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashCan, faXmark} from "@fortawesome/pro-duotone-svg-icons";
import Loading from "../utils/Loading";
import dayjs from "dayjs";
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function PatientVisitFile({locale, dialog, Dialog, file, visit, setVisit, navigation, width}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [notes, setNotes] = useState(file.notes);
    const [link, setLink] = useState('');
    const [loading, setLoading] = useState(true);

    const DialogClose = useCallback(() => {
        if (navigation.tab === 'visits') {
            Dialog({view: 'patientPastVisit'});
        } else {
            Dialog({view: 'close'});
        }
    }, [Dialog, navigation.tab]);

    const Save = () => {
        const updatedFiles = visit.files.map(data =>
            data.timestamp === file.timestamp
                ? { ...data, notes: notes }
                : data
        );

        setVisit(prevVisit => ({
            ...prevVisit,
            files: updatedFiles
        }));

        const message = {
            type: 'wss',
            path: 'visit-image',
            action: 'update',
            data: {
                files: updatedFiles,
                user: visit.user
            }
        };
        wsCall(message);
    }

    const Link = data => {
        const message = {
            type: 'wss',
            path: 'visit-image-link',
            action: 'get',
            data: {
                file: file
            }
        };
        wsCall(message);
    }

    const ButtonClose = () => {
        if (navigation.tab !== 'visits' && notes !== file.notes) {
            Save();
        } else {
            DialogClose();
        }
    }

    const ButtonDelete = () => {
        confirm({
            title: locale.patientVisitFile.d,
            message: locale.patientVisitFile.e,
            okText: locale.patientVisitFile.f,
            cancelText: locale.patientVisitFile.a,
            callback: (res) => {
                if (res) {

                    const message = {
                        type: 'wss',
                        path: 'visit-image',
                        action: 'delete',
                        data: {
                            user: visit.user,
                            files: visit.files,
                            file: file.timestamp
                        }
                    };
                    wsCall(message);
                }
            }
        });
    }

    const GetVisitImagelink = useCallback(data => {
        if (data.link) {
            setLink(data.link);
        }
        setLoading(false);
    }, []);

    const UpdateVisitImage = useCallback(data => {
        if (data.toast) {
            DialogClose();
        }
    }, [DialogClose]);

    const DeleteVisitImage = useCallback(data => {
        if (data.files) {
            const item = {...visit};
            item.files = data.files;
            setVisit(item);
        }
        DialogClose();
    }, [visit, DialogClose]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'delete' && wsResponse.path === 'visit-image') {
            DeleteVisitImage(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, DeleteVisitImage, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'visit-image') {
            UpdateVisitImage(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateVisitImage, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'visit-image-link') {
            GetVisitImagelink(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetVisitImagelink, setWsResponse]);

    useEffect(() => {
        Link();
    }, []);

    return (
        <Popup className="cs-patient-visit-file-popup" width={600} maxHeight={900} display="center" closeOnOverlayClick={false} closeOnEsc={false} scrollLock={false} buttons={[]} isOpen={dialog.view === 'patientVisitFile'} onClose={DialogClose}>
            <div className="mbsc-grid cs-patient-visit-file-header-col">
                <div className="mbsc-row cs-patient-visit-file-header-row">
                    {width > 576 &&
                        <div className="mbsc-col cs-patient-visit-file-header-col" />
                    }
                    <div className={width > 576 ? "mbsc-col cs-patient-visit-file-header-col mbsc-align-center" : "mbsc-col cs-patient-visit-file-header-col"} >
                        <Button className="cs-patient-visit-file-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                            {file.name}
                        </Button>
                    </div>
                    <div className="mbsc-col cs-patient-visit-file-header-col mbsc-align-right">
                        {navigation.tab !== 'visits' &&
                            <Button className="cs-patient-visit-file-delete-button mbsc-bold" onClick={() => ButtonDelete()} variant="flat" >
                                &nbsp;<FontAwesomeIcon className="cs-patient-visit-file-delete-icon" icon={faTrashCan} />
                            </Button>
                        }
                        <Button color="primary" variant="flat" className="mbsc-bold cs-patient-visit-file-close-button" onClick={() => ButtonClose()} >
                            &nbsp;<FontAwesomeIcon className="cs-patient-visit-file-close-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            <Input disabled inputStyle="underline" label={locale.patientVisitFile.c} labelStyle="floating" type="text" name="alias" value={dayjs(file.timestamp).format("MMMM D, YYYY @ h:mm A")} />
            {loading &&
                <div className="cs-patient-visit-file-loading mbsc-align-center" >
                    <Loading />
                </div>
            }
            {!loading &&
                <div className="cs-patient-visit-file-image mbsc-align-center">
                    <img src={link}  alt={link} />
                </div>
            }
            {((navigation.tab === 'visits' && notes) || (navigation.tab !== 'visits')) &&
                <div className="cs-patient-visit-file-textarea">
                    <Textarea disabled={navigation.tab === 'visits'} inputStyle="underline" labelStyle="floating" label={locale.patientVisitFile.b} value={notes} onChange={(ev) => setNotes(ev.target.value)} />
                </div>
            }
        </Popup>
    );
}

export default PatientVisitFile;
