import React, {useState, useContext} from 'react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {CKEditor} from "@ckeditor/ckeditor5-react";
import BalloonBlock from "@ckeditor/ckeditor5-build-balloon-block";
import '../styles/patientFamilyHistory.css';
import {NetworkContext} from "../utils/NetworkContext";

function PatientFamilyHistory({locale, setPatient, patient, setDraft}) {
    const {wsCall} = useContext(NetworkContext);
    const [fh, setFh] = useState(patient.fh);

    const EditorChange = data => {
        setFh(data);

        if (data && data.length % 10 === 0) {
            const item = {...patient};
            item.fh = data;
            setPatient(item);

            const message = {
                type: 'wss',
                path: 'patient',
                action: 'update',
                data: {
                    patient: patient.id,
                    fh: data
                }
            };
            wsCall(message);
        }
    }

    const EditorBlur = () => {
        setDraft(false);

        const item = {...patient};
        item.fh = fh;
        setPatient(item);

        const message = {
            type: 'wss',
            path: 'patient',
            action: 'update',
            data: {
                patient: patient.id,
                fh: fh
            }
        };
        wsCall(message);
    }

    return (
        <div className="cs-patient-family-history-page">
            <div className="mbsc-row cs-patient-family-history-section cs-patient-family-history-title">
                <div className="mbsc-col" >
                    <div className="cs-patient-family-history-title-text">
                        <p className="mbsc-txt-muted mbsc-bold">{locale.patientFamilyHistory.a}</p>
                    </div>
                </div>
                <div className="mbsc-col-auto" />
            </div>
            <CKEditor
                id="editor-focus"
                config={{
                    toolbar: ['bold', 'italic', 'link'],
                    blockToolbar: ['heading', '|', 'bold', 'italic', 'link', 'blockQuote', '|', 'bulletedList', 'numberedList', '|', 'undo', 'redo']
                }}
                editor={BalloonBlock}
                data={fh}
                onChange={(event, editor) => EditorChange(editor.getData())}
                onBlur={() => fh !== patient.fh ? EditorBlur() : null}
            />
            <div className="mbsc-row cs-patient-family-history-footer" />
        </div>
    );
}

export default PatientFamilyHistory;
