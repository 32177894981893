import React, {useCallback, useContext, useEffect, useRef} from 'react';
import {Popup, Button, setOptions, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/calendarRange.css';
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
  theme: 'ios',
  themeVariant: 'light',
});

function CalendarRange({locale, dialog, Dialog, setRange, range}) {
  const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
  const menu = useRef(locale.calendarRange.a);

  const DialogClose = useCallback(() => {
    Dialog({view: 'close'});
  }, [Dialog]);

  const ButtonSelect = data => {
    setRange(data);

    const message = {
      type: 'wss',
      path: 'user-ui-calendar-range',
      action: 'update',
      data: {
        range: data
      }
    };
    wsCall(message);
  }

  const ListItem = data => {
    let item = '';

    if (data.item.week) {
      item = <li key={data.item.timestamp} className="cs-calendar-range-li">
        <Button disabled={range === 'week'} color="primary" variant="flat" className="cs-calendar-range-button mbsc-bold" onClick={() => ButtonSelect('week')} >
          {locale.calendarRange.b}
        </Button>
      </li>;
    } else if (data.item.month) {
      item = <li key={data.item.timestamp} className="cs-calendar-range-li">
        <Button disabled={range === 'month'} color="primary" variant="flat" className="cs-calendar-range-button mbsc-bold" onClick={() => ButtonSelect('month')} >
          {locale.calendarRange.c}
        </Button>
      </li>;
    } else if (data.item.day) {
      item = <li key={data.item.timestamp} className="cs-calendar-range-li">
        <Button disabled={range === 'day'} color="primary" variant="flat" className="cs-calendar-range-button mbsc-bold" onClick={() => ButtonSelect('day')} >
          {locale.calendarRange.d}
        </Button>
      </li>;
    }

    return item;
  }

  const UpdateUserUiCalendarRange = useCallback(data => {
    if (data.toast){
      toast({message: locale.calendarRange.e, color: 'info', display: 'bottom', duration: 1000});
    }
    DialogClose();
  }, [locale.calendarRange.e, DialogClose]);

  useEffect(() => {
    if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'user-ui-calendar-range') {
      UpdateUserUiCalendarRange(wsResponse.data);
      setWsResponse({});
    }
  }, [wsResponse, UpdateUserUiCalendarRange, setWsResponse]);

  return (
      <Popup className="cs-calendar-range-popup" showArrow={false} anchor={document.getElementById('calendarRange')} display="anchored" buttons={[]} isOpen={dialog.view === 'calendarRange'} onClose={DialogClose}>
        <Listview
            theme="ios"
            themeVariant="light"
            select="single"
            itemType={ListItem}
            data={menu.current}
        />
      </Popup>
  );
}

export default CalendarRange;
