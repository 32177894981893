import React, {useState, useContext, useEffect, useCallback, useRef} from 'react';
import {Button, Checkbox, confirm, Input, setOptions, Textarea, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from "@mobiscroll/react4";
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import {CKEditor} from "@ckeditor/ckeditor5-react";
import BalloonBlock from "@ckeditor/ckeditor5-build-balloon-block";
import '../styles/patientVisit.css';
import {faArrowUpToLine, faArrowDownFromLine, faQrcode, faBrainCircuit, faFolderArrowUp, faFileImage} from "@fortawesome/pro-duotone-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Empty from "../utils/Empty";
import Loading from "../utils/Loading";
import PatientVisitTriage from "../dialogs/PatientVisitTriage";
import {NetworkContext} from "../utils/NetworkContext";
import dayjs from "dayjs";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function PatientVisit({locale, configuration, Dialog, setVisit, visit, setQr, sections, setSections, width, dialog, setFile}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const hiddenFileInput = useRef(null);
    const [reason, setReason] = useState(visit.reason);
    const [subjective, setSubjective] = useState(visit.soap.subjective);
    const [objective, setObjective] = useState(visit.soap.objective);
    const [assessment, setAssessment] = useState(visit.soap.assessment);
    const [plan, setPlan] = useState(visit.soap.plan);
    const [instructions, setInstructions] = useState(visit.soap.instructions);
    const [bp, setBp] = useState(visit.vitals.bp);
    const [supplementalO2, setSupplementalO2] = useState(visit.vitals.supplementalO2);
    const [bmi, setBmi] = useState(visit.vitals.bmi);
    const [height, setHeight] = useState(visit.vitals.height);
    const [o2Saturation, setO2Saturation] = useState(visit.vitals.o2Saturation);
    const [pulse, setPulse] = useState(visit.vitals.pulse);
    const [rr, setRr] = useState(visit.vitals.rr);
    const [temperature, setTemperature] = useState(visit.vitals.temperature);
    const [weight, setWeight] = useState(visit.vitals.weight);
    const [glucose, setGlucose] = useState(visit.vitals.glucose);
    const [draft, setDraft] = useState(true);
    const [loading, setLoading] = useState('');

    const ButtonFold = data => {
        const item = {...sections};

        if (data === 'reason' && sections.reason) {
            item.reason = false;
        } else if (data === 'reason' && !sections.reason) {
            item.reason = true;
        } else if (data === 'subjective' && sections.subjective) {
            item.subjective = false;
        } else if (data === 'subjective' && !sections.subjective) {
            item.subjective = true;
        } else if (data === 'objective' && sections.objective) {
            item.objective = false;
        } else if (data === 'objective' && !sections.objective) {
            item.objective = true;
        } else if (data === 'assessment' && sections.assessment) {
            item.assessment = false;
        } else if (data === 'assessment' && !sections.assessment) {
            item.assessment = true;
        } else if (data === 'plan' && sections.plan) {
            item.plan = false;
        } else if (data === 'plan' && !sections.plan) {
            item.plan = true;
        } else if (data === 'instructions' && sections.instructions) {
            item.instructions = false;
        } else if (data === 'instructions' && !sections.instructions) {
            item.instructions = true;
        }

        setSections(item);
    };

    const ButtonQR = () => {
        setQr({});

        const message = {
            type: 'wss',
            path: 'shortener',
            action: 'put',
            data: {
                path: 'patientVisitInstructions',
                type: 'qr',
                user: visit.user,
                arrival: visit.timestampArrival,
                title: locale.patientVisit.f
            }
        };
        wsCall(message);
    }

    const ButtonGenerate = data => {
        setLoading(data.section);
        let text = '';

        const soap = {
            subjective: subjective,
            objective: objective,
            assessment: assessment,
            plan: plan,
            instructions: instructions
        }

        const vitals = {
            bp: bp,
            supplementalO2: supplementalO2,
            bmi: bmi,
            height: height,
            o2Saturation: o2Saturation,
            pulse: pulse,
            rr: rr,
            temperature: temperature,
            weight: weight,
            glucose: glucose
        }

        const message = {
            type: 'wss',
            path: 'visit-generate',
            action: 'put',
            data: {
                user: visit.user,
                section: data.section,
                reason: reason,
                soap: soap,
                vitals: vitals
            }
        };

        if (data.section === 'instructions' && data.confirm) {
            text = locale.patientVisit.u;
        } else if (data.section === 'plan' && data.confirm) {
            text = locale.patientVisit.ah;
        } else if (data.section === 'objective' && data.confirm) {
            text = locale.patientVisit.ag;
        } else if (data.section === 'subjective' && data.confirm) {
            text = locale.patientVisit.ae;
        }

        if (data.confirm) {
            confirm({
                title: locale.patientVisit.t,
                message: text,
                okText: locale.patientVisit.v,
                cancelText: locale.patientVisit.w,
                callback: (res) => {
                    if (res) {
                        wsCall(message);
                    } else {
                        setLoading('');
                    }
                }
            });
        } else {
            wsCall(message);
        }
    }

    const InputBlur = data => {
        const item = {...visit};

        const message = {
            type: 'wss',
            path: 'visit',
            action: 'update',
            data: {
                user: visit.user
            }
        };

        if (data.reason) {
            message.data.reason = reason;
            item.reason = reason;
        } else if (data.subjective) {
            message.data.soap = {...item.soap, subjective: subjective};
            item.soap = message.data.soap;
        } else if (data.objective) {
            message.data.soap = {...item.soap, objective: objective};
            item.soap = message.data.soap;
        } else if (data.assessment) {
            message.data.soap = {...item.soap, assessment: assessment};
            item.soap = message.data.soap;
        } else if (data.plan) {
            message.data.soap = {...item.soap, plan: plan};
            item.soap = message.data.soap;
        } else if (data.instructions) {
            message.data.soap = {...item.soap, instructions: instructions};
            item.soap = message.data.soap;
        } else if (data.bp) {
            message.data.vitals = {...item.vitals, bp: bp};
            item.vitals = message.data.vitals;
        } else if (data.bmi) {
            message.data.vitals = {...item.vitals, bmi: bmi};
            item.vitals = message.data.vitals;
        } else if (data.height) {
            message.data.vitals = {...item.vitals, height: height};
            item.vitals = message.data.vitals;
        } else if (data.o2Saturation) {
            message.data.vitals = {...item.vitals, o2Saturation: o2Saturation};
            item.vitals = message.data.vitals;
        } else if (data.pulse) {
            message.data.vitals = {...item.vitals, pulse: pulse};
            item.vitals = message.data.vitals;
        } else if (data.rr) {
            message.data.vitals = {...item.vitals, rr: rr};
            item.vitals = message.data.vitals;
        } else if (data.temperature) {
            message.data.vitals = {...item.vitals, temperature: temperature};
            item.vitals = message.data.vitals;
        } else if (data.weight) {
            message.data.vitals = {...item.vitals, weight: weight};
            item.vitals = message.data.vitals;
        } else if (data.glucose) {
            message.data.vitals = {...item.vitals, glucose: glucose};
            item.vitals = message.data.vitals;
        }

        setVisit(item);
        wsCall(message);
    };

    const InputUpdate = data => {
        if (data.reason) {
            setReason(data.value);
        } else if (data.subjective) {
            setSubjective(data.value);
        } else if (data.objective) {
            setObjective(data.value);
        } else if (data.assessment) {
            setAssessment(data.value);
        } else if (data.plan) {
            setPlan(data.value);
        } else if (data.instructions) {
            setInstructions(data.value);
        } else if (data.supplementalO2) {
            setSupplementalO2(data.value);
        }

        if (data && ((!data.supplementalO2 && data.value && data.value.length % 10 === 0) || data.supplementalO2)) {
            const item = {...visit};

            const message = {
                type: 'wss',
                path: 'visit',
                action: 'update',
                data: {
                    user: visit.user
                }
            };
            if (data.reason) {
                message.data.reason = data.value;
                item.reason = data.value;
            } else if (data.subjective) {
                message.data.soap = {...item.soap, subjective: data.value};
                item.soap = message.data.soap;
            } else if (data.objective) {
                message.data.soap = {...item.soap, objective: data.value};
                item.soap = message.data.soap;
            } else if (data.assessment) {
                message.data.soap = {...item.soap, assessment: data.value};
                item.soap = message.data.soap;
            } else if (data.plan) {
                message.data.soap = {...item.soap, plan: data.value};
                item.soap = message.data.soap;
            } else if (data.instructions) {
                message.data.soap = {...item.soap, instructions: data.value};
                item.soap = message.data.soap;
            } else if (data.supplementalO2) {
                message.data.vitals = {...item.vitals, supplementalO2: data.value};
                item.vitals = message.data.vitals;
            }
            setVisit(item);
            wsCall(message);
        }
    }

    const InputTemperature = data => {
        if (data === '') {
            setTemperature('');
            return;
        }

        const regex = /^(\d+\.?\d*|\.\d*)$/;

        if (regex.test(data)) {

            setTemperature(data);

        }
    };

    const InputPulse = data => {
        if (data === '') {
            setPulse('');
            return;
        }

        const regex = /^\d+$/;

        if (regex.test(data)) {

            setPulse(data);

        }
    };

    const InputRR = data => {
        if (data === '') {
            setRr('');
            return;
        }

        const regex = /^\d+$/;

        if (regex.test(data)) {

            setRr(data);

        }
    };

    const InputBP = data => {
        if (data === '') {
            setBp('');
            return;
        }

        const regex = /^(\d+\/?|\d+\/\d*)$/;

        if (regex.test(data)) {

            setBp(data);

        }
    };

    const InputSaturation = data => {
        if (data === '') {
            setO2Saturation('');
            return;
        }

        const regex = /^(\d+\.?\d*|\.\d*)$/;

        if (regex.test(data)) {

            setO2Saturation(data);

        }
    };

    const InputHeight = data => {
        if (data === '') {
            setHeight('');
            return;
        }

        const regex = /^(\d+\.?\d*|\.\d*)$/;

        if (regex.test(data)) {

            setHeight(data);

        }
    };

    const InputWeight = data => {
        if (data === '') {
            setWeight('');
            return;
        }

        const regex = /^(\d+\.?\d*|\.\d*)$/;

        if (regex.test(data)) {

            setWeight(data);

        }
    };

    const InputBMI = data => {
        const regex = /^(\d+\.?\d*|\.\d*)$/;
        const heightNumber = parseFloat(height);
        const weightNumber = parseFloat(weight);

        if (data && regex.test(data)) {
            setBmi(data);
            return;
        } else if (!data && (heightNumber <= 0 || weightNumber <= 0)) {
            setBmi('');
            return;
        }

        let item;

        if (window.localStorage.locale === 'es') {
            const heightInMeters = heightNumber / 100;
            item = weightNumber / (heightInMeters * heightInMeters);
        } else {
            item = (weightNumber / (heightNumber * heightNumber)) * 703;
        }

        item = Math.round(item * 10) / 10;

        setBmi(item);
    };

    const InputGlucose = data => {
        if (data === '') {
            setGlucose('');
            return;
        }

        const regex = /^(\d+\.?\d*|\.\d*)$/;

        if (regex.test(data)) {

            setGlucose(data);

        }
    };

    const InputFileUpload = data => {
        const itemFile = data.target.files[0];
        const reader = new FileReader();

        reader.addEventListener('load', ()=>{
            const itemData = reader.result;
            const itemName = itemFile.name;
            const itemType = itemFile.type;

            const message = {
                type: 'wss',
                path: 'visit-image',
                action: 'put',
                data: {
                    file: itemData,
                    name: itemName,
                    type: itemType,
                    user: visit.user,
                    files: visit.files
                }
            };
            wsCall(message);
        })

        if (itemFile && itemFile.type.startsWith('image/') ) {
            reader.readAsDataURL(itemFile);
        } else {
            toast({message: locale.patientVisit.y, color: 'danger', display: 'bottom', duration: 3000});
        }
    }

    const ClickFileOpen = data => {
        setFile(data);
        Dialog({view: 'patientVisitFile'});
    }

    const LstItem = data => {
        const itemDate = dayjs(data.item.timestamp).format("MMMM D, YYYY");

        return <li key={data.item.timestamp}>
            <div className="mbsc-row">
                <div className="mbsc-col">
                    <div className="mbsc-row cs-patient-visit-list" onClick={(ev) => ClickFileOpen(data.item)}>
                        <FontAwesomeIcon className="cs-patient-visit-list-icon" icon={faFileImage} />
                        <div className="cs-patient-visit-list-section">
                            <div className="mbsc-bold mbsc-txt-muted">{data.item.name}</div>
                            <div className="mbsc-row mbsc-txt-muted mbsc-txt-s cs-patient-visit-item-txt">
                                {data.item.notes ? locale.patientVisit.z + ': ' + data.item.notes : locale.patientVisit.aa + ': ' + itemDate}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mbsc-col-auto cs-patient-visit-list-button" />
            </div>
        </li>;
    }

    const PutShortener = useCallback(data => {
        if (data.qr) {
            setQr(data.qr);
            Dialog({view: 'sharedQr'});
        }
    }, [Dialog]);

    const UpdateVisit = useCallback(data => {
        if (data.toast && !draft) {
            toast({message: locale.patient.b, color: 'info', duration: 1000, display: 'bottom'});
            setDraft(true);
        }
    }, [locale.patient.b, draft]);

    const PutVisitImage = useCallback(data => {
        if (data.files) {
            const item = {...visit};
            item.files = data.files;
            setVisit(item);
        }
    }, [visit]);

    const PutVisitGenerate = useCallback(data => {
        if (data.soap) {
            const item = {...visit};
            item.soap = data.soap;
            setVisit(item);
        }
        if (data.soap && data.section === 'subjective') {
            setSubjective(data.soap.subjective);
        }
        if (data.soap && data.section === 'objective') {
            setObjective(data.soap.objective);
        }
        if (data.soap && data.section === 'plan') {
            setPlan(data.soap.plan);
        }
        if (data.soap && data.section === 'instructions') {
            setInstructions(data.soap.instructions);
        }
        if (data.error) {
            toast({message: locale.patientVisit.s, color: 'danger', display: 'bottom', duration: 3000});
        }
        setLoading('');
    }, [visit, locale.patientVisit.s]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'visit-generate') {
            PutVisitGenerate(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PutVisitGenerate, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'visit-image') {
            PutVisitImage(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PutVisitImage, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'visit') {
            UpdateVisit(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateVisit, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'shortener') {
            PutShortener(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PutShortener, setWsResponse]);

    return (
        <div className={width > 576 ? "cs-patient-visit-page" : "cs-patient-visit-page-xs"}>
            <div className={sections.reason ? "mbsc-row cs-patient-visit-section" : "mbsc-row cs-patient-visit-section cs-patient-visit-title"} >
                <div className="mbsc-col" >
                    <div className="cs-patient-visit-title-text">
                        <p className="mbsc-txt-muted mbsc-bold">{locale.patientVisit.a}</p>
                    </div>
                </div>
                <div className="mbsc-col-auto" >
                    <div className="mbsc-row" >
                        {configuration.options.ai && configuration.queue.triage && sections.reason &&
                            <Button disabled={visit.triage.length === 0} color="primary" variant="flat" className="cs-patient-visit-button-multi mbsc-bold" onClick={() => Dialog({view: 'patientVisitTriage'})} >
                                {width < 768 && <FontAwesomeIcon className="cs-patient-visit-icon" icon={faBrainCircuit} />}{width > 768 && locale.patientVisit.r}
                            </Button>
                        }
                        <Button color="primary" variant="flat" className="cs-patient-visit-button-multi mbsc-bold" onClick={() => ButtonFold('reason')} >
                            <FontAwesomeIcon className="cs-patient-visit-icon" icon={sections.reason ? faArrowUpToLine : faArrowDownFromLine} />
                        </Button>
                    </div>
                </div>
            </div>
            {sections.reason &&
                <div className="mbsc-row cs-patient-visit-section">
                    <div className="mbsc-col cs-patient-visit-textarea">
                        <Textarea
                            inputStyle="underline"
                            value={reason}
                            onChange={(ev) => InputUpdate({reason: true, value: ev.target.value})}
                            className="cs-patient-visit-textarea-input"
                            onBlur={() => reason !== visit.reason ? InputBlur({reason: true}) : null}
                        />
                    </div>
                </div>
            }
            <div className="mbsc-row cs-patient-visit-section cs-patient-visit-title">
                <div className="mbsc-col" >
                    <div className="cs-patient-visit-title-text">
                        <p className="mbsc-txt-muted mbsc-bold">{locale.patientVisit.b}</p>
                    </div>
                </div>
                <div className="mbsc-col-auto" >
                    <div className="mbsc-row" >
                        {configuration.options.ai && configuration.queue.triage && sections.subjective &&
                            <Button disabled={visit.triage.length === 0} color="primary" variant="flat" className="cs-patient-visit-button-multi mbsc-bold" onClick={() => ButtonGenerate({section: 'subjective', confirm: !!subjective})} >
                                {width < 768 && <FontAwesomeIcon className="cs-patient-visit-icon" icon={faBrainCircuit} />}{width > 768 && locale.patientVisit.af}
                            </Button>
                        }
                        <Button color="primary" variant="flat" className="cs-patient-visit-button-multi mbsc-bold" onClick={() => ButtonFold('subjective')} >
                            <FontAwesomeIcon className="cs-patient-visit-icon" icon={sections.subjective ? faArrowUpToLine : faArrowDownFromLine} />
                        </Button>
                    </div>
                </div>
            </div>
            {sections.subjective && (!loading || loading !== 'subjective') &&
                <CKEditor
                    id="editor-focus"
                    config={{
                        toolbar: ['bold', 'italic', 'link'],
                        blockToolbar: ['heading', '|', 'bold', 'italic', 'link', 'blockQuote', '|', 'bulletedList', 'numberedList', '|', 'undo', 'redo']
                    }}
                    editor={BalloonBlock}
                    data={subjective}
                    onChange={(event, editor) => InputUpdate({subjective: true, value: editor.getData()})}
                    onBlur={() => subjective !== visit.soap.subjective ? InputBlur({subjective: true}) : null}
                />
            }
            {sections.subjective && loading === 'subjective' &&
                <div className="cs-patient-visit-loading mbsc-align-center" >
                    <Loading />
                </div>
            }
            <div className="mbsc-row cs-patient-visit-section cs-patient-visit-title">
                <div className="mbsc-col" >
                    <div className="cs-patient-visit-title-text">
                        <p className="mbsc-txt-muted mbsc-bold">{locale.patientVisit.c}</p>
                    </div>
                </div>
                <div className="mbsc-col-auto" >
                    <div className="mbsc-row" >
                        {configuration.options.ai && sections.objective &&
                            <Button disabled={!subjective || !reason} color="primary" variant="flat" className="cs-patient-visit-button-multi mbsc-bold" onClick={() => ButtonGenerate({section: 'objective', confirm: !!objective})} >
                                {width < 768 && <FontAwesomeIcon className="cs-patient-visit-icon" icon={faBrainCircuit} />}{width > 768 && locale.patientVisit.af}
                            </Button>
                        }
                        {configuration.options.storage && configuration.queue.storage && sections.objective &&
                            <Button color="primary" variant="flat" className="cs-patient-visit-button-multi mbsc-bold" onClick={() => hiddenFileInput.current.click()} >
                                {width < 768 && <FontAwesomeIcon className="cs-patient-visit-icon" icon={faFolderArrowUp} />}{width > 768 && locale.patientVisit.x}
                            </Button>
                        }
                        <Button color="primary" variant="flat" className="cs-patient-visit-button-multi mbsc-bold" onClick={() => ButtonFold('objective')} >
                            <FontAwesomeIcon className="cs-patient-visit-icon" icon={sections.objective ? faArrowUpToLine : faArrowDownFromLine} />
                        </Button>
                    </div>
                </div>
            </div>
            {sections.objective && visit.files.length !== 0 && !loading &&
                <Listview
                    theme="ios"
                    themeVariant="light"
                    select="single"
                    itemType={LstItem}
                    data={visit.files}
                />
            }
            {configuration.queue.temperature && sections.objective && !loading &&
                <Input inputStyle="underline" label={locale.patientVisit.g} labelStyle="floating" type="text" name="temperature" value={temperature} onChange={ev => InputTemperature(ev.target.value)} onBlur={() => temperature !== visit.vitals.temperature ? InputBlur({temperature: true}) : null} />
            }
            {configuration.queue.pulse && sections.objective && !loading &&
                <Input inputStyle="underline" label={locale.patientVisit.h} labelStyle="floating" type="text" name="pulse" value={pulse} onChange={ev => InputPulse(ev.target.value)} onBlur={() => pulse !== visit.vitals.pulse ? InputBlur({pulse: true}) : null} />
            }
            {configuration.queue.rr && sections.objective && !loading &&
                <Input inputStyle="underline" label={locale.patientVisit.i} labelStyle="floating" type="text" name="respiratory rate" value={rr} onChange={ev => InputRR(ev.target.value)} onBlur={() => rr !== visit.vitals.rr ? InputBlur({rr: true}) : null} />
            }
            {configuration.queue.bp && sections.objective && !loading &&
                <Input inputStyle="underline" label={locale.patientVisit.j} labelStyle="floating" type="text" name="bp" value={bp} onChange={ev => InputBP(ev.target.value)} onBlur={() => bp !== visit.vitals.bp ? InputBlur({bp: true}) : null} />
            }
            {configuration.queue.o2Saturation && sections.objective && !loading &&
                <>
                    <Input inputStyle="underline" label={locale.patientVisit.k} labelStyle="floating" type="text" name="o2 saturation" value={o2Saturation} onChange={ev => InputSaturation(ev.target.value)} onBlur={() => o2Saturation !== visit.vitals.o2Saturation ? InputBlur({o2Saturation: true}) : null} />
                    <Checkbox
                        cssClass="mbsc-txt-muted"
                        color="info"
                        position="end"
                        label={locale.patientVisit.p}
                        checked={supplementalO2}
                        onChange={(ev) => InputUpdate({supplementalO2: true, value: ev.target.checked})}
                    />
                </>
            }
            {configuration.queue.height && sections.objective && !loading &&
                <Input inputStyle="underline" label={locale.patientVisit.l} labelStyle="floating" type="text" name="height" value={height} onChange={ev => InputHeight(ev.target.value)} onBlur={() => height !== visit.vitals.height ? InputBlur({height: true}) : null} />
            }
            {configuration.queue.weight && sections.objective && !loading &&
                <Input inputStyle="underline" label={locale.patientVisit.m} labelStyle="floating" type="text" name="weight" value={weight} onChange={ev => InputWeight(ev.target.value)} onBlur={() => weight !== visit.vitals.weight ? InputBlur({weight: true}) : null} />
            }
            {configuration.queue.bmi && sections.objective && !loading &&
                <Input inputStyle="underline" label={locale.patientVisit.n} labelStyle="floating" type="text" name="bmi" value={bmi} onChange={ev => InputBMI(ev.target.value)} onFocus={() => InputBMI(null)} onBlur={() => bmi !== visit.vitals.bmi ? InputBlur({bmi: true}) : null} />
            }
            {configuration.queue.glucose && sections.objective && !loading &&
                <Input inputStyle="underline" label={locale.patientVisit.o} labelStyle="floating" type="text" name="glucose" value={glucose} onChange={ev => InputGlucose(ev.target.value)} onBlur={() => glucose !== visit.vitals.glucose ? InputBlur({glucose: true}) : null} />
            }
            {sections.objective && loading === 'objective' &&
                <div className="cs-patient-visit-loading mbsc-align-center" >
                    <Loading />
                </div>
            }
            {sections.objective && (!loading || loading !== 'objective') &&
                <CKEditor
                    id="editor-focus"
                    config={{
                        toolbar: ['bold', 'italic', 'link'],
                        blockToolbar: ['heading', '|', 'bold', 'italic', 'link', 'blockQuote', '|', 'bulletedList', 'numberedList', '|', 'undo', 'redo']
                    }}
                    editor={BalloonBlock}
                    data={objective}
                    onChange={(event, editor) => InputUpdate({objective: true, value: editor.getData()})}
                    onBlur={() => objective !== visit.soap.objective ? InputBlur({objective: true}) : null}
                />
            }
            <div className="mbsc-row cs-patient-visit-section cs-patient-visit-title">
                <div className="mbsc-col" >
                    <div className="cs-patient-visit-title-text">
                        <p className="mbsc-txt-muted mbsc-bold">{locale.patientVisit.d}</p>
                    </div>
                </div>
                <div className="mbsc-col-auto" >
                    <Button color="primary" variant="flat" className="cs-patient-visit-button mbsc-bold" onClick={() => ButtonFold('assessment')} >
                        <FontAwesomeIcon className="cs-patient-visit-icon" icon={sections.assessment ? faArrowUpToLine : faArrowDownFromLine} />
                    </Button>
                </div>
            </div>
            {sections.assessment &&
                <CKEditor
                    id="editor-focus"
                    config={{
                        toolbar: ['bold', 'italic', 'link'],
                        blockToolbar: ['heading', '|', 'bold', 'italic', 'link', 'blockQuote', '|', 'bulletedList', 'numberedList', '|', 'undo', 'redo']
                    }}
                    editor={BalloonBlock}
                    data={assessment}
                    onChange={(event, editor) => InputUpdate({assessment: true, value: editor.getData()})}
                    onBlur={() => assessment !== visit.soap.assessment ? InputBlur({assessment: true}) : null}
                />
            }
            <div className="mbsc-row cs-patient-visit-section cs-patient-visit-title">
                <div className="mbsc-col" >
                    <div className="cs-patient-visit-title-text">
                        <p className="mbsc-txt-muted mbsc-bold">{locale.patientVisit.e}</p>
                    </div>
                </div>
                <div className="mbsc-col-auto" >
                    <div className="mbsc-row" >
                        {configuration.options.ai && sections.plan &&
                            <Button disabled={!subjective || !reason} color="primary" variant="flat" className="cs-patient-visit-button-multi mbsc-bold" onClick={() => ButtonGenerate({section: 'plan', confirm: !!plan})} >
                                {width < 768 && <FontAwesomeIcon className="cs-patient-visit-icon" icon={faBrainCircuit} />}{width > 768 && locale.patientVisit.af}
                            </Button>
                        }
                        <Button color="primary" variant="flat" className="cs-patient-visit-button-multi mbsc-bold" onClick={() => ButtonFold('plan')} >
                            <FontAwesomeIcon className="cs-patient-visit-icon" icon={sections.plan ? faArrowUpToLine : faArrowDownFromLine} />
                        </Button>
                    </div>
                </div>
            </div>
            {sections.plan && (!loading || loading !== 'plan') &&
                <CKEditor
                    id="editor-focus"
                    config={{
                        toolbar: ['bold', 'italic', 'link'],
                        blockToolbar: ['heading', '|', 'bold', 'italic', 'link', 'blockQuote', '|', 'bulletedList', 'numberedList', '|', 'undo', 'redo']
                    }}
                    editor={BalloonBlock}
                    data={plan}
                    onChange={(event, editor) => InputUpdate({plan: true, value: editor.getData()})}
                    onBlur={() => plan !== visit.soap.plan ? InputBlur({plan: true}) : null}
                />
            }
            {sections.plan && loading === 'plan' &&
                <div className="cs-patient-visit-loading mbsc-align-center" >
                    <Loading />
                </div>
            }
            <div className="mbsc-row cs-patient-visit-section cs-patient-visit-title">
                <div className="mbsc-col" >
                    <div className="cs-patient-visit-title-text">
                        <p className="mbsc-txt-muted mbsc-bold">{locale.patientVisit.f}</p>
                    </div>
                </div>
                <div className="mbsc-col-auto" >
                    <div className="mbsc-row" >
                        {configuration.options.ai && sections.instructions &&
                            <Button disabled={!subjective || !reason} color="primary" variant="flat" className="cs-patient-visit-button-multi mbsc-bold" onClick={() => ButtonGenerate({section: 'instructions', confirm: !!instructions})} >
                                {width < 768 && <FontAwesomeIcon className="cs-patient-visit-icon" icon={faBrainCircuit} />}{width > 768 && locale.patientVisit.af}
                            </Button>
                        }
                        {sections.instructions &&
                            <Button color="primary" variant="flat" className="cs-patient-visit-button-multi mbsc-bold" onClick={() => ButtonQR()} >
                                {width < 768 && <FontAwesomeIcon className="cs-patient-visit-icon" icon={faQrcode} />}{width > 768 && locale.patientVisit.q}
                            </Button>
                        }
                        <Button color="primary" variant="flat" className="cs-patient-visit-button-multi mbsc-bold" onClick={() => ButtonFold('instructions')} >
                            <FontAwesomeIcon className="cs-patient-visit-icon" icon={sections.instructions ? faArrowUpToLine : faArrowDownFromLine} />
                        </Button>
                    </div>
                </div>
            </div>
            {sections.instructions && (!loading || loading !== 'instructions') &&
                <CKEditor
                    id="editor-focus"
                    config={{
                        toolbar: ['bold', 'italic', 'link'],
                        blockToolbar: ['heading', '|', 'bold', 'italic', 'link', 'blockQuote', '|', 'bulletedList', 'numberedList', '|', 'undo', 'redo']
                    }}
                    editor={BalloonBlock}
                    data={instructions}
                    onChange={(event, editor) => InputUpdate({instructions: true, value: editor.getData()})}
                    onBlur={() => instructions !== visit.soap.instructions ? InputBlur({instructions: true}) : null}
                />
            }
            {sections.instructions && loading === 'instructions' &&
                <div className="cs-patient-visit-loading mbsc-align-center" >
                    <Loading />
                </div>
            }
            <div className="mbsc-row cs-patient-visit-footer" />
            {dialog.view === 'patientVisitTriage' &&
                <PatientVisitTriage
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    visit={visit}
                    width={width}
                />
            }
            <div style={{display: 'none'}}>
                <input type="file" ref={hiddenFileInput} onChange={InputFileUpload} />
            </div>
        </div>
    );
}

export default PatientVisit;
