import React, {useCallback, useState, useContext, useEffect} from 'react';
import {Popup, Input, toast, Select, setOptions, Button, confirm} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/patientAllergy.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashCan, faXmark} from "@fortawesome/pro-duotone-svg-icons";
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function PatientAllergy({locale, dialog, Dialog, setPatient, setAllergies, allergies, patient, allergy, width}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [type, setType] = useState(allergy.type ? allergy.type : 'medical');
    const [text, setText] = useState(allergy.text ? allergy.text : '');

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const ButtonDelete = () => {
        confirm({
            title: locale.patientAllergy.j,
            message: locale.patientAllergy.k,
            okText: locale.patientAllergy.l,
            cancelText: locale.patientAllergy.m,
            callback: (res) => {
                if (res) {
                    const message = {
                        type: 'wss',
                        path: 'patient-allergy',
                        action: 'delete',
                        data: {
                            patient: patient.id,
                            allergies: allergies,
                            id: allergy.id
                        }
                    };
                    wsCall(message);
                }
            }
        });
    }

    const ButtonAdd = () => {
        if (!text) {
            toast({message: locale.patientAllergy.f, color: 'danger', display: 'bottom', duration: 3000});
        } else {

            const message = {
                type: 'wss',
                path: 'patient-allergy',
                action: 'put',
                data: {
                    patient: patient.id,
                    allergies: allergies,
                    type: type,
                    text: text
                }
            };
            wsCall(message);
        }
    }

    const ButtonUpdate = () => {
        if (!text) {
            toast({message: locale.patientAllergy.f, color: 'danger', display: 'bottom', duration: 3000});
        } else {

            const message = {
                type: 'wss',
                path: 'patient-allergy',
                action: 'update',
                data: {
                    patient: patient.id,
                    allergies: allergies,
                    type: type,
                    text: text,
                    id: allergy.id
                }
            };
            wsCall(message);
        }
    }

    const ButtonHeader = () => {
        if (!Empty(allergy) && allergy.id) {
            return locale.patientAllergy.h;
        } else {
            return locale.patientAllergy.b;
        }
    }

    const PutPatientAllergy = useCallback(data => {
        if (data.allergies) {
            const item = {...patient};
            item.allergies = data.allergies;
            setPatient(item);
            setAllergies(data.allergies);
            setText('');
        }
        DialogClose();
    }, [patient, DialogClose]);

    const UpdatePatientAllergy = useCallback(data => {
        if (data.allergies) {
            const item = {...patient};
            item.allergies = data.allergies;
            setPatient(item);
            setAllergies(data.allergies);
            setText('');
        }
        DialogClose();
    }, [patient, DialogClose]);

    const DeletePatientAllergy = useCallback(data => {
        if (data.allergies) {
            const item = {...patient};
            item.allergies = data.allergies;
            setPatient(item);
            setAllergies(data.allergies);
        }
        DialogClose();
    }, [patient, DialogClose]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'delete' && wsResponse.path === 'patient-allergy') {
            DeletePatientAllergy(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, DeletePatientAllergy, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'patient-allergy') {
            UpdatePatientAllergy(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdatePatientAllergy, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'patient-allergy') {
            PutPatientAllergy(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PutPatientAllergy, setWsResponse]);

    return (
        <Popup className="cs-patient-allergy-popup" display="center" width={600} closeOnOverlayClick={false} closeOnEsc={false} buttons={[]} isOpen={dialog.view === 'patientAllergy'} onClose={DialogClose}>
            <div className="mbsc-grid cs-patient-allergy-header-col">
                <div className="mbsc-row cs-patient-allergy-header-row">
                    {width > 576 &&
                        <div className="mbsc-col cs-patient-allergy-header-col" />
                    }
                    <div className={width > 576 ? "mbsc-col cs-patient-allergy-header-col mbsc-align-center" : "mbsc-col cs-patient-allergy-header-col"} >
                        <Button className="cs-patient-allergy-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                            {ButtonHeader()}
                        </Button>
                    </div>
                    <div className="mbsc-col cs-patient-allergy-header-col mbsc-align-right">
                        {!Empty(allergy) && allergy.id &&
                            <Button color="primary" variant="flat" className="mbsc-bold cs-patient-allergy-delete-button" onClick={() => ButtonDelete()} >
                                &nbsp;<FontAwesomeIcon className="cs-patient-allergy-delete-icon" icon={faTrashCan} />
                            </Button>
                        }
                        <Button color="primary" variant="flat" className="mbsc-bold cs-patient-allergy-close-button" onClick={() => DialogClose()} >
                            &nbsp;<FontAwesomeIcon className="cs-patient-allergy-close-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="mbsc-grid cs-patient-allergy-grid">
                <div className="mbsc-row">
                    <div className="mbsc-col-4 cs-patient-allergy-inputs-left">
                        <Select
                            animation="slide-down"
                            dropdown={false}
                            inputStyle="underline"
                            label={locale.patientAllergy.d}
                            labelStyle="floating"
                            rows={locale.patientAllergy.c.length}
                            display="anchored"
                            touchUi={false}
                            value={type}
                            data={locale.patientAllergy.c}
                            onChange={args => setType(args.value)}
                        />
                    </div>
                    <div className="mbsc-col-8 cs-patient-allergy-inputs-right">
                        <Input className="cs-patient-allergy-inputs-right-input" label={locale.patientAllergy.e} inputStyle="underline" labelStyle="floating" type="text" name="text" value={text} onChange={ev => setText(ev.target.value)} />
                    </div>
                </div>
            </div>
            <div className="mbsc-button-group-justified cs-patient-allergy-nav-buttons">
                {!Empty(allergy) && allergy.id &&
                    <Button color="primary" variant="flat" className="mbsc-bold cs-patient-allergy-nav-button" onClick={() => ButtonUpdate()} >
                        {locale.patientAllergy.i}
                    </Button>
                }
                {!(!Empty(allergy) && allergy.id) &&
                    <Button color="primary" variant="flat" className="mbsc-bold cs-patient-allergy-nav-button" onClick={() => ButtonAdd()} >
                        {locale.patientAllergy.a}
                    </Button>
                }
            </div>
        </Popup>
    );
}

export default PatientAllergy;
