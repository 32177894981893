import React, {useCallback, useRef} from 'react';
import {Popup, Button, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/sharedNewMenu.css';
import Empty from '../utils/Empty';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAt, faMobileScreenButton, faCirclePhone, faQrcode, faCalendarCircleExclamation} from "@fortawesome/pro-duotone-svg-icons";

setOptions({
  theme: 'ios',
  themeVariant: 'light',
});

function NewMenu({locale, dialog, Dialog, setType, configuration, anchor, instance, type, visit, navigation, Busy}) {
  const menu = useRef(locale.sharedNewMenu.d);

  const DialogClose = useCallback(() => {
    if (!type && !Empty(instance)) {
      instance.removeEvent(visit.id);
    }
    Dialog({view: 'close'});
  }, [Dialog, instance, visit, type]);

  const ButtonSelect = data => {
    setType(data);
    if (data === 'busy' && navigation.page === 'calendar' && visit.id) {
      Busy(data);
      DialogClose();
    } else if (data === 'busy' && navigation.page === 'calendar' && !visit.id) {

      Dialog({view: 'sharedNewEvent'});
    } else if (data === 'qr' && navigation.page === 'calendar' && visit.id) {

      Dialog({view: 'sharedQr'});
    } else if (data === 'qr' && navigation.page === 'calendar') {

      Dialog({view: 'sharedNewEvent'});
    } else {

      Dialog({view: 'sharedNewCheck'});
    }
  }

  const ListItem = data => {
    let item = '';

    if (data.item.email) {
      item = <li key={data.item.timestamp} className="cs-shared-new-menu-li">
        <Button color="primary" variant="flat" className="cs-shared-new-menu-button mbsc-bold" onClick={() => ButtonSelect('mobile')} >
          <FontAwesomeIcon className="cs-shared-new-menu-icon" icon={faMobileScreenButton} /> {locale.sharedNewMenu.a}
        </Button>
      </li>;
    } else if (data.item.mobile) {
      item = <li key={data.item.timestamp} className="cs-shared-new-menu-li">
        <Button color="primary" variant="flat" className="cs-shared-new-menu-button mbsc-bold" onClick={() => ButtonSelect('email')} >
          <FontAwesomeIcon className="cs-shared-new-menu-icon" icon={faAt} /> {locale.sharedNewMenu.b}
        </Button>
      </li>;
    } else if (data.item.whatsapp && configuration.options.whatsapp) {
      item = <li key={data.item.timestamp} className="cs-shared-new-menu-li">
        <Button color="primary" variant="flat" className="cs-shared-new-menu-button mbsc-bold" onClick={() => ButtonSelect('whatsapp')} >
          <FontAwesomeIcon className="cs-shared-new-menu-icon" icon={faCirclePhone} /> {locale.sharedNewMenu.c}
        </Button>
      </li>;
    } else if (data.item.busy && navigation.page === 'calendar') {
      item = <li key={data.item.timestamp} className="cs-shared-new-menu-li">
        <Button color="primary" variant="flat" className="cs-shared-new-menu-button mbsc-bold" onClick={() => ButtonSelect('busy')} >
          <FontAwesomeIcon className="cs-shared-new-menu-icon" icon={faCalendarCircleExclamation} /> {locale.sharedNewMenu.e}
        </Button>
      </li>;
    } else if (data.item.qr && navigation.page === 'calendar' && visit.id) {
      item = <li key={data.item.timestamp} className="cs-shared-new-menu-li">
        <Button color="primary" variant="flat" className="cs-shared-new-menu-button mbsc-bold" onClick={() => ButtonSelect('qr')} >
          <FontAwesomeIcon className="cs-shared-new-menu-icon" icon={faQrcode} /> {locale.sharedNewMenu.f}
        </Button>
      </li>;
    }

    return item;
  }

  return (
      <Popup className="cs-shared-new-menu-popup" anchor={anchor ? anchor : document.getElementById('sharedNewMenu')} display="anchored" showArrow={false} buttons={[]} isOpen={dialog.view === 'sharedNewMenu'} onClose={DialogClose}>
        <Listview
            theme="ios"
            themeVariant="light"
            select="single"
            itemType={ListItem}
            data={menu.current}
        />
      </Popup>
  );
}

export default NewMenu;
