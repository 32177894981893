import React, {useCallback, useState, useEffect, useContext} from 'react';
import {Popup, toast, Input, Textarea, setOptions, Button} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/loginHelp.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/pro-duotone-svg-icons";
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function LoginHelp({locale, account, dialog, Dialog, width}) {
  const {restCall, restResponse, setRestResponse} = useContext(NetworkContext);
  const [text, setText] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  const DialogClose = useCallback(() => {
    Dialog({view: 'close'});
  }, [Dialog]);

  const ButtonHelp = () => {
    if (!text && !email && !name) {
      toast({message: locale.loginHelp.a, color: 'danger', duration: 3000, display: 'bottom'});
    } else {
      toast({message: locale.loginHelp.b, color: 'info', duration: 1000, display: 'bottom'});

      const message = {
        type: 'rest',
        path: 'help',
        action: 'put',
        data: {
          text: text,
          email: email,
          name: name,
          account: account
        }
      };
      restCall(message);
    }
  }

  const PutHelp = useCallback(data => {
    if (data.toast) {
      DialogClose();
      toast({message: locale.loginHelp.c, color: 'info', duration: 1000, display: 'bottom'});
    }
  }, [locale.loginHelp.c, DialogClose]);

  useEffect(() => {
    if (restResponse && !Empty(restResponse) && restResponse.action === 'put' && restResponse.path === 'help') {
      PutHelp(restResponse.data);
      setRestResponse({});
    }
  }, [restResponse, PutHelp, setRestResponse])

  return (
      <Popup className="cs-login-help-popup" width={600} closeOnOverlayClick={false} closeOnEsc={false} display="center" scrollLock={false} buttons={[]} isOpen={dialog.view === 'loginHelp'} onClose={DialogClose}>
          <div className="mbsc-grid cs-login-help-header-col">
              <div className="mbsc-row cs-login-help-header-row">
                  {width > 576 &&
                      <div className="mbsc-col cs-login-help-header-col" />
                  }
                  <div className={width > 576 ? "mbsc-col cs-login-help-header-col mbsc-align-center" : "mbsc-col cs-login-help-header-col"} >
                      <Button className="cs-login-help-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                          {locale.loginHelp.d}
                      </Button>
                  </div>
                  <div className="mbsc-col cs-login-help-header-col mbsc-align-right">
                      <Button color="primary" variant="flat" className="mbsc-bold cs-login-help-close-button" onClick={() => DialogClose()} >
                          &nbsp;<FontAwesomeIcon className="cs-login-help-close-icon" icon={faXmark} />
                      </Button>
                  </div>
              </div>
          </div>
          <div className="cs-login-help-helper" >
          <p>{locale.loginHelp.l}</p>
        </div>
        <div className="cs-login-help-helper" >
          <p>{locale.loginHelp.m}</p>
        </div>
        <div className="cs-login-help-helper" >
          <p>{locale.loginHelp.n}</p>
        </div>
        <Input inputStyle="underline" label={locale.loginHelp.g} labelStyle="floating" type="text" name="name" value={name} onChange={ev => setName(ev.target.value)} />
        <Input inputStyle="underline" label={locale.loginHelp.i} labelStyle="floating" type="email" name="Email" value={email} onChange={ev => setEmail(ev.target.value)} />
        <div className="cs-login-help-textarea">
          <Textarea inputStyle="underline" labelStyle="floating" label={locale.loginHelp.j} value={text} onChange={(ev) => setText(ev.target.value)} />
        </div>
          <div className="mbsc-button-group-justified cs-login-help-nav-buttons">
              <Button color="primary" variant="flat" className="mbsc-bold cs-login-help-nav-button" onClick={() => ButtonHelp()} >
                  {locale.loginHelp.e}
              </Button>
          </div>
      </Popup>
  );
}

export default LoginHelp;
