import React, {useCallback, useState, useEffect, useContext} from 'react';
import {Popup, toast, Input, Textarea, Datepicker, Select, Button, confirm, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview, NavItem, TabNav} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/queueVisit.css';
import Empty from "../utils/Empty";
import User from "../utils/User";
import Loading from "../utils/Loading";
import dayjs from 'dayjs';
import QRCode from "react-qr-code";
import {faBracketsSquare, faRightToBracket, faLeftFromBracket, faDownFromBracket, faTrashCan, faXmark} from "@fortawesome/pro-duotone-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
  theme: 'ios',
  themeVariant: 'light',
});

function QueueVisit({locale, configuration, dialog, Dialog, width, visit, setRefresh, navigation, user, setQr, qr}) {
  const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
  const [firstName, setFirstName] = useState(visit.firstName ? visit.firstName : null);
  const [middleName, setMiddleName] = useState(visit.middleName ? visit.middleName : null);
  const [lastName, setLastName] = useState(visit.lastName ? visit.lastName : null);
  const [paternalName, setPaternalName] = useState(visit.paternalName ? visit.paternalName : null);
  const [maternalName, setMaternalName] = useState(visit.maternalName ? visit.maternalName : null);
  const [dob, setDob] = useState(visit.dob ? visit.dob : null);
  const [gender, setGender] = useState(visit.gender ? visit.gender : 'male');
  const [reason, setReason] = useState(visit.reason ? visit.reason : null);
  const [notes, setNotes] = useState(visit.notes ? visit.notes : null);

  const DialogClose = useCallback(() => {
    Dialog({view: 'close'});
  }, [Dialog]);

  const Update = () => {
    setRefresh(true);
    const update = {};

    if (firstName !== visit.firstName) {
      update.firstName = firstName;
    } else if (middleName !== visit.middleName) {
      update.middleName = middleName;
    } else if (lastName !== visit.lastName) {
      update.lastName = lastName;
    } else if (paternalName !== visit.paternalName) {
      update.paternalName = paternalName;
    } else if (maternalName !== visit.maternalName) {
      update.maternalName = maternalName;
    } else if (dob !== visit.dob) {
      update.dob = dob;
    } else if (gender !== visit.gender) {
      update.gender = gender;
    }

    const message = {
      type: 'wss',
      path: 'visit-patient',
      action: 'update',
      data: {
        reason: reason ? reason : null,
        notes: notes ? notes : null,
        update: update,
        user: visit.user
      }
    };
    wsCall(message);
  }

  const NavTriage = data => {
    setQr({});

    const message = {
      type: 'wss',
      path: 'shortener',
      action: 'put',
      data: {
        path: 'queueVisitTriage',
        type: 'qr',
        user: visit.user,
        arrival: visit.timestampArrival,
        title: locale.queueVisit.aj,
        location: visit.location
      }
    };
    wsCall(message);
  }

  const NavInstructions = data => {
    setQr({});

    const message = {
      type: 'wss',
      path: 'shortener',
      action: 'put',
      data: {
        path: 'patientVisitInstructions',
        type: 'qr',
        user: visit.user,
        arrival: visit.timestampArrival,
        title: locale.queueVisit.al
      }
    };
    wsCall(message);
  }

  const ButtonDelete = () => {
    confirm({
      title: locale.queueVisit.y,
      message: locale.queueVisit.z,
      okText: locale.queueVisit.x,
      cancelText: locale.queueVisit.aa,
      callback: (res) => {
        if (res) {
          setRefresh(true);

          const message = {
            type: 'wss',
            path: 'visit',
            action: 'delete',
            data: {
              user: visit.user,
            }
          };
          wsCall(message);
        }
      }
    });
  }

  const ButtonStatus = data => {
    if (data === 'discharge') {
      confirm({
        title: locale.queueVisit.ac,
        message: locale.queueVisit.ad,
        okText: locale.queueVisit.ae,
        cancelText: locale.queueVisit.aa,
        callback: (res) => {
          if (res) {
            setRefresh(true);

            const message = {
              type: 'wss',
              path: 'visit-status',
              action: 'update',
              data: {
                user: visit.user,
                status: data,
                log: visit.log
              }
            };
            wsCall(message);
          }
        }
      });
    } else if (data === 'transfer' && configuration.locations.length === 2) {
      confirm({
        title: locale.queueVisit.af,
        message: locale.queueVisit.ag,
        okText: locale.queueVisit.ah,
        cancelText: locale.queueVisit.aa,
        callback: (res) => {
          if (res) {
            setRefresh(true);

            const message = {
              type: 'wss',
              path: 'visit-status',
              action: 'update',
              data: {
                user: visit.user,
                status: data,
                log: visit.log,
                locations: configuration.locations
              }
            };
            wsCall(message);
          }
        }
      });
    } else if (data === 'transfer' && configuration.locations.length > 2) {

      Dialog({view: 'queueVisitTransfer'});

    } else {
      setRefresh(true);

      const message = {
        type: 'wss',
        path: 'visit-status',
        action: 'update',
        data: {
          user: visit.user,
          status: data,
          log: visit.log
        }
      };
      wsCall(message);
    }
  }

  const ButtonClose = () => {
    if (firstName !== visit.firstName ||
        middleName !== visit.middleName ||
        lastName !== visit.lastName ||
        paternalName !== visit.paternalName ||
        maternalName !== visit.maternalName ||
        dob !== visit.dob ||
        gender !== visit.gender ||
        reason !== visit.reason ||
        notes !== visit.notes) {
      Update();
    } else {
      DialogClose();
    }
  }

  const ListItem = data => {
    const itemDate = dayjs(parseInt(data.item.timestamp)).format("MMMM D, YYYY @ h:mm A");

    return <li key={data.item.timestamp}>
      <div className="mbsc-row cs-queue-visit-list">
        {data.item.type === 'wait' &&
            <FontAwesomeIcon className="cs-queue-visit-list-icon-mobile" icon={faBracketsSquare} />
        }
        {data.item.type === 'in' &&
            <FontAwesomeIcon className="cs-queue-visit-list-icon-mobile" icon={faRightToBracket} />
        }
        {data.item.type === 'discharge' &&
            <FontAwesomeIcon className="cs-queue-visit-list-icon-mobile" icon={faLeftFromBracket} />
        }
        {data.item.type === 'transfer' &&
            <FontAwesomeIcon className="cs-queue-visit-list-icon-mobile" icon={faDownFromBracket} />
        }
        <div className="cs-queue-visit-list-section">
          <div className="mbsc-bold mbsc-txt-muted">{data.item.user.id} | {data.item.user.firstName && data.item.user.firstName} {data.item.user.middleName && data.item.user.middleName} {data.item.user.lastName && data.item.user.lastName} {data.item.user.paternalName && data.item.user.paternalName} {data.item.user.maternalName && data.item.user.maternalName}</div>
          <div className="mbsc-row mbsc-txt-muted mbsc-txt-s cs-queue-visit-item-txt">
            {itemDate}
          </div>
        </div>
      </div>
    </li>;
  }

  const UpdateVisitStatus = useCallback(data => {
    if (data.toast) {
      toast({message: locale.queueVisit.u, color: 'info', duration: 1000, display: 'bottom'});
    }
    DialogClose();
    setRefresh(false);
  }, [locale.queueVisit.u, DialogClose, dialog.view]);

  const DeleteVisit = useCallback(data => {
    if (data.toast) {
      toast({message: locale.queueVisit.ab, color: 'info', duration: 1000, display: 'bottom'});
    }
    DialogClose();
    setRefresh(false);
  }, [locale.queueVisit.ab, DialogClose, dialog.view]);

  const UpdateVisitPatient = useCallback(data => {
    if (data.toast) {
      toast({message: locale.queueVisit.m, color: 'info', duration: 1000, display: 'bottom'});
    }
    DialogClose();
    setRefresh(false);
  }, [locale.queueVisit.m, DialogClose]);

  useEffect(() => {
    if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'visit-patient') {
      UpdateVisitPatient(wsResponse.data);
      setWsResponse({});
    }
  }, [wsResponse, UpdateVisitPatient, setWsResponse]);

  useEffect(() => {
    if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'delete' && wsResponse.path === 'visit') {
      DeleteVisit(wsResponse.data);
      setWsResponse({});
    }
  }, [wsResponse, DeleteVisit, setWsResponse]);

  useEffect(() => {
    if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'visit-status') {
      UpdateVisitStatus(wsResponse.data);
      setWsResponse({});
    }
  }, [wsResponse, UpdateVisitStatus, setWsResponse]);

  return (
      <Popup className="cs-queue-visit-popup" width={600} display="center" closeOnOverlayClick={false} closeOnEsc={false} scrollLock={false} buttons={[]} isOpen={dialog.view === 'queueVisit'} onClose={DialogClose}>
        <div className="mbsc-grid cs-queue-visit-header-col">
          <div className="mbsc-row cs-queue-visit-header-row">
            {width > 576 &&
                <div className="mbsc-col cs-queue-visit-header-col" />
            }
            <div className={width > 576 ? "mbsc-col cs-queue-visit-header-col mbsc-align-center" : "mbsc-col cs-queue-visit-header-col"}>
              <Button className="cs-queue-visit-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                {User(visit)}
              </Button>
            </div>
            <div className="mbsc-col cs-queue-visit-header-col mbsc-align-right">
              {(configuration.queue.delete || user.options.admin || user.options.owner) &&
                  <Button className="cs-queue-visit-delete-button mbsc-bold" onClick={() => ButtonDelete()} variant="flat" >
                    &nbsp;<FontAwesomeIcon className="cs-queue-visit-delete-icon" icon={faTrashCan} />
                  </Button>
              }
              <Button className="cs-queue-visit-close-button mbsc-bold" onClick={() => ButtonClose()} variant="flat" >
                &nbsp;<FontAwesomeIcon className="cs-queue-visit-close-icon" icon={faXmark} />
              </Button>
            </div>
          </div>
        </div>
        <TabNav theme="ios" themeVariant="light" display='inline' >
          <NavItem selected={dialog.tab === 'visit'} onClick={(ev) => Dialog({tab: 'visit'})} >
            <span className={dialog.tab === 'visit' ? 'mbsc-bold cs-queue-visit-nav' : 'mbsc-bold'} >{locale.queueVisit.o}</span>
          </NavItem>
          {configuration.options.ai && configuration.queue.triage && visit.triage.length === 0 && !visit.soap.instructions &&
              <NavItem selected={dialog.tab === 'triage'} onClick={(ev) => {NavTriage(); Dialog({tab: 'triage'});}} >
                <span className={dialog.tab === 'triage' ? 'mbsc-bold cs-queue-visit-nav' : 'mbsc-bold'} >{locale.queueVisit.ai}</span>
              </NavItem>
          }
          {visit.soap.instructions &&
              <NavItem selected={dialog.tab === 'instructions'} onClick={(ev) => {NavInstructions(); Dialog({tab: 'instructions'});}} >
                <span className={dialog.tab === 'instructions' ? 'mbsc-bold cs-queue-visit-nav' : 'mbsc-bold'} >{locale.queueVisit.ak}</span>
              </NavItem>
          }
          <NavItem selected={dialog.tab === 'details'} onClick={(ev) => Dialog({tab: 'details'})} >
            <span className={dialog.tab === 'details' ? 'mbsc-bold cs-queue-visit-nav' : 'mbsc-bold'} >{locale.queueVisit.s}</span>
          </NavItem>
          <NavItem selected={dialog.tab === 'log'} onClick={(ev) => Dialog({tab: 'log'})} >
            <span className={dialog.tab === 'log' ? 'mbsc-bold cs-queue-visit-nav' : 'mbsc-bold'} >{locale.queueVisit.t}</span>
          </NavItem>
        </TabNav>
        {dialog.tab === 'visit' &&
          <>
            {configuration.patients.longName ?
                <>
                  <Input inputStyle="underline" label={locale.queueVisit.j} labelStyle="floating" type="text" name="firstName" value={firstName} onChange={ev => setFirstName(ev.target.value)} />
                  <Input inputStyle="underline" label={locale.queueVisit.l} labelStyle="floating" type="text" name="middleName" value={middleName} onChange={ev => setMiddleName(ev.target.value)} />
                  <Input inputStyle="underline" label={locale.queueVisit.i} labelStyle="floating" type="text" name="paternalName" value={paternalName} onChange={ev => setPaternalName(ev.target.value)} />
                  <Input inputStyle="underline" label={locale.queueVisit.h} labelStyle="floating" type="text" name="maternalName" value={maternalName} onChange={ev => setMaternalName(ev.target.value)} />
                </>
                :
                <>
                  <Input inputStyle="underline" label={locale.queueVisit.j} labelStyle="floating" type="text" name="firstName" value={firstName} onChange={ev => setFirstName(ev.target.value)} />
                  <Input inputStyle="underline" label={locale.queueVisit.k} labelStyle="floating" type="text" name="lastName" value={lastName} onChange={ev => setLastName(ev.target.value)} />
                </>
            }
            <Datepicker
                controls={['date']}
                touchUi={width > 576 ? false : true}
                inputStyle="underline"
                label={locale.queueVisit.f}
                labelStyle="floating"
                value={dob}
                selectMultiple={false}
                max={dayjs().format("YYYY-M-D")}
                onChange={args => setDob(args.value)}
                buttons={['set', 'cancel']}
                setText={locale.queueVisit.am}
                cancelText={locale.queueVisit.an}
                dateFormat="YYYY-M-D"
                dateWheels="|YYYY | MMM | D|"
            />
            <Select
                animation="slide-down"
                inputStyle="underline"
                label={locale.queueVisit.p}
                labelStyle="floating"
                touchUi={false}
                rows={locale.queueVisit.q.length}
                display="anchored"
                data={locale.queueVisit.q}
                value={gender}
                onChange={args => setGender(args.value)}
            />
            <div className="cs-queue-visit-textarea-reason">
              <Textarea inputStyle="underline" labelStyle="floating" label={locale.queueVisit.e} value={reason} onChange={(ev) => setReason(ev.target.value)} />
            </div>
          </>
        }
        {dialog.tab === 'details' &&
          <>
            <div className="cs-queue-visit-helper">
              <p>{locale.queueVisit.w}</p>
            </div>
            <div className="cs-queue-visit-textarea-notes">
              <Textarea inputStyle="underline" labelStyle="floating" label={locale.queueVisit.v} value={notes} onChange={(ev) => setNotes(ev.target.value)} />
            </div>
          </>
        }
        {dialog.tab === 'log' &&
            <>
              <Listview
                  theme="ios"
                  themeVariant="light"
                  select="single"
                  itemType={ListItem}
                  data={visit.log}
              />
            </>
        }
        {(dialog.tab === 'triage' || dialog.tab === 'instructions') && Empty(qr) &&
            <div className="cs-queue-visit-loading mbsc-align-center" >
              <Loading />
            </div>
        }
        {(dialog.tab === 'triage' || dialog.tab === 'instructions') && !Empty(qr) &&
            <div className="mbsc-row mbsc-justify-content-center cs-queue-visit-qr">
              <QRCode
                  size={256}
                  value={qr.url}
                  viewBox={`0 0 256 256`}
              />
            </div>
        }
        <div className="mbsc-button-group-justified cs-queue-visit-nav-buttons">
          {(navigation.tab === 'wait' || navigation.tab === 'transfer') &&
              <Button className="cs-queue-visit-nav-button mbsc-bold" onClick={() => ButtonStatus('in')} variant="flat" >
                {locale.queueVisit.n}
              </Button>
          }
          {navigation.tab === 'in' &&
              <Button className="cs-queue-visit-nav-button mbsc-bold" onClick={() => ButtonStatus('discharge')} variant="flat" >
                {locale.queueVisit.d}
              </Button>
          }
          {(navigation.tab === 'in' || navigation.tab === 'transfer') &&
              <Button className="cs-queue-visit-nav-button mbsc-bold" onClick={() => ButtonStatus('wait')} variant="flat" >
                {locale.queueVisit.c}
              </Button>
          }
          {(navigation.tab === 'wait' || navigation.tab === 'in') && configuration.locations.length > 1 && configuration.queue.transfer &&
              <Button className="cs-queue-visit-nav-button mbsc-bold" onClick={() => ButtonStatus('transfer')} variant="flat" >
                {locale.queueVisit.r}
              </Button>
          }
        </div>
      </Popup>
  );
}

export default QueueVisit;
