import React, {useCallback, useContext, useState} from 'react';
import {Popup, toast, Input, setOptions, Button} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/loginReset.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/pro-duotone-svg-icons";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function LoginReset({locale, dialog, Dialog, reset, account, email, mobile, username, setEmail, setMobile, setUsername, type, width}) {
    const {restCall} = useContext(NetworkContext);
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');

    const DialogClose = useCallback(() => {
        setPassword('');
        setConfirm('');
        setEmail('');
        setUsername('');
        setMobile('');
        Dialog({view: 'close'});
    }, [Dialog]);

    const ButtonReset = () => {
        if (!password || !confirm) {
            toast({message: locale.loginReset.a, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (password !== confirm) {
            toast({message: locale.loginReset.b, color: 'danger', duration: 3000, display: 'bottom'});
        } else {
            const itemData = {
                password: password,
                reset: reset.current,
                account: account
            }
            if (type === 'email') {
                itemData.email = email;
            } else if (type === 'mobile') {
                itemData.mobile = mobile;
            } else if (type === 'username') {
                itemData.username = username;
            }

            const message = {
                type: 'rest',
                path: 'token',
                action: 'get',
                data: itemData
            };
            restCall(message);
        }
    }

    return (
        <Popup className="cs-login-reset-popup" width={600} display="center" closeOnOverlayClick={false} closeOnEsc={false} buttons={[]} isOpen={dialog.view === 'loginReset'} onClose={DialogClose}>
            <div className="mbsc-grid cs-login-reset-header-col">
                <div className="mbsc-row cs-login-reset-header-row">
                    {width > 576 &&
                        <div className="mbsc-col cs-login-reset-header-col" />
                    }
                    <div className={width > 576 ? "mbsc-col cs-login-reset-header-col mbsc-align-center" : "mbsc-col cs-login-reset-header-col"} >
                        <Button className="cs-login-reset-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                            {locale.loginReset.c}
                        </Button>
                    </div>
                    <div className="mbsc-col cs-login-reset-header-col mbsc-align-right">
                        <Button color="primary" variant="flat" className="mbsc-bold cs-login-reset-close-button" onClick={() => DialogClose()} >
                            &nbsp;<FontAwesomeIcon className="cs-login-reset-close-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="cs-login-reset-helper">
                <p>{locale.loginReset.g}</p>
            </div>
            <div className="cs-login-reset-helper">
                <p>{locale.loginReset.h}</p>
            </div>
            <div className="cs-login-reset-helper">
                <p>{locale.loginReset.i}</p>
            </div>
            <div className="cs-login-reset-helper">
                <p>{locale.loginReset.j}</p>
            </div>
            <Input inputStyle="underline" label={locale.loginReset.d} labelStyle="floating" type="password" name="password" passwordToggle={true} value={password} onChange={ev => setPassword(ev.target.value)} />
            <Input inputStyle="underline" label={locale.loginReset.e} labelStyle="floating" type="password" name="confirm" passwordToggle={true} value={confirm} onChange={ev => setConfirm(ev.target.value)} />
            <div className="mbsc-button-group-justified cs-login-reset-nav-buttons">
                <Button color="primary" variant="flat" className="mbsc-bold cs-login-reset-nav-button" onClick={() => ButtonReset()} >
                    {locale.loginReset.f}
                </Button>
            </div>
        </Popup>
    );
}

export default LoginReset;
