import React, {useState, useContext, useEffect, useCallback} from 'react';
import {Button, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/queueTransfer.css';
import dayjs from "dayjs";
import {
    faAt,
    faMobileScreenButton,
    faArrowUpShortWide,
    faArrowDownWideShort,
    faCirclePhone,
    faPipe
} from "@fortawesome/pro-duotone-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Empty from "../utils/Empty";
import User from "../utils/User";
import Loading from "../utils/Loading";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function QueueTransfer({locale, Dialog, setVisit, location, width}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [queueTransit, setQueueTransit] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sort, setSort] = useState(true);

    const Queue = data => {
        const message = {
            type: 'wss',
            path: 'queue',
            action: 'get',
            data: {
                status: 'transfer',
                location: location
            }
        };
        wsCall(message);
    }

    const ButtonSort = () => {
        if (!sort) {
            const array = queueTransit;
            array.reverse();
            setQueueTransit(array);
            setSort(true);
        } else {
            const array = queueTransit;
            array.reverse();
            setQueueTransit(array);
            setSort(false);
        }
    }

    const ClickOpen = data => {
        setVisit(queueTransit[data]);
        Dialog({view: 'queueVisit', tab: 'visit'});
    }

    const ListItem = data => {
        const itemDate = dayjs(parseInt(data.item.timestampTransfer)).format("MMMM D, YYYY @ h:mm A");

        return <li key={data.item.timestampArrival}>
            <div className="mbsc-row cs-queue-transfer-list" onClick={(ev) => ClickOpen(data.item)}>
                {data.item.type === 'mobile' && width > 576 &&
                    <FontAwesomeIcon className="cs-queue-transfer-list-icon-mobile" icon={faMobileScreenButton} />
                }
                {data.item.type === 'whatsapp' && width > 576 &&
                    <FontAwesomeIcon className="cs-queue-transfer-list-icon-whatsapp" icon={faCirclePhone} />
                }
                {data.item.type === 'email' && width > 576 &&
                    <FontAwesomeIcon className="cs-queue-transfer-list-icon-email" icon={faAt} />
                }
                <div className="cs-queue-transfer-list-section">
                    <div className="mbsc-bold mbsc-txt-muted">{data.item.firstName && data.item.firstName} {data.item.middleName && data.item.middleName} {data.item.lastName && data.item.lastName} {data.item.paternalName && data.item.paternalName} {data.item.maternalName && data.item.maternalName} <FontAwesomeIcon className="cs-queue-transfer-icon-pipe-xs" icon={faPipe} /> {User(data.item)}</div>
                    <div className="mbsc-row mbsc-txt-muted mbsc-txt-s cs-queue-transfer-item-txt">
                        {locale.queueTransfer.f} {itemDate}
                    </div>
                </div>
            </div>
        </li>;
    }

    const GetQueue = useCallback(data => {
        if (data.queue) {
            setQueueTransit(data.queue);
        }
        if (loading) {
            setLoading(false);
        }
    }, [loading]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'queue') {
            GetQueue(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetQueue, setWsResponse]);

    useEffect(() => {
        Queue();
    }, []);

    return (
        <>
            <div className="mbsc-row cs-queue-transfer-menu">
                <div className="mbsc-col" />
                <div className="mbsc-col-auto">
                    <div className="mbsc-row">
                        <Button color="primary" variant="flat" className="mbsc-bold cs-queue-transfer-button-text" onClick={() => ButtonSort()} >
                            <FontAwesomeIcon className="cs-queue-transfer-icon" icon={sort ? faArrowUpShortWide : faArrowDownWideShort} /> {width > 768 && locale.queueTransfer.b}
                        </Button>
                    </div>
                </div>
            </div>
            {loading &&
                <Loading />
            }
            {queueTransit.length === 0 && !loading &&
                <div className="mbsc-row mbsc-justify-content-center cs-queue-transfer-section">
                    <div className="mbsc-padding">
                        <p className="mbsc-bold mbsc-txt-muted">{locale.queueTransfer.a}</p>
                    </div>
                </div>
            }
            {queueTransit.length !== 0 && !loading &&
                <Listview
                    theme="ios"
                    themeVariant="light"
                    select="single"
                    itemType={ListItem}
                    data={queueTransit}
                />
            }
        </>
    );
}

export default QueueTransfer;
