import React, {useCallback, useState, useContext} from 'react';
import {Popup, toast, Input, Textarea, Datepicker, setOptions, Select, Button} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/sharedNewEvent.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/pro-duotone-svg-icons";
import Empty from "../utils/Empty";
import User from "../utils/User";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {NetworkContext} from "../utils/NetworkContext";

dayjs.extend(utc);
setOptions({
  theme: 'ios',
  themeVariant: 'light',
});

function NewEvent({locale, configuration, dialog, Dialog, type, width, visit, location, setRefresh, instance, hours, date, resources, range, anchor, selection, navigation}) {
  const {wsCall} = useContext(NetworkContext);
  const [firstName, setFirstName] = useState(visit.firstName ? visit.firstName : null);
  const [middleName, setMiddleName] = useState(visit.middleName ? visit.middleName : null);
  const [lastName, setLastName] = useState(visit.lastName ? visit.lastName : null);
  const [paternalName, setPaternalName] = useState(visit.paternalName ? visit.paternalName : null);
  const [maternalName, setMaternalName] = useState(visit.maternalName ? visit.maternalName : null);
  const [start, setStart] = useState(visit.start ? visit.start : null);
  const [end, setEnd] = useState(visit.end ? visit.end : null);
  const [dob, setDob] = useState(visit.dob ? visit.dob : null);
  const [gender, setGender] = useState(visit.gender ? visit.gender : 'male');
  const [reason, setReason] = useState(null);
  const [resource, setResource] = useState(visit.resource ? visit.resource : resources[0].value);

  const DialogClose = useCallback(() => {
    if (!Empty(instance)) {
      instance.removeEvent(visit.id);
    }
    Dialog({view: 'close'});
  }, [Dialog, instance, visit.id]);

  const ButtonEvent = () => {
    if (configuration.patients.longName && !firstName && !paternalName && !(type === 'busy' || type === 'qr')) {
      toast({message: locale.sharedNewEvent.a, color: 'danger', duration: 3000, display: 'bottom'});
    } else if (!configuration.patients.longName && !firstName && !lastName && !(type === 'busy' || type === 'qr')) {
      toast({message: locale.sharedNewEvent.b, color: 'danger', duration: 3000, display: 'bottom'});
    } else if (!start || !end) {
      toast({message: locale.sharedNewEvent.g, color: 'danger', duration: 3000, display: 'bottom'});
    } else if (!resource) {
      toast({message: locale.sharedNewEvent.w, color: 'danger', duration: 3000, display: 'bottom'});
    } else {
      setRefresh(true);
      const update = {};

      if (!visit.new && firstName !== visit.firstName) {
        update.firstName = true;
      } else if (!visit.new && middleName !== visit.middleName) {
        update.middleName = true;
      } else if (!visit.new && lastName !== visit.lastName) {
        update.lastName = true;
      } else if (!visit.new && paternalName !== visit.paternalName) {
        update.paternalName = true;
      } else if (!visit.new && maternalName !== visit.maternalName) {
        update.maternalName = true;
      } else if (!visit.new && dob !== visit.dob) {
        update.dob = true;
      } else if (!visit.new && gender !== visit.gender) {
        update.gender = true;
      }

      const message = {
        type: 'wss',
        path: 'event',
        action: 'put',
        data: {
          firstName: firstName ? firstName : null,
          middleName: middleName ? middleName : null,
          lastName: lastName ? lastName : null,
          paternalName: paternalName ? paternalName : null,
          maternalName: maternalName ? maternalName : null,
          dob: dob ? dob : null,
          gender: gender ? gender : null,
          user: visit.user,
          start: dayjs(start).format(),
          end: dayjs(end).format(),
          resource: resource,
          reason: reason ? reason : null,
          type: type,
          location: location,
          new: visit.new ? visit.new : null,
          firstVisit: visit.firstVisit ? visit.firstVisit : null,
          update: update
        }
      };
      wsCall(message);
    }
  }

  const ButtonHeader = () => {
    if (anchor && !(type === 'busy' || type === 'qr')) {
      const itemDiff = dayjs(visit.end);

      return dayjs(visit.start).format("h:mm A") + ' | ' + itemDiff.diff(visit.start, 'minute') + ' ' + locale.sharedNewEvent.t + ' ' + User(visit);
    } else if (anchor && (type === 'busy' || type === 'qr')) {

      return dayjs(visit.start).format("h:mm A") + ' | ' + locale.sharedNewEvent.y;
    } else if (!anchor && !(type === 'busy' || type === 'qr')) {

      return User(visit);
    } else if (!anchor && (type === 'busy' || type === 'qr')) {

      return locale.sharedNewEvent.y;
    }
  }

  return (
      <Popup className="cs-shared-new-event-popup" width={600} maxHeight={900} anchor={anchor ? anchor : null} display={anchor ? "anchored" : "center"} showArrow={false} closeOnOverlayClick={false} closeOnEsc={false} scrollLock={false} buttons={[]} isOpen={dialog.view === 'sharedNewEvent'} onClose={DialogClose}>
        <div className="mbsc-grid cs-shared-new-event-header-col">
          <div className="mbsc-row cs-shared-new-event-header-row">
            {width > 576 &&
                <div className="mbsc-col cs-shared-new-event-header-col" />
            }
            <div className={width > 576 ? "mbsc-col cs-shared-new-event-header-col mbsc-align-center" : "mbsc-col cs-shared-new-event-header-col"} >
              <Button className="cs-shared-new-event-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                {ButtonHeader()}
              </Button>
            </div>
            <div className="mbsc-col cs-shared-new-event-header-col mbsc-align-right">
              <Button color="primary" variant="flat" className="mbsc-bold cs-shared-new-event-close-button" onClick={() => DialogClose()} >
                &nbsp;<FontAwesomeIcon className="cs-shared-new-event-close-icon" icon={faXmark} />
              </Button>
            </div>
          </div>
        </div>
        {visit.new && !(type === 'busy' || type === 'qr' || navigation.page === 'patient') &&
            <div className="cs-shared-new-event-helper">
              <p><span className="mbsc-bold">{locale.sharedNewEvent.m}</span> {locale.sharedNewEvent.u}</p>
            </div>
        }
        {!visit.new && !(type === 'busy' || type === 'qr' || navigation.page === 'patient') &&
            <div className="cs-shared-new-event-helper">
              <p><span className="mbsc-bold">{locale.sharedNewEvent.n}</span> {locale.sharedNewEvent.v}</p>
            </div>
        }
        {!anchor &&
            <div className="mbsc-grid cs-shared-new-event-grid">
              <div className="mbsc-row cs-shared-new-event-row">
                <div className="mbsc-col cs-shared-new-event-grid">
                  <Datepicker
                      controls={['calendar', 'timegrid']}
                      touchUi={width > 576 ? false : true}
                      inputStyle="underline"
                      label={locale.sharedNewEvent.f}
                      labelStyle="floating"
                      value={start}
                      selectMultiple={false}
                      stepMinute={15}
                      minTime={hours.startTime}
                      maxTime={hours.endTime}
                      onChange={(args) => setStart(args.value)}
                      defaultSelection={date}
                      buttons={['set', 'cancel']}
                      setText={locale.sharedNewEvent.d}
                      cancelText={locale.sharedNewEvent.r}
                  />
                </div>
                <div className="mbsc-col cs-shared-new-event-grid">
                  <Datepicker
                      controls={['calendar', 'timegrid']}
                      touchUi={width > 576 ? false : true}
                      inputStyle="underline"
                      label={locale.sharedNewEvent.p}
                      labelStyle="floating"
                      value={end}
                      selectMultiple={false}
                      stepMinute={15}
                      minTime={start ? start : hours.startTime}
                      maxTime={hours.endTime}
                      onChange={(args) => setEnd(args.value)}
                      defaultSelection={start ? start : date}
                      buttons={['set', 'cancel']}
                      setText={locale.sharedNewEvent.d}
                      cancelText={locale.sharedNewEvent.r}
                  />
                </div>
              </div>
            </div>
        }
        {configuration.patients.longName && !(type === 'busy' || type === 'qr' || navigation.page === 'patient') &&
            <>
              <Input inputStyle="underline" label={locale.sharedNewEvent.j} labelStyle="floating" type="text" name="firstName" value={firstName} onChange={ev => setFirstName(ev.target.value)} />
              <Input inputStyle="underline" label={locale.sharedNewEvent.l} labelStyle="floating" type="text" name="middleName" value={middleName} onChange={ev => setMiddleName(ev.target.value)} />
              <Input inputStyle="underline" label={locale.sharedNewEvent.i} labelStyle="floating" type="text" name="paternalName" value={paternalName} onChange={ev => setPaternalName(ev.target.value)} />
              <Input inputStyle="underline" label={locale.sharedNewEvent.h} labelStyle="floating" type="text" name="maternalName" value={maternalName} onChange={ev => setMaternalName(ev.target.value)} />
            </>
        }
        {!configuration.patients.longName && !(type === 'busy' || type === 'qr' || navigation.page === 'patient') &&
            <>
              <Input inputStyle="underline" label={locale.sharedNewEvent.j} labelStyle="floating" type="text" name="firstName" value={firstName} onChange={ev => setFirstName(ev.target.value)} />
              <Input inputStyle="underline" label={locale.sharedNewEvent.k} labelStyle="floating" type="text" name="lastName" value={lastName} onChange={ev => setLastName(ev.target.value)} />
            </>
        }
        {(!anchor && selection.length > 1) &&
            <Select
                animation="slide-down"
                inputStyle="underline"
                dropdown={false}
                label={locale.sharedNewEvent.q}
                labelStyle="floating"
                rows={resources.length}
                display="anchored"
                touchUi={false}
                data={resources}
                value={resource}
                onChange={args => setResource(args.value)}
            />
        }
        {navigation.page !== 'patient' &&
            <Datepicker
                controls={['date']}
                touchUi={width > 576 ? false : true}
                inputStyle="underline"
                label={locale.sharedNewEvent.o}
                labelStyle="floating"
                value={dob}
                selectMultiple={false}
                max={dayjs().format("YYYY-M-D")}
                onChange={args => setDob(args.value)}
                buttons={['set', 'cancel']}
                setText={locale.sharedNewEvent.d}
                cancelText={locale.sharedNewEvent.r}
                dateFormat="YYYY-M-D"
                dateWheels="|YYYY | MMM | D|"
            />
        }
        {navigation.page !== 'patient' &&
            <Select
                animation="slide-down"
                inputStyle="underline"
                label={locale.sharedNewEvent.s}
                labelStyle="floating"
                touchUi={false}
                rows={locale.sharedNewEvent.x.length}
                display="anchored"
                data={locale.sharedNewEvent.x}
                value={gender}
                onChange={args => setGender(args.value)}
            />
        }
        {!(type === 'busy' || type === 'qr') &&
            <div className="cs-shared-new-event-textarea">
              <Textarea inputStyle="underline" labelStyle="floating" label={locale.sharedNewEvent.e} value={reason} onChange={(ev) => setReason(ev.target.value)} />
            </div>
        }
        <div className="mbsc-button-group-justified cs-shared-new-event-nav-buttons">
          <Button color="primary" variant="flat" className="mbsc-bold cs-shared-new-event-nav-button" onClick={() => ButtonEvent()} >
            {locale.sharedNewEvent.c}
          </Button>
        </div>
      </Popup>
  );
}

export default NewEvent;
