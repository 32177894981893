import React, {useState, useContext} from 'react';
import {Button, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from "@mobiscroll/react4";
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import dayjs from "dayjs";
import '../styles/patientAllergies.css';
import {faPanFood, faTrees, faPills} from "@fortawesome/pro-duotone-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PatientAllergy from "../dialogs/PatientAllergy";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function PatientAllergies({locale, setPatient, patient, dialog, Dialog, width}) {
    const [allergies, setAllergies] = useState(patient.allergies);
    const [allergy, setAllergy] = useState({});

    const ButtonCreate = () => {
        setAllergy({});
        Dialog({view: 'patientAllergy'});
    }

    const ClickOpen = data => {
        setAllergy(data);
        Dialog({view: 'patientAllergy'});
    }

    const ListItem = data => {
        const itemDate = dayjs(parseInt(data.item.id)).format("MMMM D, YYYY");

        return <li key={data.item.id}>
            <div className="mbsc-row">
                <div className="mbsc-col">
                    <div className="mbsc-row cs-patient-allergies-list" onClick={(ev) => ClickOpen(data.item)}>
                        {data.item.type === 'medical' && width > 576 &&
                            <FontAwesomeIcon className="cs-patient-allergies-list-icon-medical" icon={faPills} />
                        }
                        {data.item.type === 'environmental' && width > 576 &&
                            <FontAwesomeIcon className="cs-patient-allergies-list-icon-environment" icon={faTrees} />
                        }
                        {data.item.type === 'dietary' && width > 576 &&
                            <FontAwesomeIcon className="cs-patient-allergies-list-icon-food" icon={faPanFood} />
                        }
                        <div className="cs-patient-allergies-list-section">
                            <div className="mbsc-bold mbsc-txt-muted">{data.item.text}</div>
                            <div className="mbsc-row mbsc-txt-muted mbsc-txt-s cs-patient-allergies-item-txt">
                                {itemDate}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mbsc-col-auto cs-patient-allergies-list-button" />
            </div>
        </li>;
    }

    return (
        <div className="cs-patient-allergies-page">
            <div className="mbsc-row cs-patient-allergies-section cs-patient-allergies-title">
                <div className="mbsc-col" >
                    <div className="cs-patient-allergies-title-text">
                        <p className="mbsc-txt-muted mbsc-bold">{locale.patientAllergies.a}</p>
                    </div>
                </div>
                <div className="mbsc-col-auto" >
                    <Button color="primary" variant="flat" className="cs-patient-allergies-button mbsc-bold" onClick={() => ButtonCreate()} >
                        {locale.patientAllergies.b}
                    </Button>
                </div>
            </div>
            {allergies.length !== 0 ?
                <Listview
                    theme="ios"
                    themeVariant="light"
                    select="single"
                    itemType={ListItem}
                    data={allergies}
                />
                :
                <div className="mbsc-row cs-patient-allergies-section cs-patient-allergies-body" />
            }
            <div className={allergies.length === 0 ? "mbsc-row cs-patient-allergies-footer-empty" : "mbsc-row cs-patient-allergies-footer"} />
            {dialog.view === 'patientAllergy' &&
                <PatientAllergy
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    setPatient={setPatient}
                    setAllergies={setAllergies}
                    allergies={allergies}
                    patient={patient}
                    allergy={allergy}
                    width={width}
                />
            }
        </div>
    );
}

export default PatientAllergies;
