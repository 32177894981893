import React, {useCallback, useState, useEffect, useContext} from 'react';
import {Popup, toast, Textarea, Datepicker, setOptions, Select, Button, confirm, Input} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/sharedEvent.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashCan, faXmark} from "@fortawesome/pro-duotone-svg-icons";
import Empty from "../utils/Empty";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {NetworkContext} from "../utils/NetworkContext";

dayjs.extend(utc);
setOptions({
  theme: 'ios',
  themeVariant: 'light',
});

function Event({locale, dialog, Dialog, width, visit, location, setRefresh, anchor, hours, date, resources, configuration, navigation}) {
  const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
  const [start, setStart] = useState(visit.start ? visit.start : null);
  const [end, setEnd] = useState(visit.end ? visit.end : null);
  const [reason, setReason] = useState(visit.reason ? visit.reason : null);
  const [dob, setDob] = useState(visit.dob ? visit.dob : null);
  const [gender, setGender] = useState(visit.gender ? visit.gender : null);
  const [firstName, setFirstName] = useState(visit.firstName ? visit.firstName : null);
  const [middleName, setMiddleName] = useState(visit.middleName ? visit.middleName : null);
  const [lastName, setLastName] = useState(visit.lastName ? visit.lastName : null);
  const [paternalName, setPaternalName] = useState(visit.paternalName ? visit.paternalName : null);
  const [maternalName, setMaternalName] = useState(visit.maternalName ? visit.maternalName : null);
  const [resource, setResource] = useState(visit.resource ? visit.resource : null);

  const DialogClose = useCallback(() => {
    Dialog({view: 'close'});
  }, [Dialog]);

  const Update = () => {
    if (!start || !end) {
      toast({message: locale.sharedEvent.g, color: 'danger', duration: 3000, display: 'bottom'});
    } else if (!resource) {
      toast({message: locale.sharedEvent.i, color: 'danger', duration: 3000, display: 'bottom'});
    } else {
      setRefresh(true);
      const update = {};

      if (firstName !== visit.firstName || middleName !== visit.middleName || lastName !== visit.lastName || paternalName !== visit.paternalName || maternalName !== visit.maternalName) {
        update.firstName = firstName;
        update.middleName = middleName;
        update.lastName = lastName;
        update.paternalName = paternalName;
        update.maternalName = maternalName;
      } else if (dob !== visit.dob) {
        update.dob = dob;
      } else if (gender !== visit.gender) {
        update.gender = gender;
      }

      const message = {
        type: 'wss',
        path: 'event',
        action: 'update',
        data: {
          start: dayjs(start).format(),
          end: dayjs(end).format(),
          resource: resource,
          reason: reason ? reason : null,
          location: location,
          user: visit.user,
          update: update,
          event: visit.id
        }
      };
      wsCall(message);
    }
  }

  const ButtonClose = () => {
    if (start !== visit.start ||
        end !== visit.end ||
        reason !== visit.reason ||
        dob !== visit.dob ||
        gender !== visit.gender ||
        firstName !== visit.firstName ||
        middleName !== visit.middleName ||
        lastName !== visit.lastName ||
        paternalName !== visit.paternalName ||
        maternalName !== visit.maternalName ||
        resource !== visit.resource) {
      Update();
    } else {
      DialogClose();
    }
  }

  const ButtonDelete = () => {

    confirm({
      title: locale.sharedEvent.k,
      message: locale.sharedEvent.l,
      okText: locale.sharedEvent.m,
      cancelText: locale.sharedEvent.n,
      callback: (res) => {
        if (res) {
          setRefresh(true);

          const message = {
            type: 'wss',
            path: 'event',
            action: 'delete',
            data: {
              event: visit.id,
              user: visit.user
            }
          };
          wsCall(message);
        }
      }
    })
  }

  const ButtonCheckin = () => {
    const message = {
      type: 'wss',
      path: 'calendar-queue',
      action: 'put',
      data: {
        user: visit.user,
        reason: reason ? reason : null,
        location: location
      }
    };
    wsCall(message);
  }

  const ButtonPatient = () => {

    const message = {
      type: 'wss',
      path: 'patient',
      action: 'get',
      data: {
        patient: visit.user,
        source: 'sharedEvent'
      }
    };
    wsCall(message);
  }

  const PutCalendarQueue = useCallback(data => {
    if (data.toast) {
      Dialog({view: 'close'});
      toast({message: locale.sharedEvent.d, color: 'info', duration: 1000, display: 'bottom'});
    } else if (data.error) {
      toast({message: locale.sharedEvent.o, color: 'danger', duration: 3000, display: 'bottom'});
    }
  }, [locale.sharedEvent.d, locale.sharedEvent.o]);

  useEffect(() => {
    if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'calendar-queue') {
      PutCalendarQueue(wsResponse.data);
      setWsResponse({});
    }
  }, [wsResponse, PutCalendarQueue, setWsResponse]);

  return (
      <Popup className="cs-shared-event-popup" width={600} anchor={anchor ? anchor : null} display={anchor ? "anchored" : "center"}  showArrow={false} closeOnOverlayClick={false} closeOnEsc={false} scrollLock={false} buttons={[]} isOpen={dialog.view === 'sharedEvent'} onClose={DialogClose}>
        <div className="mbsc-grid cs-shared-event-header-col">
          <div className="mbsc-row cs-shared-event-header-row">
            {width > 576 &&
                <div className="mbsc-col cs-shared-event-header-col" />
            }
            <div className={width > 576 ? "mbsc-col cs-shared-event-header-col mbsc-align-center" : "mbsc-col cs-shared-event-header-col"} >
              <Button className="cs-shared-event-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                {visit.title}
              </Button>
            </div>
            <div className="mbsc-col cs-shared-event-header-col mbsc-align-right">
              <Button color="primary" variant="flat" className="mbsc-bold cs-shared-event-delete-button" onClick={() => ButtonDelete()} >
                &nbsp;<FontAwesomeIcon className="cs-shared-event-delete-icon" icon={faTrashCan} />
              </Button>
              <Button className="cs-shared-event-close-button mbsc-bold" onClick={() => ButtonClose()} variant="flat" >
                &nbsp;<FontAwesomeIcon className="cs-shared-event-close-icon" icon={faXmark} />
              </Button>
            </div>
          </div>
        </div>
        <div className="mbsc-grid cs-shared-event-grid">
          <div className="mbsc-row cs-shared-event-row">
            <div className="mbsc-col cs-shared-event-grid">
              <Datepicker
                  controls={['calendar', 'timegrid']}
                  touchUi={width > 576 ? false : true}
                  inputStyle="underline"
                  label={locale.sharedEvent.f}
                  labelStyle="floating"
                  value={start}
                  selectMultiple={false}
                  stepMinute={15}
                  minTime={hours.startTime}
                  maxTime={hours.endTime}
                  onChange={(args, inst) => setStart(args.value)}
                  defaultSelection={date}
                  buttons={['set', 'cancel']}
                  setText={locale.sharedEvent.a}
                  cancelText={locale.sharedEvent.n}
              />
            </div>
            <div className="mbsc-col cs-shared-event-grid">
              <Datepicker
                  controls={['calendar', 'timegrid']}
                  touchUi={width > 576 ? false : true}
                  inputStyle="underline"
                  label={locale.sharedEvent.h}
                  labelStyle="floating"
                  value={end}
                  selectMultiple={false}
                  stepMinute={15}
                  minTime={start ? start : hours.startTime}
                  maxTime={hours.endTime}
                  onChange={(args, inst) => setEnd(args.value)}
                  defaultSelection={start ? start : date}
                  buttons={['set', 'cancel']}
                  setText={locale.sharedEvent.a}
                  cancelText={locale.sharedEvent.n}
              />
            </div>
          </div>
        </div>
        {configuration.patients.longName && !(visit.type === 'busy' || visit.type === 'qr' || navigation.page === 'patient') &&
            <>
              <Input inputStyle="underline" label={locale.sharedEvent.s} labelStyle="floating" type="text" name="firstName" value={firstName} onChange={ev => setFirstName(ev.target.value)} />
              <Input inputStyle="underline" label={locale.sharedEvent.u} labelStyle="floating" type="text" name="middleName" value={middleName} onChange={ev => setMiddleName(ev.target.value)} />
              <Input inputStyle="underline" label={locale.sharedEvent.r} labelStyle="floating" type="text" name="paternalName" value={paternalName} onChange={ev => setPaternalName(ev.target.value)} />
              <Input inputStyle="underline" label={locale.sharedEvent.q} labelStyle="floating" type="text" name="maternalName" value={maternalName} onChange={ev => setMaternalName(ev.target.value)} />
            </>
        }
        {!configuration.patients.longName && !(visit.type === 'busy' || visit.type === 'qr' || navigation.page === 'patient') &&
            <>
              <Input inputStyle="underline" label={locale.sharedEvent.s} labelStyle="floating" type="text" name="firstName" value={firstName} onChange={ev => setFirstName(ev.target.value)} />
              <Input inputStyle="underline" label={locale.sharedEvent.t} labelStyle="floating" type="text" name="lastName" value={lastName} onChange={ev => setLastName(ev.target.value)} />
            </>
        }
        {navigation.page === 'calendar' && !(visit.type === 'busy' || visit.type === 'qr') &&
            <Datepicker
                controls={['date']}
                touchUi={width > 576 ? false : true}
                inputStyle="underline"
                label={locale.sharedEvent.v}
                labelStyle="floating"
                value={dob}
                selectMultiple={false}
                max={dayjs().format("YYYY-M-D")}
                onChange={args => setDob(args.value)}
                buttons={['set', 'cancel']}
                setText={locale.sharedEvent.a}
                cancelText={locale.sharedEvent.n}
                dateFormat="YYYY-M-D"
                dateWheels="|YYYY | MMM | D|"
            />
        }
        {navigation.page === 'calendar' && !(visit.type === 'busy' || visit.type === 'qr') &&
            <Select
                animation="slide-down"
                inputStyle="underline"
                label={locale.sharedEvent.w}
                labelStyle="floating"
                touchUi={false}
                rows={locale.sharedEvent.x.length}
                display="anchored"
                data={locale.sharedEvent.x}
                value={gender}
                onChange={args => setGender(args.value)}
            />
        }
        {resources.length > 1 &&
            <Select
                animation="slide-down"
                inputStyle="underline"
                dropdown={false}
                label={locale.sharedEvent.b}
                labelStyle="floating"
                rows={resources.length}
                display="anchored"
                touchUi={false}
                data={resources}
                value={resource}
                onChange={args => setResource(args.value)}
            />
        }
        {!(visit.type === 'busy' || visit.type === 'qr') &&
            <div className="cs-shared-event-textarea">
              <Textarea inputStyle="underline" labelStyle="floating" label={locale.sharedEvent.e} value={reason} onChange={(ev) => setReason(ev.target.value)} />
            </div>
        }
        <div className="mbsc-button-group-justified cs-shared-event-nav-buttons">
          {dayjs().format('YYYY-M-D') === dayjs(start).format('YYYY-M-D') && !(visit.type === 'busy' || visit.type === 'qr') &&
              <Button color="primary" variant="flat" className="mbsc-bold cs-shared-event-nav-button" onClick={() => ButtonCheckin()} >
                {locale.sharedEvent.j}
              </Button>
          }
          {!(visit.type === 'busy' || visit.type === 'qr' || navigation.page === 'patient') &&
              <Button color="primary" variant="flat" className="mbsc-bold cs-shared-event-nav-button" onClick={() => ButtonPatient()} >
                {locale.sharedEvent.p}
              </Button>
          }
        </div>
      </Popup>
  );
}

export default Event;
