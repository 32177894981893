import React, {useCallback, useContext, useRef} from 'react';
import {Popup, Button, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import {faWatchFitness} from "@fortawesome/pro-duotone-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import '../styles/patientVitalsMetric.css';
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
  theme: 'ios',
  themeVariant: 'light',
});

function PatientVitalsMetric({locale, dialog, Dialog, setMetric, range, patient, date, setLoading, metric}) {
  const {wsCall} = useContext(NetworkContext);
  const menu = useRef(locale.patientVitalsMetric.a);

  const DialogClose = useCallback(() => {
    Dialog({view: 'close'});
  }, [Dialog]);

  const ButtonSelect = data => {
    setMetric(data);
    setLoading(true);

    const message = {
      type: 'wss',
      path: 'vitals-metric',
      action: 'get',
      data: {
        patient: patient.id,
        metric: data,
        range: range,
        date: date
      }
    };
    wsCall(message);
    DialogClose();
  }

  const ListItem = data => {
    let item = '';

    if (data.item.temperature) {
      item = <li key={data.item.timestamp} className="cs-patient-vitals-metric-li">
        <Button disabled={metric === 'temperature'} color="primary" variant="flat" className="cs-patient-vitals-metric-button mbsc-bold" onClick={() => ButtonSelect('temperature')} >
          {locale.patientVitalsMetric.b}
        </Button>
      </li>;
    } else if (data.item.pulse) {
      item = <li key={data.item.timestamp} className="cs-patient-vitals-metric-li">
        <Button disabled={metric === 'pulse'} color="primary" variant="flat" className="cs-patient-vitals-metric-button mbsc-bold" onClick={() => ButtonSelect('pulse')} >
          {locale.patientVitalsMetric.c}
        </Button>
      </li>;
    } else if (data.item.pulseShared && patient.share && patient.lastSync) {
      item = <li key={data.item.timestamp} className="cs-patient-vitals-metric-li">
        <Button disabled={metric === 'pulseShared'} color="primary" variant="flat" className="cs-patient-vitals-metric-button mbsc-bold" onClick={() => ButtonSelect('pulseShared')} >
          {locale.patientVitalsMetric.c}<FontAwesomeIcon className="cs-patient-vitals-metric-icon" icon={faWatchFitness} />
        </Button>
      </li>;
    } else if (data.item.activityShared && patient.share && patient.lastSync) {
      item = <li key={data.item.timestamp} className="cs-patient-vitals-metric-li">
        <Button disabled={metric === 'activityShared'} color="primary" variant="flat" className="cs-patient-vitals-metric-button mbsc-bold" onClick={() => ButtonSelect('activityShared')} >
          {locale.patientVitalsMetric.k}<FontAwesomeIcon className="cs-patient-vitals-metric-icon" icon={faWatchFitness} />
        </Button>
      </li>;
    } else if (data.item.bpShared && patient.share && patient.lastSync) {
      item = <li key={data.item.timestamp} className="cs-patient-vitals-metric-li">
        <Button disabled={metric === 'bpShared'} color="primary" variant="flat" className="cs-patient-vitals-metric-button mbsc-bold" onClick={() => ButtonSelect('bpShared')} >
          {locale.patientVitalsMetric.e}<FontAwesomeIcon className="cs-patient-vitals-metric-icon" icon={faWatchFitness} />
        </Button>
      </li>;
    } else if (data.item.sleepShared && patient.share && patient.lastSync) {
      item = <li key={data.item.timestamp} className="cs-patient-vitals-metric-li">
        <Button disabled={metric === 'sleepShared'} color="primary" variant="flat" className="cs-patient-vitals-metric-button mbsc-bold" onClick={() => ButtonSelect('sleepShared')} >
          {locale.patientVitalsMetric.l}<FontAwesomeIcon className="cs-patient-vitals-metric-icon" icon={faWatchFitness} />
        </Button>
      </li>;
    } else if (data.item.rr) {
      item = <li key={data.item.timestamp} className="cs-patient-vitals-metric-li">
        <Button disabled={metric === 'rr'} color="primary" variant="flat" className="cs-patient-vitals-metric-button mbsc-bold" onClick={() => ButtonSelect('rr')} >
          {locale.patientVitalsMetric.d}
        </Button>
      </li>;
    } else if (data.item.bp) {
      item = <li key={data.item.timestamp} className="cs-patient-vitals-metric-li">
        <Button disabled={metric === 'bp'} color="primary" variant="flat" className="cs-patient-vitals-metric-button mbsc-bold" onClick={() => ButtonSelect('bp')} >
          {locale.patientVitalsMetric.e}
        </Button>
      </li>;
    } else if (data.item.o2Saturation) {
      item = <li key={data.item.timestamp} className="cs-patient-vitals-metric-li">
        <Button disabled={metric === 'o2Saturation'} color="primary" variant="flat" className="cs-patient-vitals-metric-button mbsc-bold" onClick={() => ButtonSelect('o2Saturation')} >
          {locale.patientVitalsMetric.f}
        </Button>
      </li>;
    } else if (data.item.height) {
      item = <li key={data.item.timestamp} className="cs-patient-vitals-metric-li">
        <Button disabled={metric === 'height'} color="primary" variant="flat" className="cs-patient-vitals-metric-button mbsc-bold" onClick={() => ButtonSelect('height')} >
          {locale.patientVitalsMetric.g}
        </Button>
      </li>;
    } else if (data.item.weight) {
      item = <li key={data.item.timestamp} className="cs-patient-vitals-metric-li">
        <Button disabled={metric === 'weight'} color="primary" variant="flat" className="cs-patient-vitals-metric-button mbsc-bold" onClick={() => ButtonSelect('weight')} >
          {locale.patientVitalsMetric.h}
        </Button>
      </li>;
    } else if (data.item.bmi) {
      item = <li key={data.item.timestamp} className="cs-patient-vitals-metric-li">
        <Button disabled={metric === 'bmi'} color="primary" variant="flat" className="cs-patient-vitals-metric-button mbsc-bold" onClick={() => ButtonSelect('bmi')} >
          {locale.patientVitalsMetric.i}
        </Button>
      </li>;
    } else if (data.item.glucose) {
      item = <li key={data.item.timestamp} className="cs-patient-vitals-metric-li">
        <Button disabled={metric === 'glucose'} color="primary" variant="flat" className="cs-patient-vitals-metric-button mbsc-bold" onClick={() => ButtonSelect('glucose')} >
          {locale.patientVitalsMetric.j}
        </Button>
      </li>;
    }

    return item;
  }

  return (
      <Popup className="cs-patient-vitals-metric-popup" showArrow={false} maxHeight={1200} scrollLock={false} anchor={document.getElementById('patientVitalsMetric')} display="anchored" buttons={[]} isOpen={dialog.view === 'patientVitalsMetric'} onClose={DialogClose}>
        <Listview
            theme="ios"
            themeVariant="light"
            select="single"
            itemType={ListItem}
            data={menu.current}
        />
      </Popup>
  );
}

export default PatientVitalsMetric;
