import React, {useCallback, useState} from 'react';
import {Popup, Select, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/headerLocale.css';
import English from "../locale/english.json";
import Spanish from "../locale/spanish.json";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

const StateLocale = () => {
    if (window.localStorage.locale) {
        return window.localStorage.locale;
    } else {
        window.localStorage.locale = 'en';
        return 'en';
    }
}

function HeaderLocale({dialog, Dialog, configuration, setLocale}) {
    const [language, setLanguage] = useState(StateLocale());

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const SelectLocale = data => {
        if (data === 'en') {
            setLocale(English);
            window.localStorage.locale = 'en';
            setLanguage('en');
        } else if (data === 'es') {
            setLocale(Spanish);
            window.localStorage.locale = 'es';
            setLanguage('es');
        }
        DialogClose();
    }

    return (
        <Popup className="cs-header-locale-popup" display="anchored" anchor={document.getElementById('headerLocale')} buttons={[]} isOpen={dialog.view === 'headerLocale'} onClose={DialogClose}>
            <Select
                rows={2}
                display="inline"
                touchUi={false}
                selectMultiple={false}
                value={language}
                data={configuration.mobi.language}
                onChange={args => SelectLocale(args.value)}
            />
        </Popup>
    );
}

export default HeaderLocale;
