import React, {useCallback, useState, useEffect, useContext} from 'react';
import {RadioGroup, Radio, toast, Select, Input, Button, setOptions, Checkbox, Textarea} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {BottomNav, NavItem} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/microAppQueueWait.css';
import Mobile from "../utils/Mobile";
import Loading from "../utils/Loading";
import {NetworkContext} from '../utils/NetworkContext';
import Empty from "../utils/Empty";
import dayjs from "dayjs";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function QueueWait({locale, microApp, width}) {
    const {restCall, restResponse, setRestResponse} = useContext(NetworkContext);
    const [step, setStep] = useState(0);
    const [triage, setTriage] = useState([]);
    const [stepTriage, setStepTriage] = useState(0);
    const [type, setType] = useState('mobile');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('');
    const [notifications, setNotifications] = useState(true);
    const [country, setCountry] = useState(() => {
        if (window.localStorage.locale === 'en') {
            return 'us';
        } else if (window.localStorage.locale === 'es') {
            return 'mx';
        } else {
            return 'us';
        }
    });
    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');
    const [paternalName, setPaternalName] = useState('');
    const [maternalName, setMaternalName] = useState('');
    const [dob, setDob] = useState('');
    const [month, setMonth] = useState('');
    const [day, setDay] = useState('');
    const [year, setYear] = useState('');
    const [gender, setGender] = useState('male');
    const [auth, setAuth] = useState(false);
    const [putPatient, setPutPatient] = useState(true);
    const [firstVisit, setFirstVisit] = useState(true);
    const [reason, setReason] = useState('');

    const ButtonNext = () => {
        if (step === 3 && microApp.patients.longName && (!firstName || !paternalName)) {
            toast({message: locale.microAppQueueWait.s, color: 'danger', display: 'top', duration: 3000});
        } else if (step === 3 && !microApp.patients.longName && (!firstName || !lastName)) {
            toast({message: locale.microAppQueueWait.s, color: 'danger', display: 'top', duration: 3000});
        } else if (step === 4 && (!month || !day || !year)) {
            toast({message: locale.microAppQueueWait.ac, color: 'danger', display: 'top', duration: 3000});
        } else {
            if (step === 4) {
                const date = year + '-' + month + '-' + day;
                setDob(date);
            }

            const item = step + 1;
            setStep(item);
        }
    }

    const ButtonCheck = () => {
        if (type === 'mobile' && !mobile) {
            toast({message: locale.microAppQueueWait.k, color: 'danger', display: 'top', duration: 3000});
        } else if (type === 'whatsapp' && !mobile) {
            toast({message: locale.microAppQueueWait.ao, color: 'danger', display: 'top', duration: 3000});
        } else if (type === 'email' && !email) {
            toast({message: locale.microAppQueueWait.l, color: 'danger', display: 'top', duration: 3000});
        } else {
            setStep(9);

            let number = '';
            if (type === 'mobile' || type === 'whatsapp') {
                number = mobile.replace(/[()\s-]/g, '');
            }

            const message = {
                type: 'rest',
                path: 'queue-patient',
                action: 'get',
                data: {
                    email: type === 'email' ? email : null,
                    mobile: (type === 'mobile' || type === 'whatsapp') ? number : null,
                    country: (type === 'mobile' || type === 'whatsapp') ? country : null,
                    type: type,
                    microApp: {account: microApp.account, location: microApp.location}
                }
            };
            restCall(message);
        }
    }

    const ButtonLogin = () => {
        if (!password) {
            toast({message: locale.microAppQueueWait.aw, color: 'danger', display: 'top', duration: 3000});
        } else {
            setStep(9);

            let number = '';
            if (type === 'mobile' || type === 'whatsapp') {
                number = mobile.replace(/[()\s-]/g, '');
            }

            const message = {
                type: 'rest',
                path: 'queue-password',
                action: 'get',
                data: {
                    email: type === 'email' ? email : null,
                    mobile: (type === 'mobile' || type === 'whatsapp') ? number : null,
                    country: (type === 'mobile' || type === 'whatsapp') ? country : null,
                    type: type,
                    microApp: {account: microApp.account, location: microApp.location},
                    password: password
                }
            };
            restCall(message);
        }
    }

    const ButtonReview = () => {
        setStep(8);
    }

    const ButtonFinish = () => {
        setType('mobile');
        setEmail('');
        setMobile('');
        setPassword('');
        setNotifications(true);
        setFirstName('');
        setMiddleName('');
        setLastName('');
        setPaternalName('');
        setMaternalName('');
        setDob('');
        setMonth('');
        setDay('');
        setYear('');
        setGender('');
        setAuth(false);
        setPutPatient(true);
        setReason('');
        setStep(0);
    }

    const ButtonSignIn = () => {
        if (!reason) {
            toast({message: locale.microAppQueueWait.ai, color: 'danger', display: 'top', duration: 3000});
        } else {
            setStep(9);

            let number = '';
            if (type === 'mobile' || type === 'whatsapp') {
                number = mobile.replace(/[()\s-]/g, '');
            }

            const message = {
                type: 'rest',
                path: 'visit',
                action: 'put',
                data: {
                    email: type === 'email' ? email : null,
                    mobile: (type === 'mobile' || type === 'whatsapp') ? number : null,
                    country: (type === 'mobile' || type === 'whatsapp') ? country : null,
                    firstName: firstName ? firstName : null,
                    lastName: lastName ? lastName : null,
                    paternalName: paternalName ? paternalName : null,
                    maternalName: maternalName ? maternalName : null,
                    dob: dob,
                    gender: gender,
                    notifications: notifications,
                    reason: reason,
                    type: type,
                    new: putPatient,
                    locale: window.localStorage.locale,
                    firstVisit: firstVisit,
                    microApp: {
                        account: microApp.account,
                        location: microApp.location,
                        specialty: microApp.specialty,
                        queue: microApp.queue,
                        options: microApp.options,
                        patients: microApp.patients
                    }
                }
            };
            restCall(message);
        }
    }

    const ButtonBack = () => {
        if (step === 8) {
            setStep(6);
        } else if (step === 3) {
            setStep(1);
        } else {
            const item = step - 1;
            setStep(item);
        }
    }

    const ButtonTriageNext = (data, value) => {
        if (data === 'text' && !value) {
            toast({message: locale.microAppQueueWait.be, color: 'danger', display: 'top', duration: 3000});
        } else {
            setStepTriage(stepTriage + 1);
        }
    }

    const ButtonTriageBack = () => {
        const item = stepTriage - 1;
        setStepTriage(item);
    }

    const ButtonTriageComplete = (data, value) => {
        if (data === 'text' && !value) {
            toast({message: locale.microAppQueueWait.ai, color: 'danger', display: 'top', duration: 3000});
        } else {
            setStep(9);

            let number = '';
            if (type === 'mobile' || type === 'whatsapp') {
                number = mobile.replace(/[()\s-]/g, '');
            }

            const message = {
                type: 'rest',
                path: 'queue-triage',
                action: 'update',
                data: {
                    email: type === 'email' ? email : null,
                    mobile: (type === 'mobile' || type === 'whatsapp') ? number : null,
                    country: (type === 'mobile' || type === 'whatsapp') ? country : null,
                    type: type,
                    dob: dob,
                    gender: gender,
                    triage: triage,
                    microApp: {
                        account: microApp.account,
                        location: microApp.location
                    }
                }
            };
            restCall(message);
        }
    }

    const InputMonth = data => {
        if (data.length < month.length) {
            setMonth(data);
            return;
        }

        const numericValue = data.replace(/[^\d]/g, '');
        let truncatedValue = numericValue.slice(0, 2);

        let monthNumber = parseInt(truncatedValue, 10);
        if (monthNumber > 12) {
            truncatedValue = '12';
        }

        setMonth(truncatedValue);
    };

    const InputDay = data => {
        if (data.length < dob.length) {
            setDay(data);
            return;
        }

        const numericValue = data.replace(/[^\d]/g, '');
        let truncatedValue = numericValue.slice(0, 2);

        let dayNumber = parseInt(truncatedValue, 10);
        if (dayNumber > 31) {
            truncatedValue = '31';
        }

        setDay(truncatedValue);
    };

    const InputYear = data => {
        if (data.length < dob.length) {
            setYear(data);
            return;
        }

        const numericValue = data.replace(/[^\d]/g, '');
        let truncatedValue = numericValue.slice(0, 4);

        let yearNumber = parseInt(truncatedValue, 10);
        if (yearNumber > dayjs().year()) {
            truncatedValue = dayjs().year().toString();
        }

        setYear(truncatedValue);
    };

    const RadioType = data => {
        setType(data.target.value);
    }

    const InputTriage = (index, value) => {
        setTriage(prevTriage => {
            const newTriage = [...prevTriage];
            newTriage[index] = { ...newTriage[index], answer: value };
            return newTriage;
        });
    }

    const RadioGender = data => {
        setGender(data.target.value);
    }

    const PutVisit = useCallback(data => {
        if (data.toast) {
            setStep(7);
        }
        if (data.triage) {
            setTriage(data.triage);
            setStep(10);
        }
    }, []);

    const GetQueuePatient = useCallback(data => {
        if (data.firstVisit === true || data.firstVisit === false) {
            setFirstVisit(data.firstVisit);
        }
        if (data.login === true) {
            setStep(2);
            setPutPatient(false);
        } else if (data.login === false) {
            setStep(3);
        }
        if (data.error && data.queue) {
            toast({message: locale.microAppQueueWait.ar, color: 'danger', display: 'top', duration: 3000});
            setStep(1);
        }
    }, [locale.microAppQueueWait.ar]);

    const GetQueuePassword = useCallback(data => {
        if (data.user) {
            setFirstName(data.user.firstName);
            setMiddleName(data.user.middleName);
            setLastName(data.user.lastName);
            setPaternalName(data.user.paternalName);
            setMaternalName(data.user.maternalName);
            setDob(data.user.dob);
            setGender(data.user.gender);
            setAuth(true);

            setStep(6);
        }
        if (data.error) {
            toast({message: locale.microAppQueueWait.ax, color: 'danger', display: 'top', duration: 3000});
            setStep(2);
        }
    }, [locale.microAppQueueWait.ax]);

    const UpdateQueueTriage = useCallback(data => {
        if (data.toast) {
            setStep(7);
        }
    }, []);

    useEffect(() => {
        if (restResponse && !Empty(restResponse) && restResponse.action === 'update' && restResponse.path === 'queue-triage') {
            UpdateQueueTriage(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, UpdateQueueTriage, setRestResponse]);

    useEffect(() => {
        if (restResponse && !Empty(restResponse) && restResponse.action === 'get' && restResponse.path === 'queue-password') {
            GetQueuePassword(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, GetQueuePassword, setRestResponse]);

    useEffect(() => {
        if (restResponse && !Empty(restResponse) && restResponse.action === 'get' && restResponse.path === 'queue-patient') {
            GetQueuePatient(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, GetQueuePatient, setRestResponse]);

    useEffect(() => {
        if (restResponse && !Empty(restResponse) && restResponse.action === 'put' && restResponse.path === 'visit') {
            PutVisit(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, PutVisit, setRestResponse]);

    return (
        <div className={width > 768 ? "mbsc-grid cs-microapp-queue-wait-main" : "mbsc-grid cs-microapp-queue-wait-main-small"} >
            <div className="mbsc-row mbsc-justify-content-center">
                <div className="mbsc-col-sm-12 mbsc-col-md-11 mbsc-col-xl-10 cs-microapp-queue-wait-page">
                    {step === 0 &&
                        <>
                            <div className="cs-microapp-queue-wait-helper mbsc-align-center" >
                                <h3>{locale.microAppQueueWait.d}</h3>
                            </div>
                            <RadioGroup name="type" >
                                <Radio className={type === 'mobile' ? 'mbsc-bold mbsc-txt-m' : 'mbsc-txt-m'} label={locale.microAppQueueWait.b} value="mobile" checked={type === 'mobile'} onChange={RadioType} defaultChecked={true} />
                                {microApp.options.whatsapp &&
                                    <Radio className={type === 'whatsapp' ? 'mbsc-bold mbsc-txt-m' : 'mbsc-txt-m'} label={locale.microAppQueueWait.am} value="whatsapp" checked={type === 'whatsapp'} onChange={RadioType} />
                                }
                                <Radio className={type === 'email' ? 'mbsc-bold mbsc-txt-m' : 'mbsc-txt-m'} label={locale.microAppQueueWait.c} value="email" checked={type === 'email'} onChange={RadioType} />
                            </RadioGroup>
                        </>
                    }
                    {step === 1 &&
                        <>
                            <div className="cs-microapp-queue-wait-helper mbsc-align-center" >
                                {type === 'mobile' &&
                                    <h3>{locale.microAppQueueWait.g}</h3>
                                }
                                {type === 'whatsapp' &&
                                    <h3>{locale.microAppQueueWait.an}</h3>
                                }
                                {type === 'email' &&
                                    <h3>{locale.microAppQueueWait.j}</h3>
                                }
                            </div>
                            {(type === 'mobile' || type === 'whatsapp') && width > 576 &&
                                <div className="mbsc-grid cs-microapp-queue-wait-grid">
                                    <div className="mbsc-row">
                                        <div className="mbsc-col-4 cs-microapp-queue-wait-inputs-left">
                                            <Select
                                                label={locale.microAppQueueWait.e}
                                                rows={locale.microAppQueueWait.f.length}
                                                display="inline"
                                                touchUi={true}
                                                value={country}
                                                data={locale.microAppQueueWait.f}
                                                disabled={mobile.length !== 0}
                                                onChange={args => setCountry(args.value)}
                                            />
                                        </div>
                                        <div className="mbsc-col-8 cs-microapp-queue-wait-inputs-right">
                                            <Input className="cs-microapp-queue-wait-inputs-right-input mbsc-txt-l" label={locale.microAppQueueWait.b} inputStyle="underline" labelStyle="floating" type="text" name="mobile" value={mobile} onChange={ev => Mobile({data: ev.target.value, setMobile: setMobile, country: country})} />
                                        </div>
                                    </div>
                                </div>
                            }
                            {(type === 'mobile' || type === 'whatsapp') && width < 576 &&
                                <>
                                    <Select
                                        label={locale.microAppQueueWait.e}
                                        rows={locale.microAppQueueWait.f.length}
                                        display="inline"
                                        touchUi={true}
                                        value={country}
                                        data={locale.microAppQueueWait.f}
                                        disabled={mobile.length !== 0}
                                        onChange={args => setCountry(args.value)}
                                    />
                                    <Input className="cs-microapp-queue-wait-inputs-right-input mbsc-txt-l" label={locale.microAppQueueWait.b} inputStyle="underline" labelStyle="floating" type="text" name="mobile" value={mobile} onChange={ev => Mobile({data: ev.target.value, setMobile: setMobile, country: country})} />
                                </>
                            }
                            {type === 'email' &&
                                <Input className="cs-microapp-queue-wait-input mbsc-txt-l" inputStyle="underline" label={locale.microAppQueueWait.c} labelStyle="floating" type="email" name="email" value={email} onChange={ev => setEmail(ev.target.value)} />
                            }
                            {type === 'whatsapp' &&
                                <Checkbox
                                    className="mbsc-txt-l mbsc-txt-muted"
                                    color="info"
                                    label={locale.microAppQueueWait.aj}
                                    checked={notifications}
                                    onChange={(ev) => setNotifications(ev.target.checked)}
                                />
                            }
                            {type === 'mobile' &&
                                <Checkbox
                                    className="mbsc-txt-l mbsc-txt-muted"
                                    color="info"
                                    label={locale.microAppQueueWait.ak}
                                    checked={notifications}
                                    onChange={(ev) => setNotifications(ev.target.checked)}
                                />
                            }
                            {type === 'email' &&
                                <Checkbox
                                    className="mbsc-txt-l mbsc-txt-muted"
                                    color="info"
                                    label={locale.microAppQueueWait.al}
                                    checked={notifications}
                                    onChange={(ev) => setNotifications(ev.target.checked)}
                                />
                            }
                        </>
                    }
                    {step === 2 &&
                        <>
                            <div className="cs-microapp-queue-wait-helper mbsc-align-center" >
                                <h3>{locale.microAppQueueWait.at}</h3>
                            </div>
                            <Input className="cs-microapp-queue-wait-input mbsc-txt-l" inputStyle="underline" label={locale.microAppQueueWait.as} labelStyle="floating" type="password" passwordToggle={true} name="password" value={password} onChange={ev => setPassword(ev.target.value)} />
                        </>
                    }
                    {step === 3 &&
                        <>
                            <div className="cs-microapp-queue-wait-helper mbsc-align-center" >
                                <h3>{locale.microAppQueueWait.r}</h3>
                            </div>
                            {microApp.patients.longName ?
                                <>
                                    <Input className="cs-microapp-queue-wait-input mbsc-txt-l" inputStyle="underline" label={locale.microAppQueueWait.o} labelStyle="floating" type="text" name="firstName" value={firstName} onChange={ev => setFirstName(ev.target.value)} />
                                    <Input className="cs-microapp-queue-wait-input mbsc-txt-l" inputStyle="underline" label={locale.microAppQueueWait.q} labelStyle="floating" type="text" name="middleName" value={middleName} onChange={ev => setMiddleName(ev.target.value)} />
                                    <Input className="cs-microapp-queue-wait-input mbsc-txt-l" inputStyle="underline" label={locale.microAppQueueWait.n} labelStyle="floating" type="text" name="paternalName" value={paternalName} onChange={ev => setPaternalName(ev.target.value)} />
                                    <Input className="cs-microapp-queue-wait-input mbsc-txt-l" inputStyle="underline" label={locale.microAppQueueWait.m} labelStyle="floating" type="text" name="maternalName" value={maternalName} onChange={ev => setMaternalName(ev.target.value)} />
                                </>
                                :
                                <>
                                    <Input className="cs-microapp-queue-wait-input mbsc-txt-l" inputStyle="underline" label={locale.microAppQueueWait.o} labelStyle="floating" type="text" name="name" value={firstName} onChange={ev => setFirstName(ev.target.value)} />
                                    <Input className="cs-microapp-queue-wait-input mbsc-txt-l" inputStyle="underline" label={locale.microAppQueueWait.p} labelStyle="floating" type="text" name="name" value={lastName} onChange={ev => setLastName(ev.target.value)} />
                                </>
                            }
                        </>
                    }
                    {step === 4 &&
                        <>
                            <div className="cs-microapp-queue-wait-helper mbsc-align-center" >
                                <h3>{locale.microAppQueueWait.t}</h3>
                            </div>
                            <div className="mbsc-row cs-microapp-queue-wait-section">
                                <div className="mbsc-col mbsc-align-center">
                                    <Input className="cs-microapp-queue-wait-input mbsc-txt-l" inputStyle="underline" placeholder={locale.microAppQueueWait.z} label={locale.microAppQueueWait.w} labelStyle="floating" type="text" name="month" value={month} onChange={ev => InputMonth(ev.target.value)} />
                                </div>
                                <div className="mbsc-col mbsc-align-center">
                                    <Input className="cs-microapp-queue-wait-input mbsc-txt-l" inputStyle="underline" placeholder={locale.microAppQueueWait.aa} label={locale.microAppQueueWait.x} labelStyle="floating" type="text" name="day" value={day} onChange={ev => InputDay(ev.target.value)} />
                                </div>
                                <div className="mbsc-col mbsc-align-center">
                                    <Input className="cs-microapp-queue-wait-input mbsc-txt-l" inputStyle="underline" placeholder={locale.microAppQueueWait.ab} label={locale.microAppQueueWait.y} labelStyle="floating" type="text" name="year" value={year} onChange={ev => InputYear(ev.target.value)} />
                                </div>
                            </div>
                        </>
                    }
                    {step === 5 &&
                        <>
                            <div className="cs-microapp-queue-wait-helper mbsc-align-center" >
                                <h3>{locale.microAppQueueWait.u}</h3>
                            </div>
                            <RadioGroup name="gender" >
                                <Radio className={gender === 'male' ? 'mbsc-bold mbsc-txt-m' : 'mbsc-txt-m'} label={locale.microAppQueueWait.v} value="male" checked={gender === 'male'} onChange={RadioGender} defaultChecked={true} />
                                <Radio className={gender === 'female' ? 'mbsc-bold mbsc-txt-m' : 'mbsc-txt-m'} label={locale.microAppQueueWait.ad} value="female" checked={gender === 'female'} onChange={RadioGender} />
                                <Radio className={gender === 'n/a' ? 'mbsc-bold mbsc-txt-m' : 'mbsc-txt-m'} label={locale.microAppQueueWait.ae} value="n/a" checked={gender === 'n/a'} onChange={RadioGender} />
                            </RadioGroup>
                        </>
                    }
                    {step === 6 &&
                        <>
                            <div className="cs-microapp-queue-wait-helper mbsc-align-center" >
                                <h3>{auth ? locale.microAppQueueWait.ay : locale.microAppQueueWait.af}</h3>
                            </div>
                            <div className="cs-microapp-queue-wait-textarea">
                                <Textarea className="mbsc-txt-l" inputStyle="underline" labelStyle="floating" placeholder={locale.microAppQueueWait.ah} value={reason} onChange={(ev) => setReason(ev.target.value)} />
                            </div>
                        </>
                    }
                    {step === 7 &&
                        <div className="cs-microapp-queue-wait-helper mbsc-align-center" >
                            <h3>{locale.microAppQueueWait.ap}</h3>
                        </div>
                    }
                    {step === 8 &&
                        <>
                            <div className="cs-microapp-queue-wait-helper mbsc-align-center" >
                                <h3>{locale.microAppQueueWait.ba}</h3>
                            </div>
                            {microApp.patients.longName ?
                                <>
                                    <Input disabled className="cs-microapp-queue-wait-input mbsc-txt-l" inputStyle="underline" label={locale.microAppQueueWait.o} labelStyle="floating" type="text" name="firstName" value={firstName} />
                                    <Input disabled className="cs-microapp-queue-wait-input mbsc-txt-l" inputStyle="underline" label={locale.microAppQueueWait.q} labelStyle="floating" type="text" name="middleName" value={middleName} />
                                    <Input disabled className="cs-microapp-queue-wait-input mbsc-txt-l" inputStyle="underline" label={locale.microAppQueueWait.n} labelStyle="floating" type="text" name="paternalName" value={paternalName} />
                                    <Input disabled className="cs-microapp-queue-wait-input mbsc-txt-l" inputStyle="underline" label={locale.microAppQueueWait.m} labelStyle="floating" type="text" name="maternalName" value={maternalName} />
                                </>
                                :
                                <>
                                    <Input disabled className="cs-microapp-queue-wait-input mbsc-txt-l" inputStyle="underline" label={locale.microAppQueueWait.o} labelStyle="floating" type="text" name="name" value={firstName} />
                                    <Input disabled className="cs-microapp-queue-wait-input mbsc-txt-l" inputStyle="underline" label={locale.microAppQueueWait.p} labelStyle="floating" type="text" name="name" value={lastName} />
                                </>
                            }
                            <Input disabled className="cs-microapp-queue-wait-input mbsc-txt-l" inputStyle="underline" label={locale.microAppQueueWait.bb} labelStyle="floating" type="text" name="dob" value={window.localStorage.locale === 'en' ? dayjs(dob).format("M/D/YYYY") : dayjs(dob).format("D/M/YYYY")} />
                            <Input disabled className="cs-microapp-queue-wait-input mbsc-txt-l" inputStyle="underline" label={locale.microAppQueueWait.bc} labelStyle="floating" type="text" name="gender" value={gender === 'n/a' ? locale.microAppQueueWait.ae : gender.charAt(0).toUpperCase() + gender.slice(1)} />
                        </>
                    }
                    {step === 9 &&
                        <>
                            <div className="cs-microapp-queue-wait-helper mbsc-align-center" >
                                <h3>{locale.microAppQueueWait.bh}</h3>
                            </div>
                            <div className="cs-microapp-queue-wait-loading mbsc-align-center" >
                                <Loading />
                            </div>
                        </>
                    }
                    {step === 10 && triage.map((item, index) => {
                        return (
                            <>
                                {stepTriage === index &&
                                    <>
                                        {index === 0 &&
                                            <div className="cs-microapp-queue-wait-helper mbsc-align-center" >
                                                <h3>{locale.microAppQueueWait.bi}</h3>
                                            </div>
                                        }
                                        <div className="cs-microapp-queue-wait-helper mbsc-align-center" >
                                            <h3>{item.question}</h3>
                                        </div>
                                        {item.type === 'text' &&
                                            <div className="cs-microapp-queue-wait-textarea">
                                                <Textarea className="mbsc-txt-l" inputStyle="underline" labelStyle="floating" value={item.answer} onChange={(ev) => InputTriage(index, ev.target.value)} />
                                            </div>
                                        }
                                        {item.type === 'number' &&
                                            <Input className="cs-microapp-queue-wait-input-triage mbsc-txt-l" inputStyle="underline" labelStyle="floating" type="number" name="number" value={item.answer} onChange={ev => InputTriage(index, ev.target.value)} />
                                        }
                                        {item.type === 'boolean' &&
                                            <RadioGroup name={item.sequence} >
                                                <Radio className={item.answer === "true" ? 'mbsc-bold mbsc-txt-m' : 'mbsc-txt-m'} label={locale.microAppQueueWait.bf} value="true" checked={item.answer === "true"} onChange={ev => InputTriage(index, ev.target.value)} />
                                                <Radio className={item.answer === "false" ? 'mbsc-bold mbsc-txt-m' : 'mbsc-txt-m'} label={locale.microAppQueueWait.bg} value="false" checked={item.answer === "false"} onChange={ev => InputTriage(index, ev.target.value)} defaultChecked={true} />
                                            </RadioGroup>
                                        }
                                        {width > 768 &&
                                            <div className="mbsc-row cs-microapp-queue-wait-section">
                                                {stepTriage !== 0 &&
                                                    <div className="mbsc-col mbsc-button-group-block">
                                                        <Button color="primary" className="cs-microapp-queue-wait-button mbsc-txt-l mbsc-bold" onClick={() => ButtonTriageBack()} >
                                                            {locale.microAppQueueWait.i}
                                                        </Button>
                                                    </div>
                                                }
                                                {index !== (triage.length - 1) &&
                                                    <div className="mbsc-col mbsc-button-group-block">
                                                        <Button color="primary" className="cs-microapp-queue-wait-button mbsc-txt-l mbsc-bold" onClick={() => ButtonTriageNext(item.type, item.answer)} >
                                                            {locale.microAppQueueWait.h}
                                                        </Button>
                                                    </div>
                                                }
                                                {index === (triage.length - 1) &&
                                                    <div className="mbsc-col mbsc-button-group-block">
                                                        <Button color="primary" className="cs-microapp-queue-wait-button mbsc-txt-l mbsc-bold" onClick={() => ButtonTriageComplete(item.type, item.answer)} >
                                                            {locale.microAppQueueWait.ag}
                                                        </Button>
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {width < 768 &&
                                            <div className="mbsc-row">
                                                <div className="mbsc-col cs-microapp-queue-wait-grid cs-microapp-queue-wait-nav-bottom" >
                                                    <BottomNav
                                                        theme="ios"
                                                        themeVariant="light"
                                                        type="bottom"
                                                    >
                                                        {stepTriage !== 0 &&
                                                            <NavItem>
                                                                <Button color="primary" className="cs-microapp-queue-wait-button-bottom mbsc-txt-l mbsc-bold" onClick={() => ButtonTriageBack()} >
                                                                    {locale.microAppQueueWait.i}
                                                                </Button>
                                                            </NavItem>
                                                        }
                                                        {index !== (triage.length - 1) &&
                                                            <NavItem>
                                                                <Button color="primary" className="cs-microapp-queue-wait-button-bottom mbsc-txt-l mbsc-bold" onClick={() => ButtonTriageNext(item.type, item.answer)} >
                                                                    {locale.microAppQueueWait.h}
                                                                </Button>
                                                            </NavItem>
                                                        }
                                                        {index === (triage.length - 1) &&
                                                            <NavItem>
                                                                <Button color="primary" className="cs-microapp-queue-wait-button-bottom mbsc-txt-l mbsc-bold" onClick={() => ButtonTriageComplete(item.type, item.answer)} >
                                                                    {locale.microAppQueueWait.ag}
                                                                </Button>
                                                            </NavItem>
                                                        }
                                                    </BottomNav>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                            </>
                        );
                    })}
                    {width > 768 && step !== 10 &&
                        <div className="mbsc-row cs-microapp-queue-wait-section">
                            {(step === 1 || step === 2 || step === 3 || step === 4 || step === 5 || step === 6 || (step === 8 && auth)) &&
                                <div className="mbsc-col mbsc-button-group-block">
                                    <Button color="primary" className="cs-microapp-queue-wait-button mbsc-txt-l mbsc-bold" onClick={() => ButtonBack()} >
                                        {(step === 8 && auth) ? locale.microAppQueueWait.bd : locale.microAppQueueWait.i}
                                    </Button>
                                </div>
                            }
                            {step === 0 &&
                                <div className="mbsc-col mbsc-button-group-block">
                                    <Button color="primary" className="cs-microapp-queue-wait-button mbsc-txt-l mbsc-bold" onClick={() => ButtonNext()} >
                                        {locale.microAppQueueWait.a}
                                    </Button>
                                </div>
                            }
                            {step === 1 &&
                                <div className="mbsc-col mbsc-button-group-block">
                                    <Button color="primary" className="cs-microapp-queue-wait-button mbsc-txt-l mbsc-bold" onClick={() => ButtonCheck()} >
                                        {locale.microAppQueueWait.h}
                                    </Button>
                                </div>
                            }
                            {(step === 3 || step === 4 || step === 5 || step === 2) &&
                                <div className="mbsc-col mbsc-button-group-block">
                                    <Button color="primary" className="cs-microapp-queue-wait-button mbsc-txt-l mbsc-bold" onClick={() => ButtonNext()} >
                                        {step === 2 ? locale.microAppQueueWait.au : locale.microAppQueueWait.h}
                                    </Button>
                                </div>
                            }
                            {step === 2 &&
                                <div className="mbsc-col mbsc-button-group-block">
                                    <Button color="primary" className="cs-microapp-queue-wait-button mbsc-txt-l mbsc-bold" onClick={() => ButtonLogin()} >
                                        {locale.microAppQueueWait.av}
                                    </Button>
                                </div>
                            }
                            {step === 6 && auth &&
                                <div className="mbsc-col mbsc-button-group-block">
                                    <Button color="primary" className="cs-microapp-queue-wait-button mbsc-txt-l mbsc-bold" onClick={() => ButtonReview()} >
                                        {locale.microAppQueueWait.az}
                                    </Button>
                                </div>
                            }
                            {step === 6 &&
                                <div className="mbsc-col mbsc-button-group-block">
                                    <Button color="primary" className="cs-microapp-queue-wait-button mbsc-txt-l mbsc-bold" onClick={() => ButtonSignIn()} >
                                        {(microApp.options.ai && microApp.queue.triage) ? locale.microAppQueueWait.h : locale.microAppQueueWait.ag}
                                    </Button>
                                </div>
                            }
                            {step === 7 &&
                                <div className="mbsc-col mbsc-button-group-block">
                                    <Button color="primary" className="cs-microapp-queue-wait-button mbsc-txt-l mbsc-bold" onClick={() => ButtonFinish()} >
                                        {locale.microAppQueueWait.aq}
                                    </Button>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
            {width < 768 && step !== 10 &&
                <div className="mbsc-row">
                    <div className="mbsc-col cs-microapp-queue-wait-grid cs-microapp-queue-wait-nav-bottom" >
                        <BottomNav
                            theme="ios"
                            themeVariant="light"
                            type="bottom"
                        >
                            {(step === 1 || step === 2 || step === 3 || step === 4 || step === 5 || step === 6 || (step === 8 && auth)) &&
                                <NavItem>
                                    <Button color="primary" className="cs-microapp-queue-wait-button-bottom mbsc-txt-l mbsc-bold" onClick={() => ButtonBack()} >
                                        {(step === 8 && auth) ? locale.microAppQueueWait.bd : locale.microAppQueueWait.i}
                                    </Button>
                                </NavItem>
                            }
                            {step === 0 &&
                                <NavItem>
                                    <Button color="primary" className="cs-microapp-queue-wait-button-bottom mbsc-txt-l mbsc-bold" onClick={() => ButtonNext()} >
                                        {locale.microAppQueueWait.a}
                                    </Button>
                                </NavItem>
                            }
                            {step === 1 &&
                                <NavItem>
                                    <Button color="primary" className="cs-microapp-queue-wait-button-bottom mbsc-txt-l mbsc-bold" onClick={() => ButtonCheck()} >
                                        {locale.microAppQueueWait.h}
                                    </Button>
                                </NavItem>
                            }
                            {(step === 3 || step === 4 || step === 5 || step === 2) &&
                                <NavItem>
                                    <Button color="primary" className="cs-microapp-queue-wait-button-bottom mbsc-txt-l mbsc-bold" onClick={() => ButtonNext()} >
                                        {step === 2 ? locale.microAppQueueWait.au : locale.microAppQueueWait.h}
                                    </Button>
                                </NavItem>
                            }
                            {step === 2 &&
                                <NavItem>
                                    <Button color="primary" className="cs-microapp-queue-wait-button-bottom mbsc-txt-l mbsc-bold" onClick={() => ButtonLogin()} >
                                        {locale.microAppQueueWait.av}
                                    </Button>
                                </NavItem>
                            }
                            {step === 6 && auth &&
                                <NavItem>
                                    <Button color="primary" className="cs-microapp-queue-wait-button-bottom mbsc-txt-l mbsc-bold" onClick={() => ButtonReview()} >
                                        {locale.microAppQueueWait.az}
                                    </Button>
                                </NavItem>
                            }
                            {step === 6 &&
                                <NavItem>
                                    <Button color="primary" className="cs-microapp-queue-wait-button-bottom mbsc-txt-l mbsc-bold" onClick={() => ButtonSignIn()} >
                                        {(microApp.options.ai && microApp.queue.triage) ? locale.microAppQueueWait.h : locale.microAppQueueWait.ag}
                                    </Button>
                                </NavItem>
                            }
                            {step === 7 && auth &&
                                <NavItem>
                                    <Button color="primary" className="cs-microapp-queue-wait-button-bottom mbsc-txt-l mbsc-bold" onClick={() => ButtonFinish()} >
                                        {locale.microAppQueueWait.aq}
                                    </Button>
                                </NavItem>
                            }
                        </BottomNav>
                    </div>
                </div>
            }
        </div>
    );
}

export default QueueWait;
