import React, {useCallback} from 'react';
import {Popup, setOptions, Button} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/patientVisitTriage.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/pro-duotone-svg-icons";

setOptions({
  theme: 'ios',
  themeVariant: 'light',
});

function PatientVisitTriage({locale, dialog, Dialog, visit, width}) {

  const DialogClose = useCallback(() => {
    Dialog({view: 'close'});
  }, [Dialog]);

  const ListItem = data => {

    return <li key={data.item.sequence}>
      <div className="mbsc-row cs-patient-visit-triage-list">
        <div className="cs-patient-visit-triage-list-section">
          <div className="mbsc-bold mbsc-txt-muted">{data.item.question}</div>
          <div className="mbsc-row mbsc-txt-muted cs-patient-visit-triage-item-txt">
            {data.item.type === 'boolean' && data.item.answer === 'true' && locale.patientVisitTriage.c}
            {data.item.type === 'boolean' && data.item.answer === 'false' && locale.patientVisitTriage.d}
            {(data.item.type === 'number' || data.item.type === 'text') && data.item.answer}
          </div>
        </div>
      </div>
    </li>;
  }

  return (
      <Popup className="cs-patient-visit-triage-popup" width={600} display="center" closeOnOverlayClick={false} closeOnEsc={false} scrollLock={false} buttons={[]} isOpen={dialog.view === 'patientVisitTriage'} onClose={DialogClose}>
        <div className="mbsc-grid cs-patient-visit-triage-header-col">
          <div className="mbsc-row cs-patient-visit-triage-header-row">
            {width > 576 &&
                <div className="mbsc-col cs-patient-visit-triage-header-col" />
            }
            <div className={width > 576 ? "mbsc-col cs-patient-visit-triage-header-col mbsc-align-center" : "mbsc-col cs-patient-visit-triage-header-col"} >
              <Button className="cs-patient-visit-triage-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                {locale.patientVisitTriage.b}
              </Button>
            </div>
            <div className="mbsc-col cs-patient-visit-triage-header-col mbsc-align-right">
              <Button color="primary" variant="flat" className="mbsc-bold cs-patient-visit-triage-close-button" onClick={() => DialogClose()} >
                &nbsp;<FontAwesomeIcon className="cs-patient-visit-triage-close-icon" icon={faXmark} />
              </Button>
            </div>
          </div>
        </div>
        <Listview
            theme="ios"
            themeVariant="light"
            select="single"
            itemType={ListItem}
            data={visit.triage}
        />
      </Popup>
  );
}

export default PatientVisitTriage;
