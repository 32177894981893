import React, {useCallback, useState, useContext, useEffect} from 'react';
import {Popup, Input, toast, Datepicker, setOptions, Button, confirm} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/patientImmunization.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashCan, faXmark} from "@fortawesome/pro-duotone-svg-icons";
import dayjs from "dayjs";
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function PatientImmunization({locale, dialog, Dialog, setPatient, setImmunizations, immunizations, patient, width, immunization}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [date, setDate] = useState(immunization.date ? immunization.date : '');
    const [text, setText] = useState(immunization.text ? immunization.text : '');

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const ButtonAdd = () => {
        if (!text) {
            toast({message: locale.patientImmunization.f, color: 'danger', display: 'bottom', duration: 3000});
        } else {

            const message = {
                type: 'wss',
                path: 'patient-immunization',
                action: 'put',
                data: {
                    patient: patient.id,
                    immunizations: immunizations,
                    date: date,
                    text: text
                }
            };
            wsCall(message);
        }
    }

    const ButtonHeader = () => {
        if (!Empty(immunization) && immunization.id) {
            return locale.patientImmunization.d;
        } else {
            return locale.patientImmunization.b;
        }
    }

    const ButtonUpdate = () => {
        if (!text) {
            toast({message: locale.patientImmunization.f, color: 'danger', display: 'bottom', duration: 3000});
        } else {

            const message = {
                type: 'wss',
                path: 'patient-immunization',
                action: 'update',
                data: {
                    patient: patient.id,
                    immunizations: immunizations,
                    date: date,
                    text: text,
                    id: immunization.id
                }
            };
            wsCall(message);
        }
    }

    const ButtonDelete = () => {
        confirm({
            title: locale.patientImmunization.i,
            message: locale.patientImmunization.j,
            okText: locale.patientImmunization.k,
            cancelText: locale.patientImmunization.l,
            callback: (res) => {
                if (res) {
                    const message = {
                        type: 'wss',
                        path: 'patient-immunization',
                        action: 'delete',
                        data: {
                            patient: patient.id,
                            immunizations: immunizations,
                            id: immunization.id
                        }
                    };
                    wsCall(message);
                }
            }
        });
    }

    const PutPatientImmunization = useCallback(data => {
        if (data.immunizations) {
            const item = {...patient};
            item.immunizations = data.immunizations;
            setPatient(item);
            setImmunizations(data.immunizations);
            setText('');
        }
        DialogClose();
    }, [patient, DialogClose]);

    const UpdatePatientImmunization = useCallback(data => {
        if (data.immunizations) {
            const item = {...patient};
            item.immunizations = data.immunizations;
            setPatient(item);
            setImmunizations(data.immunizations);
            setText('');
        }
        DialogClose();
    }, [patient, DialogClose]);

    const DeletePatientImmunization = useCallback(data => {
        if (data.immunizations) {
            const item = {...patient};
            item.immunizations = data.immunizations;
            setPatient(item);
            setImmunizations(data.immunizations);
        }
        DialogClose();
    }, [patient, DialogClose]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'delete' && wsResponse.path === 'patient-immunization') {
            DeletePatientImmunization(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, DeletePatientImmunization, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'patient-immunization') {
            UpdatePatientImmunization(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdatePatientImmunization, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'patient-immunization') {
            PutPatientImmunization(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PutPatientImmunization, setWsResponse]);

    return (
        <Popup className="cs-patient-immunization-popup" display="center" width={600} closeOnOverlayClick={false} closeOnEsc={false} buttons={[]} isOpen={dialog.view === 'patientImmunization'} onClose={DialogClose}>
            <div className="mbsc-grid cs-patient-immunization-header-col">
                <div className="mbsc-row cs-patient-immunization-header-row">
                    {width > 576 &&
                        <div className="mbsc-col cs-patient-immunization-header-col" />
                    }
                    <div className={width > 576 ? "mbsc-col cs-patient-immunization-header-col mbsc-align-center" : "mbsc-col cs-patient-immunization-header-col"} >
                        <Button className="cs-patient-immunization-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                            {ButtonHeader()}
                        </Button>
                    </div>
                    <div className="mbsc-col cs-patient-immunization-header-col mbsc-align-right">
                        {!Empty(immunization) && immunization.id &&
                            <Button color="primary" variant="flat" className="mbsc-bold cs-patient-immunization-delete-button" onClick={() => ButtonDelete()} >
                                &nbsp;<FontAwesomeIcon className="cs-patient-immunization-delete-icon" icon={faTrashCan} />
                            </Button>
                        }
                        <Button color="primary" variant="flat" className="mbsc-bold cs-patient-immunization-close-button" onClick={() => DialogClose()} >
                            &nbsp;<FontAwesomeIcon className="cs-patient-immunization-close-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="mbsc-grid cs-patient-immunization-grid">
                <div className="mbsc-row">
                    <div className="mbsc-col-4 cs-patient-immunization-inputs-left">
                        <Datepicker
                            controls={['date']}
                            touchUi={width <= 576}
                            inputStyle="underline"
                            label={locale.patientImmunization.c}
                            labelStyle="floating"
                            value={date}
                            selectMultiple={false}
                            max={dayjs().format("YYYY-M-D")}
                            onChange={args => setDate(args.value)}
                            buttons={['set', 'cancel']}
                            setText={locale.patientImmunization.m}
                            cancelText={locale.patientImmunization.l}
                            dateFormat="YYYY-M-D"
                            dateWheels="|YYYY | MMM | D|"
                        />
                    </div>
                    <div className="mbsc-col-8 cs-patient-immunization-inputs-right">
                        <Input className="cs-patient-immunization-inputs-right-input" label={locale.patientImmunization.e} inputStyle="underline" labelStyle="floating" type="text" name="allergy" value={text} onChange={ev => setText(ev.target.value)} />
                    </div>
                </div>
            </div>
            <div className="mbsc-button-group-justified cs-patient-immunization-nav-buttons">
                {!Empty(immunization) && immunization.id &&
                    <Button color="primary" variant="flat" className="mbsc-bold cs-patient-immunization-nav-button" onClick={() => ButtonUpdate()} >
                        {locale.patientImmunization.h}
                    </Button>
                }
                {!(!Empty(immunization) && immunization.id) &&
                    <Button color="primary" variant="flat" className="mbsc-bold cs-patient-immunization-nav-button" onClick={() => ButtonAdd()} >
                        {locale.patientImmunization.a}
                    </Button>
                }
            </div>
        </Popup>
    );
}

export default PatientImmunization;
