import React, {useEffect, useState, useCallback, useContext} from 'react';
import {setOptions, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview, NavItem, TabNav} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import 'dayjs/locale/es'
import '../styles/patient.css';
import dayjs from "dayjs";
import Empty from '../utils/Empty';
import Menu from "../navigation/Menu";
import Item from "../navigation/Item";
import PatientVisit from "../blocks/PatientVisit";
import PatientVisits from "../blocks/PatientVisits";
import PatientMedicalHistory from "../blocks/PatientMedicalHistory";
import PatientSurgicalHistory from "../blocks/PatientSurgicalHistory";
import PatientFamilyHistory from "../blocks/PatientFamilyHistory";
import PatientSocialHistory from "../blocks/PatientSocialHistory";
import PatientAllergies from "../blocks/PatientAllergies";
import PatientMedications from "../blocks/PatientMedications";
import PatientImmunizations from "../blocks/PatientImmunizations";
import PatientVisitFile from "../dialogs/PatientVisitFile";
import PatientVitals from "../blocks/PatientVitals";
import QR from "../shared/QR";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function Patient({locale, width, navigation, Navigation, configuration, dialog, Dialog, patient, setPatient, visit, setVisit, location, setRefresh, refresh}) {
    const {wsResponse, setWsResponse} = useContext(NetworkContext);
    const [qr, setQr] = useState({});
    const [sections, setSections] = useState({reason: true, subjective: true, objective: true, assessment: true, plan: true, instructions: true});
    const [draft, setDraft] = useState(true);
    const [visits, setVisits] = useState({});
    const [summary, setSummary] = useState('');
    const [file, setFile] = useState({});
    const [date, setDate] = useState(dayjs().format('YYYY-M-D'));

    const UpdatePatient = useCallback(data => {
        if (data.toast && !draft) {
            toast({message: locale.patient.b, color: 'info', duration: 1000, display: 'bottom'});
            setDraft(true);
        }
    }, [locale.patient.b, draft]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'patient') {
            UpdatePatient(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdatePatient, setWsResponse]);

    useEffect(() => {
        if (!Empty(visit)) {
            Navigation({menu: locale.patient.c});
        } else {
            Navigation({menu: locale.patient.d});
        }
    }, []);

    return (
        <div className={width > 768 ? "mbsc-grid cs-patient-main" : "mbsc-grid cs-patient-main-xs"}>
            <div className={width > 768 ? "mbsc-row cs-patient-container" : "mbsc-row cs-patient-container-xs"}>
                <div className="mbsc-col cs-patient-title cs-patient-title-input">
                    {configuration.patients.longName &&
                        <p className={width > 768 ? "mbsc-txt-l" : "mbsc-txt-m"}>
                            {patient.paternalName && patient.paternalName} {patient.maternalName && patient.maternalName} {(patient.paternalName || patient.maternalName) && ', '}{patient.firstName && patient.firstName} {patient.middleName && patient.middleName}
                        </p>
                    }
                    {!configuration.patients.longName &&
                        <p className={width > 768 ? "mbsc-txt-l" : "mbsc-txt-m"}>
                            {patient.lastName && patient.lastName}{patient.lastName && ', '}{patient.firstName && patient.firstName}
                        </p>
                    }
                </div>
            </div>
            <div className="mbsc-row">
                {width > 768 &&
                    <div className="mbsc-col-12 mbsc-col-md-3 mbsc-col-sm-12 cs-patient-left">
                        {navigation.menu && navigation.menu.length !== 0 &&
                            <Listview
                                theme="ios"
                                themeVariant="light"
                                select="single"
                                itemType={event => Item(event, navigation)}
                                data={navigation.menu}
                                onItemTap={event => Menu(event.index, Navigation, navigation)}
                            />
                        }
                    </div>
                }
                <div className={width > 576 ? "mbsc-col-12 mbsc-col-md-9 mbsc-col-sm-12 cs-patient-right" : "mbsc-col-12 mbsc-col-md-9 mbsc-col-sm-12 cs-patient-right-xs"} >
                    {navigation.menu && navigation.menu.length !== 0 && width < 768 &&
                        <TabNav theme="ios" themeVariant="light" display='inline' >
                            {navigation.menu.map((item) => {
                                return (
                                    <NavItem selected={navigation.tab === item.name} onClick={(ev) => Menu(item.name, Navigation, navigation)} >
                                        <span className={navigation.tab === item.name ? 'mbsc-bold cs-patient-nav' : 'mbsc-bold'} >{item.label}</span>
                                    </NavItem>
                                );
                            })}
                        </TabNav>
                    }
                    {navigation.tab === 'visit' && !Empty(visit) &&
                        <PatientVisit
                            locale={locale}
                            configuration={configuration}
                            Dialog={Dialog}
                            setVisit={setVisit}
                            visit={visit}
                            setQr={setQr}
                            sections={sections}
                            setSections={setSections}
                            width={width}
                            dialog={dialog}
                            setFile={setFile}
                        />
                    }
                    {navigation.tab === 'visits' &&
                        <PatientVisits
                            locale={locale}
                            patient={patient}
                            visits={visits}
                            setVisits={setVisits}
                            summary={summary}
                            setSummary={setSummary}
                            dialog={dialog}
                            Dialog={Dialog}
                            width={width}
                            setFile={setFile}
                            location={location}
                            setRefresh={setRefresh}
                            configuration={configuration}
                            navigation={navigation}
                            refresh={refresh}
                        />
                    }
                    {navigation.tab === 'medicalHistory' && configuration.patients.medicalHistory &&
                        <PatientMedicalHistory
                            locale={locale}
                            setPatient={setPatient}
                            patient={patient}
                            setDraft={setDraft}
                        />
                    }
                    {navigation.tab === 'surgicalHistory' && configuration.patients.surgicalHistory &&
                        <PatientSurgicalHistory
                            locale={locale}
                            setPatient={setPatient}
                            patient={patient}
                            setDraft={setDraft}
                        />
                    }
                    {navigation.tab === 'familyHistory' && configuration.patients.familyHistory &&
                        <PatientFamilyHistory
                            locale={locale}
                            setPatient={setPatient}
                            patient={patient}
                            setDraft={setDraft}
                        />
                    }
                    {navigation.tab === 'socialHistory' && configuration.patients.socialHistory &&
                        <PatientSocialHistory
                            locale={locale}
                            setPatient={setPatient}
                            patient={patient}
                            setDraft={setDraft}
                        />
                    }
                    {navigation.tab === 'allergies' && configuration.patients.allergies &&
                        <PatientAllergies
                            locale={locale}
                            setPatient={setPatient}
                            patient={patient}
                            dialog={dialog}
                            Dialog={Dialog}
                            width={width}
                        />
                    }
                    {navigation.tab === 'medications' && configuration.patients.medications &&
                        <PatientMedications
                            locale={locale}
                            setPatient={setPatient}
                            patient={patient}
                            dialog={dialog}
                            Dialog={Dialog}
                            width={width}
                        />
                    }
                    {navigation.tab === 'immunizations' && configuration.patients.immunizations &&
                        <PatientImmunizations
                            locale={locale}
                            setPatient={setPatient}
                            patient={patient}
                            width={width}
                            dialog={dialog}
                            Dialog={Dialog}
                        />
                    }
                    {navigation.tab === 'vitals' &&
                        <PatientVitals
                            locale={locale}
                            Dialog={Dialog}
                            dialog={dialog}
                            patient={patient}
                            date={date}
                            setDate={setDate}
                            configuration={configuration}
                        />
                    }
                </div>
            </div>
            {dialog.view === 'sharedQr' && !Empty(qr) &&
                <QR
                    locale={locale}
                    dialog={dialog}
                    Dialog={Dialog}
                    qr={qr}
                    width={width}
                />
            }
            {dialog.view === 'patientVisitFile' &&
                <PatientVisitFile
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    file={file}
                    visit={visit}
                    setVisit={setVisit}
                    navigation={navigation}
                    width={width}
                />
            }
        </div>
    );
}

export default Patient;
