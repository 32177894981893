import React from 'react';
import {Button, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/microAppHeader.css';
import {faLanguage} from "@fortawesome/pro-duotone-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function MicroAppHeader({locale, setLocale, microApp, width}) {

    const ButtonLocale = () => {
        if (window.localStorage.locale === 'en') {
            setLocale(microApp.locale.es);
            window.localStorage.locale = 'es';
        } else if (window.localStorage.locale === 'es') {
            setLocale(microApp.locale.en);
            window.localStorage.locale = 'en';
        }
    }

    return (
        <div className="mbsc-grid cs-microapp-header-toolbar">
            <div className="mbsc-row">
                <div className="mbsc-col">
                    <div className="mbsc-row">
                        <div className="cs-microapp-header-logo" style={{backgroundImage: `url(${microApp.logo})`}} />
                        <Button className="cs-microapp-header-button-text-logo mbsc-bold" variant="flat" >
                            {microApp.displayName}
                        </Button>
                    </div>
                </div>
                <div className="mbsc-col-auto">
                    <Button id="headerLocale" title={locale.microAppHeader.a} onClick={() => ButtonLocale()} className="cs-microapp-header-button-text mbsc-bold" variant="flat" >
                        <FontAwesomeIcon className="cs-microapp-header-button-icon" icon={faLanguage} />{width > 576 && <div className="cs-microapp-header-menu-text">{locale.microAppHeader.b}</div>}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default MicroAppHeader;
